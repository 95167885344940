import { Component, Inject } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';

export interface IMessageModalData {
    message: string;
    title: string;
    closeText?: string;
}

@Component({
    selector: 'passbot-delete-item-modal',
    templateUrl: './message-modal.component.html',
})
export class MessageModalComponent {
    constructor(@Inject(MODAL_REF) private readonly modal: ModalComponent, @Inject(MODAL_DATA) public data: IMessageModalData) {}

    public close() {
        this.modal.close();
    }
}
