<div class="space-y-8 text-white min-w-[400px] text-left" >
    <h3 class="text-center font-semibold text-lg text-brand">{{ data.title }}</h3>
    <p>{{ data.message }}</p>
    <div class="flex justify-center mt-8">
        <button
                (click)="close()"
                class="inline-flex shadow-sm justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
            {{ data.closeText ? data.closeText : 'Close' }}
        </button>
    </div>
</div>
