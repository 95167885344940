import { createReducer, on } from '@ngrx/store';
import { IUser } from '@passbot/shared';
import {
    authWithDevice,
    authWithDeviceError,
    authWithDeviceSuccess,
    authWithLocalDevice,
    authWithToken,
    authWithTokenError,
    authWithTokenSuccess,
    loadUser,
    loadUserError,
    loadUserSuccess,
    logout,
} from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserState {
    user?: IUser; // which User record has been selected
    loaded: boolean; // has the User list been loaded
    error?: string | null; // last known error (if any)
    inProgress: boolean;
    authExpires: number;
}

export interface UserPartialState {
    readonly [USER_FEATURE_KEY]: UserState;
}

export const initialUserState: UserState = {
    user: undefined,
    // set initial required properties
    loaded: false,
    inProgress: false,
    authExpires: 0,
};

export const UserReducers = createReducer(
    initialUserState,
    on(loadUser, (state) => {
        return { ...state, user: undefined, loaded: false, error: null, inProgress: true };
    }),
    on(loadUserSuccess, (state, user) => {
        return { ...state, user, loaded: true, error: null, inProgress: false };
    }),
    on(loadUserError, (state, error) => ({ ...state, loaded: false, error: error.msg, inProgress: false })),
    on(logout, (state) => initialUserState),
    on(authWithDevice, (state) => ({ ...state, inProgress: true })),
    on(authWithLocalDevice, (state) => ({ ...state, inProgress: true })),
    on(authWithDeviceError, (state, error) => ({ ...state, inProgress: false, error: error.message })),
    on(authWithDeviceSuccess, (state, { expiration }) => ({ ...state, inProgress: false, authExpires: expiration })),
    on(authWithToken, (state) => ({ ...state, inProgress: true })),
    on(authWithTokenSuccess, (state) => ({ ...state, inProgress: false })),
    on(authWithTokenError, (state, error) => ({ ...state, inProgress: false, error: error.message })),
);
