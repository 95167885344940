import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IUser, IUserPermission } from '@passbot/shared';
import { Actions, ofType } from '@ngrx/effects';
import { firstValueFrom, merge } from 'rxjs';
import {
    loadPermissions,
    removeUserPermission,
    removeUserPermissionError,
    removeUserPermissionSuccess,
    updateUserPermission,
    updateUserPermissionError,
    updateUserPermissionSuccess,
} from './permissions.actions';
import { permissionsSelectors } from './permissions.selectors';

@Injectable()
export class PermissionsFacade {
    public permissions$ = this.store.pipe(select(permissionsSelectors.selectAll));
    public isProcessing$ = this.store.pipe(select(permissionsSelectors.getIsProcessing));
    public getByUserId$ = (userId: string) => this.store.pipe(select(permissionsSelectors.getByUserId(userId)));
    public getByGroupId$ = (groupId: string) => this.store.pipe(select(permissionsSelectors.getByGroupId(groupId)));
    public getByUserIdAndGroupId$ = (userId: string, groupId: string) =>
        this.store.pipe(select(permissionsSelectors.getByUserIdAndGroupId(userId, groupId)));

    public getByUserIdAndGroupIds$ = (userId: string, groupIds: string[]) =>
        this.store.pipe(select(permissionsSelectors.getByUserIdAndGroupIds(userId, groupIds)));

    public getByLoggedInUserAndGroupId$ = (groupId: string) => this.store.pipe(select(permissionsSelectors.getByUserAndGroupId(groupId)));

    constructor(private readonly store: Store, private readonly actions: Actions) {}

    public loadPermissions() {
        this.store.dispatch(loadPermissions());
    }

    public deleteUserPermission(userId: IUser['id'], permissionId: IUserPermission['id']) {
        this.store.dispatch(removeUserPermission({ userId, permissionId }));
    }

    public deleteUserPermissionAsync(userId: IUser['id'], permissionId: IUserPermission['id']) {
        this.deleteUserPermission(userId, permissionId);

        const deleteSuccess = this.actions.pipe(ofType(removeUserPermissionSuccess));
        const deleteError = this.actions.pipe(ofType(removeUserPermissionError));

        return firstValueFrom(merge(deleteSuccess, deleteError));
    }

    public updatePermission(permission: IUserPermission, userId: IUser['id']) {
        this.store.dispatch(updateUserPermission({ permission, userId }));
    }

    public updatePermissionAsync(permission: IUserPermission, userId: IUser['id']) {
        this.updatePermission(permission, userId);

        const updateSuccess = this.actions.pipe(ofType(updateUserPermissionSuccess));
        const updateError = this.actions.pipe(ofType(updateUserPermissionError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }
}
