<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="(processing$ | async) === false" [formGroup]="permissionForm">
    <h3 class="text-center font-semibold text-lg">Edit Permission</h3>
    <div *ngIf="credentialGroup$ | async as credentialGroup">Edit the permission for <span class="text-brand">{{user.name}}</span> and the <span class="text-brand">{{ credentialGroup.name }}</span> group.</div>
    <div class="text-center text-sm space-x-2 flex items-center justify-center">
        <select formControlName="access" class="block w-full p-2 mb-6 text-sm border rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
            <option [value]="Permissions.Read">Read</option>
            <option [value]="Permissions.Write">Write</option>
            <option [value]="Permissions.Admin">Admin</option>
        </select>
    </div>
    <div class="flex justify-between mt-8">
        <div (click)="close()" class="cursor-pointer inline-flex justify-center bg-slate-200 cursor-pointer hover:bg-slate-100 text-slate-800 py-2 px-4 border-b-4 border-white rounded-lg">
            Cancel
        </div>
        <button
                (click)="save()"
                class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
           Save
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="processing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>

