import { Component } from '@angular/core';
import { WebauthService } from '@passbot/angular/webauthn';

@Component({
    selector: 'passbot-webauth-register',
    templateUrl: './register.component.html',
})
export class RegisterComponent {
    public complete = false;
    public error = null;

    constructor(private readonly webauthnService: WebauthService) {}

    public ngOnInit() {
        setTimeout(async () => {
            try {
                await this.webauthnService.handleToken(true);
                this.complete = true;
            } catch (e: any) {
                this.error = e.message;
            }
        }, 2000);
    }

    public closeWindow() {
        window.close();
    }
}
