<div class="text-white text-sm space-y-2 text-left">
    <div>
        Removing this credential from the groups <span [innerHTML]=" groups | passbotPrettyList: 'name'"></span> would mean that they would be left empty.
    </div>
    <div>
        You can delete these groups if you wish or keep them.
    </div>
    <div class="text-red-500 text-xs">
        NOTE: Deleting the groups will also remove any permissions attached to them.
    </div>


    <div class="pt-2 space-y-4">
        <div *ngFor="let group of groups">
            <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" [(ngModel)]="deleteGroups[group.id]">
                <div class="w-11 h-6 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-brand rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-brand"></div>
                <span class="ml-3 text-sm font-medium text-white">
                    <ng-container *ngIf="!deleteGroups[group.id]"> Keep <span class="text-brand font-semibold">{{ group.name }}</span> empty</ng-container>
                    <span *ngIf="deleteGroups[group.id]" class="text-red-500"> Delete <span class="text-brand font-semibold">{{ group.name }}</span></span>
                </span>
            </label>
        </div>
    </div>

    <div class="flex justify-end pt-8">
<!--        <div (click)="cancel()" class="cursor-pointer inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">-->
<!--            Cancel-->
<!--        </div>-->
<!--        <div class="text-center text-xs text-white flex items-center justify-center">-->
<!--            * required field-->
<!--        </div>-->
        <button
                (click)="processGroups()"
                class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
            Save
        </button>
    </div>
</div>
