<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="!isProcessing">
    <h3 class="text-center font-semibold text-lg text-brand">Edit Number of Seats</h3>
    <p *ngIf="!showReason">
        Change the number of seats contained in your subscription.
    </p>
    <p *ngIf="showReason">
        You currently do not have enough seats to add a new user, you can update your subscribed seats below.
    </p>
    <div>
        <label class="text-xs mb-1" [ngClass]="{'!text-red-500': numSeats < inUse || numSeats < 5 }">Number of seats *</label>
        <input
                type="number"
                [min]="5 < inUse ? inUse : 5"
                [ngClass]="{'!ring-red-500':  numSeats < inUse || numSeats < 5}"
                [(ngModel)]="numSeats"
                (ngModelChange)="checkUpdateStatus()"
                class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
        <div class="text-xs text-right text-red-500 mt-1" *ngIf="numSeats < inUse">
            You are currently using <strong>{{ inUse }}</strong> seats, you must delete users first to go down to this value.
        </div>
        <div class="text-xs text-right text-red-500 mt-1" *ngIf="numSeats < 5 && numSeats >= inUse">
            We offer 5 seats as a minimum as part of our standard package.
        </div>
    </div>
    <div class="h-[50px] flex items-center">
        <div *ngIf="gettingUpdateStatus && numSeats >= inUse && origNumSeats !== numSeats" class="p-4 text-center flex-1">

                <i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i>
        </div>
        <div *ngIf="!gettingUpdateStatus && numSeats >= inUse && origNumSeats !== numSeats &&  (updateStatus$ | async) as status" class="space-y-2 text-sm text-white/60 flex-1">
            <ng-container *ngIf="status.amount !== 0" >
                <div class="text-white text-md">As a result of this change:</div>

                <div *ngIf="status.credit">This change will come into affect on your next billing date (<span class="text-brand font-semibold">{{ this.seatInfo.nextBillingDate | date }}</span>)</div>

                <div *ngIf="!status.credit">We will charge you a pro-rated amount of <span class="text-brand font-semibold">{{ status.amount | passbotCurrency }}</span> once you hit save.</div>
                <div>Your estimated monthly bill will be <span class="text-brand font-semibold">{{ status.monthly | passbotCurrency }}</span></div>
            </ng-container>
            <ng-container *ngIf="status.amount === 0 && seatInfo.pending && numSeats === seatInfo.seats">
                <div>This change will revert the pending changes to your subscription</div>
                <div>Your estimated monthly bill will be <span class="text-brand font-semibold">{{ status.monthly | passbotCurrency }}</span></div>
            </ng-container>
        </div>
    </div>
    <div class="flex justify-between mt-8">
        <div (click)="close()" class="cursor-pointer inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
            Cancel
        </div>
        <button
                [disabled]="numSeats < inUse || numSeats < 5 || numSeats === origNumSeats"
                (click)="updateSeats()"
                class="inline-flex justify-center rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed">
            Save
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>

