import { ICredential, ICredentialSecurityBreakdown, ISecurityStats } from '../interfaces';

const numDaysSinceUpdate = (credential: ICredential) =>
    Math.round((new Date().getTime() - new Date(credential.passwordChangedDate).getTime()) / (1000 * 3600 * 24));

export const calculateSecurityStats = (credentials: ICredential[]): ISecurityStats => {
    const seen = new Set<string>();

    const totals = credentials.reduce(
        (score, cred) => {
            score.total += 12;
            score.score += cred.score;
            score.credentials++;

            if (numDaysSinceUpdate(cred) > 365) {
                score.old++;
            } else {
                score.score++;
            }

            if (cred.risks?.length > 0) {
                score.risks++;
            } else {
                score.score++;
            }

            if (cred.score < 2) {
                score.weak++;
            }

            if (cred.totpKey) {
                score.mfa++;
                score.score += 4;
            }

            if (seen.has(cred.fingerprint)) {
                score.duplicates++;
            } else {
                score.score += 2;
                seen.add(cred.fingerprint);
            }

            return score;
        },
        { total: 0, score: 0, old: 0, risks: 0, credentials: 0, weak: 0, mfa: 0, duplicates: 0 },
    );

    const percentScore = Math.round((totals.score / totals.total) * 100);

    return { ...totals, percentScore };
};

export const getCredentialsSecurityBreakdown = (credentials: ICredential[]) => {
    const seen = new Set<string>();

    const getCredentialSecurity = (credential: ICredential) => {
        const total = 12;
        let { score } = credential;
        const issues: any = {};

        if (numDaysSinceUpdate(credential) > 365) {
            issues.old = true;
        } else {
            score++;
        }

        if (credential.risks?.length < 1) {
            score++;
        } else {
            issues.atRisk = true;
        }

        if (credential.score < 2) {
            issues.weak = true;
        }

        if (credential.totpKey) {
            score += 4;
        }

        if (seen.has(credential.fingerprint)) {
            issues.duplicate = true;
        } else {
            score += 2;
            seen.add(credential.fingerprint);
        }

        return {
            score,
            percent: Math.round((score / total) * 100),
            issues,
        };
    };

    return credentials
        .map((cred) => ({
            credential: cred,
            breakdown: getCredentialSecurity(cred),
        }))
        .sort((a, b) => {
            return a.credential.name.toLowerCase() > b.credential.name.toLowerCase() ? 1 : -1;
        }) as ICredentialSecurityBreakdown[];
};
