<div class="space-y-8 text-white min-w-[500px] text-left" *ngIf="(isProcessing$ | async) === false && ready" [formGroup]="confirmationForm">
    <h3 class="text-center font-semibold text-lg text-brand">Delete Credential Vault</h3>

    <div *ngIf="permanentlyDelete.length > 0">
        <div class="flex mb-4">
            <div class="flex-1">The following credentials will be permanently deleted:</div>
        </div>
        <div class="">
            <span *ngFor="let credential of permanentlyDelete"
                class="icon-parent px-2 inline-flex h-8 mb-2 mr-2 shrink-0 space-x-2 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[1.1rem] text-gray-400 group-hover:text-white"
            >
                <img [src]="'https://www.google.com/s2/favicons?domain=' + credential.url"/>
                <span class="text-xs">{{ credential.name }}</span>
            </span>
        </div>
        <div class="text-sm mt-4 space-x-4 flex items-center">
            <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" formControlName="confirmDeletion">
                <div class="w-7 h-4 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-brand rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all border-gray-600 peer-checked:bg-brand"></div>
            </label>

            <span class="text-red-500">I Confirm deletion of these credentials</span>
        </div>
    </div>

    <div *ngIf="delete2FA.length > 0">
        <div class="mb-4">The following credentials all have 2FA enabled and we are the 2FA token holder. Please confirm you have off-boarded these by entering the name of each service affected:</div>
        <div formArrayName="confirm2FA">
            <div class="flex" *ngFor="let credential of delete2FA; let i = index">
                <div class="flex-1">
                     <span
                           class="icon-parent px-2 inline-flex h-8 shrink-0 space-x-2 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[1.1rem] text-gray-400 group-hover:text-white "
                     >
                        <img [src]="'https://www.google.com/s2/favicons?domain=' + credential.url"/>
                        <span class="text-xs">{{ credential.name }}</span>
                    </span>
                </div>
                <div class="flex-1">
                    <input
                        [formControlName]="i"
                        type="text"
                        [ngClass]="{'!ring-red-500': getFormControl(i).invalid && getFormControl(i).touched}"
                        [placeholder]="credential.name"
                        class="block text-sm min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="delete2FA.length < 1 && permanentlyDelete.length < 1">
        Are you sure you want to delete this vault?
    </div>

    <div class="flex justify-between mt-8">
        <div (click)="close()" class="cursor-pointer inline-flex justify-center bg-slate-200 cursor-pointer hover:bg-slate-100 text-slate-800 py-2 px-4 border-b-4 border-white rounded-lg">
            Cancel
        </div>
        <button
                [disabled]="confirmationForm.invalid"
                (click)="deleteGroup()"
                class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
            Delete {{ credentialGroup.name }}
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>

