import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getDefaultSelectors } from '@passbot/angular/common';
import { IUserPermission } from '@passbot/shared';
import { getUser } from '@passbot/angular/auth';
import { PERMISSIONS_FEATURE_KEY, PermissionsState } from './permissions.reducer';

export const getPermissionsState = createFeatureSelector<PermissionsState>(PERMISSIONS_FEATURE_KEY);
const { selectAll, getIsProcessing, getError, getSelectedId, getSelected, getById } = getDefaultSelectors<IUserPermission>(getPermissionsState);

const getByGroupId = (groupId: string) =>
    createSelector(selectAll, (permissions) => {
        return permissions.filter((perm) => perm.credentialGroup.id === groupId);
    });

const getByUserId = (userId: string) =>
    createSelector(selectAll, (permissions) => {
        return permissions.filter((perm) => perm.user.id === userId);
    });

const getByUserIdAndGroupId = (userId: string, groupId: string) =>
    createSelector(selectAll, (permissions) => {
        return permissions.find((perm) => perm.user.id === userId && perm.credentialGroup.id === groupId);
    });

const getByUserIdAndGroupIds = (userId: string, groupIds: string[]) =>
    createSelector(selectAll, (permissions) => {
        return permissions.filter((perm) => perm.user.id === userId && groupIds.includes(perm.credentialGroup.id));
    });

const getByUserAndGroupId = (groupId: string) =>
    createSelector(getUser, selectAll, (user, permissions) => {
        return permissions.find((perm) => perm.user.id === user?.id && perm.credentialGroup.id === groupId);
    });

export const permissionsSelectors = {
    selectAll,
    getIsProcessing,
    getError,
    getSelected,
    getSelectedId,
    getById,
    getByGroupId,
    getByUserId,
    getByUserIdAndGroupId,
    getByUserAndGroupId,
    getByUserIdAndGroupIds,
};
