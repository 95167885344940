import { IUser, StoreActions } from '@passbot/shared';
import { createAction, props } from '@ngrx/store';

export const loadUser = createAction(StoreActions.User.loadUser);
export const loadUserSuccess = createAction(StoreActions.User.loadUserSuccess, props<IUser>());
export const loadUserError = createAction(StoreActions.User.loadUserError, props<{ msg: string }>());
export const logout = createAction(StoreActions.User.logout);
export const logoutSuccess = createAction(StoreActions.User.logoutSuccess);

export const authWithDevice = createAction(StoreActions.User.authWithDevice, props<{ method: string }>());
export const authWithDeviceInProgress = createAction(StoreActions.User.authWithDeviceInProgress);

export const authWithDeviceSuccess = createAction(StoreActions.User.authWithDeviceSuccess, props<{ expiration: number }>());
export const authWithDeviceError = createAction(StoreActions.User.authWithDeviceError, props<{ message: string }>());
export const authWithLocalDevice = createAction(StoreActions.User.authWithLocalDevice);

export const needsDeviceAuth = createAction(StoreActions.User.needsDeviceAuth);

export const authWithToken = createAction(StoreActions.User.authWithToken, props<{ token: string }>());
export const authWithTokenSuccess = createAction(StoreActions.User.authWithTokenSuccess);
export const authWithTokenError = createAction(StoreActions.User.authWithTokenError, props<{ message: string }>());
