import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { ICredential, ICredentialGroup } from '@passbot/shared';

import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { CredentialGroupsFacade, CredentialsFacade } from '../../+state';

export interface IDeleteCredentialGroupModalData {
    credentialGroup: ICredentialGroup;
}

@Component({
    selector: 'passbot-delete-group-modal',
    templateUrl: './delete-group-modal.component.html',
})
export class DeleteGroupModalComponent implements OnInit {
    public credentialGroup: ICredentialGroup;
    public credentials: ICredential[];
    public credentialGroups: Record<string, ICredentialGroup[]> = {};
    public isProcessing$ = this.credentialGroupsFacade.isProcessing$;
    public ready = false;
    public permanentlyDelete: ICredential[] = [];
    public delete2FA: ICredential[] = [];

    public confirmationForm = new FormGroup({
        confirm2FA: new FormArray([] as FormControl[]),
        confirmDeletion: new FormControl(false, [this.verifyDeletion.bind(this)]),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) private readonly data: IDeleteCredentialGroupModalData,
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly credentialsFacade: CredentialsFacade,
        private readonly toastr: ToastrService,
        private readonly router: Router,
    ) {
        this.credentialGroup = this.data.credentialGroup;
    }

    public async ngOnInit() {
        this.credentials = await firstValueFrom(this.credentialsFacade.getByGroupId$(this.credentialGroup.id));

        for (let i = 0; i < (this.credentials || []).length; i++) {
            const cred = this.credentials[i];

            const groups = await this.credentialGroupsFacade.groupsByCredentialIdAsync(cred.id);

            this.credentialGroups[cred.id] = groups;

            if (groups.length === 1 && !cred.totpKey) {
                this.permanentlyDelete.push(cred);
            }

            if (cred.totpKey && groups.length === 1) {
                this.delete2FA.push(cred);
                this.confirmationForm.controls.confirm2FA.push(new FormControl('', [this.verifyCredentialConfirmation(cred).bind(this)]));
            }
        }

        this.ready = true;
    }

    public close() {
        this.modal.close();
    }

    public async deleteGroup() {
        await this.credentialGroupsFacade.deleteGroupAsync(this.credentialGroup.id, true);
        this.toastr.success('Vault successfully deleted');
        void this.router.navigateByUrl('/credentials');
        this.close();
    }

    public getFormControl(i: number) {
        return this.confirmationForm.controls.confirm2FA.at(i);
    }

    private verifyCredentialConfirmation(credential: ICredential) {
        return (control: AbstractControl) => {
            if (control.value.toLowerCase() !== credential.name.toLowerCase()) {
                return { unconfirmed: true };
            }

            return null;
        };
    }

    private verifyDeletion(control: AbstractControl) {
        return this.permanentlyDelete.length > 0 && control.value == false ? { noConfirmation: true } : null;
    }
}
