import { Injectable } from '@angular/core';

import { AbstractHostService } from '@passbot/shared';

import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class HostService extends AbstractHostService {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(configService: ConfigService) {
        super(configService);
    }
}
