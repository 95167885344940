import {
    AfterViewInit,
    Component,
    ComponentRef,
    ContentChild,
    InjectionToken,
    Injector,
    Input,
    OnInit,
    StaticProvider,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, slideInDownOnEnterAnimation, slideOutUpOnLeaveAnimation } from 'angular-animations';
import { BaseModalComponent } from '../base/base.modal.component';
import { ModalContentDirective } from './modal-content.directive';

export const MODAL_DATA = new InjectionToken<unknown>('ModalData');
export const MODAL_REF = new InjectionToken<ModalComponent>('ModalRef');

@Component({
    selector: 'passbot-modal-wrapper',
    templateUrl: './modal.component.html',
    animations: [
        fadeInOnEnterAnimation({ duration: 600 }),
        slideInDownOnEnterAnimation({ duration: 600 }),
        fadeOutOnLeaveAnimation({ duration: 300 }),
        slideOutUpOnLeaveAnimation({ duration: 300 }),
    ],
})
export class ModalComponent implements AfterViewInit, OnInit {
    @ViewChild(ModalContentDirective, { static: false })
    public contentDirective: ModalContentDirective;

    @Input() public title: string;
    @Input() public subtitle: string;
    @Input() public closeText = 'Close';
    @Input() public body: TemplateRef<unknown>;
    @Input() public context: unknown;
    @Input() public hideOnClickOutside = true;
    @Input() public hideOnEsc = true;
    @Input() public data: unknown;
    @ContentChild('footerTemplate', { static: false }) public footerTemplateRef: TemplateRef<unknown>;
    @Input() public childComponent: Type<BaseModalComponent>;
    @Input() public template: TemplateRef<unknown>;
    @Input() public html: string;
    @Input() public class: string;

    public childInstance: BaseModalComponent;
    public modalBoxOpenState = false;
    public closing = false;
    public trustedHtml: SafeHtml;

    private childComponentRef: ComponentRef<BaseModalComponent>;
    private readonly closeSubject = new Subject<unknown>();
    public visible = true;

    constructor(private readonly injector: Injector, private readonly sanitizer: DomSanitizer) {}

    public ngOnInit() {
        if (this.html) {
            this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.html);
        }
    }

    public ngAfterViewInit(): void {
        if (this.childComponent) {
            const { viewContainerRef } = this.contentDirective;
            viewContainerRef.clear();

            const providers: StaticProvider[] = [
                { provide: MODAL_DATA, useValue: this.data },
                { provide: MODAL_REF, useValue: this },
            ];

            const injector = Injector.create({ parent: this.injector, providers });

            this.childComponentRef = viewContainerRef.createComponent(this.childComponent, { injector });
            this.childInstance = this.childComponentRef.instance;
            if (this.data) {
                Object.keys(this.data).forEach((key) => {
                    Object.assign(this.childInstance, this.data);
                });
            }
            this.childComponentRef.changeDetectorRef.detectChanges();
            setTimeout(() => {
                this.visible = true;
            }, 1000);
        }
    }

    public cancel(): void {
        this.close();
    }

    public close(response?: unknown): void {
        this.closing = true;
        const closeModal = () => {
            this.closeSubject.next(response);
        };

        if (!response && this.childInstance && this.childInstance.onClose) {
            const outcome = this.childInstance.onClose();
            if (outcome === true) {
                closeModal();
            }
            return;
        }

        closeModal();
        this.visible = false;
    }

    public onClose<T>(): Observable<T> {
        return this.closeSubject.asObservable() as Observable<T>;
    }

    public saveClick(): void {
        if (!this.childInstance.saveDisabled) {
            this.childInstance.onSave!();
        }
    }

    public modalBoxState(eventVal: { isModalBoxActive: boolean; modalBoxId: string }) {
        this.modalBoxOpenState = eventVal.isModalBoxActive;
    }
}
