import { Route } from '@angular/router';
import { CredentialGroupComponent } from './components/credential-group/credential-group.component';
import { IndexComponent } from './components/index/index.component';

export const credentialsRoutes: Route[] = [
    { path: ':credentialGroupId', pathMatch: 'full', component: CredentialGroupComponent },
    {
        // the route below is really just a redirect to an actual group to avoid access to the groups index page
        path: '',
        pathMatch: 'full',
        component: IndexComponent,
    },
];
