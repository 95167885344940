<div class="space-y-8 text-white min-w-[400px] text-center">
    <div *ngIf="!credential" class="h-40 flex items-center justify-center">
        <i class="fa-regular fa-spin fa-spinner text-slate-400 text-6xl"></i>
    </div>
    <ng-container *ngIf="credential">
        <div class="font-semibold text-xl">
            <passbot-credential-image [item]="credential"></passbot-credential-image> {{ credential.name }}
        </div>
        <div class="">
            <div *ngIf="credential.url">Link: <a class="text-brand" [href]="credential.url" target="_blank">{{ credential.url }}</a></div>
        </div>
        <div class="">
            <div class="text-xs font-semibold text-slate-400">USERNAME <i (click)="copy(credential.username, 'Username')" class="fa-regular text-brand font-normal fa-clipboard cursor-pointer" data-tooltip="Copy to clipboard"></i></div>
            <div class="text-lg font-semibold">{{ credential.username }}</div>
        </div>
        <div class="">
            <div class="text-xs font-semibold text-slate-400">PASSWORD <i (click)="copy(credential.password, 'Password')" class="fa-regular text-brand font-normal fa-clipboard cursor-pointer" data-tooltip="Copy to clipboard"></i></div>
            <div class="text-lg font-semibold">{{ credential.password }}</div>
        </div>
        <div *ngIf="credential.totpKey">
            <div class="text-xs font-semibold text-slate-400">2FA Code <i *ngIf="mfaCode" (click)="copy(credential.password, '2FA Code')" class="fa-regular text-brand font-normal fa-clipboard cursor-pointer" data-tooltip="Copy to clipboard"></i></div>
            <div (click)="getMFACode()" *ngIf="!mfaCode && !loadingMfa" class="text-lg font-semibold text-brand cursor-pointer">CLICK TO REVEAL</div>
            <div *ngIf="mfaCode" class="text-lg font-semibold text-white cursor-pointer">{{ mfaCode }}</div>
            <div *ngIf="loadingMfa && !mfaCode"><i class="fa-regular fa-spinner fa-spin text-white mt-1"></i></div>
        </div>
    </ng-container>
    <div class="text-center !mt-16">
        <span class="border-brand transition-all rounded border text-brand p-2 hover:bg-brand hover:text-white cursor-pointer" (click)="close()">CLOSE</span>
    </div>
    <div class="text-xs text-white/50">
        This will automatically close in {{ timeRemaining }} seconds.
    </div>
</div>
