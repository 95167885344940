export enum Entities {
    Credential = 'credential',
    Credential_Group = 'credential_group',
    Signup = 'signup',
    Tenant = 'tenant',
    Tenant_Workspace = 'tenant_workspace',
    User = 'user',
    User_Auth_Device = 'user_auth_device',
    User_Permission = 'user_permission',
    Workspace_User = 'workspace_user',
}
