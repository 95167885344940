import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ICredential } from '@passbot/shared';
import { firstValueFrom, map, merge, Subject, take, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { SocketIoService } from '@passbot/angular/socket-io';
import { APIService } from '@passbot/angular/api';
import { credentialsSelectors } from './credentials.selectors';
import {
    createCredential,
    createCredentialError,
    createCredentialSuccess,
    deleteCredential,
    deleteCredentialError,
    deleteCredentialSuccess,
    setCredentialViewed,
    updateCredential,
    updateCredentialError,
    updateCredentialSuccess,
} from './credentials.actions';

@Injectable()
export class CredentialsFacade {
    constructor(
        private readonly store: Store,
        private readonly actions: Actions,
        public readonly sockets: SocketIoService,
        private readonly api: APIService,
    ) {}

    public getAll$ = this.store.pipe(select(credentialsSelectors.selectAll));
    public getId$ = (id: string) => this.store.pipe(select(credentialsSelectors.getById(id)));
    public isProcessing$ = this.store.pipe(select(credentialsSelectors.getIsProcessing));
    public getError$ = this.store.pipe(select(credentialsSelectors.getError));
    public awaiting2FASubject = new Subject<boolean | string>();
    public awaiting2FA$ = this.awaiting2FASubject.asObservable();
    public stats$ = this.store.pipe(select(credentialsSelectors.getStats));
    public getByGroupId$ = (groupId: string) => this.store.pipe(select(credentialsSelectors.getByGroupId(groupId)));
    public cancel2FA = new Subject<void>();
    public search$ = (searchStr: string) => this.store.pipe(select(credentialsSelectors.searchCredentials(searchStr)));
    public haveCredentials$ = this.store.pipe(select(credentialsSelectors.selectAll)).pipe(map((creds) => creds.length > 0));
    public viewed = this.store.pipe(select(credentialsSelectors.getViewed));

    public createCredential(credential: Partial<ICredential>) {
        this.store.dispatch(createCredential(credential));
    }

    public updateCredential(credential: ICredential) {
        this.store.dispatch(updateCredential(credential));
    }

    public createCredentialAsync(credential: Partial<ICredential>) {
        this.createCredential(credential);

        const credentialSuccess = this.actions.pipe(ofType(createCredentialSuccess));
        const credentialError = this.actions.pipe(ofType(createCredentialError));

        return firstValueFrom(merge(credentialSuccess, credentialError));
    }

    public updateCredentialAsync(credential: ICredential) {
        this.updateCredential(credential);

        const updateSuccess = this.actions.pipe(ofType(updateCredentialSuccess));
        const updateError = this.actions.pipe(ofType(updateCredentialError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }

    public cancel2FAListener() {
        this.cancel2FA.next();
        this.awaiting2FASubject.next(false);
    }

    public async scan2FA() {
        this.awaiting2FASubject.next(true);
        const response = await this.api.getAsync<{ uuid: string }>('/credential/send-scan-link');
        this.sockets
            .awaitResponse<{ totp: string }>(response.uuid)
            .pipe(takeUntil(this.cancel2FA), take(1))
            .subscribe(({ totp }) => {
                this.awaiting2FASubject.next(totp);
            });
    }

    public async getTotp(secret: string) {
        const response = await this.api.postAsync<{ TOTP: string }>('/credential/2fa-token', { secret });
        return response.TOTP;
    }

    public getByIdAsync(credentialId: string) {
        return firstValueFrom(this.store.pipe(select(credentialsSelectors.getById(credentialId))));
    }

    public deleteCredential(credential: ICredential, groupId: string, cascade = false) {
        this.store.dispatch(deleteCredential({ credential, groupId, cascade }));
    }

    public deleteCredentialAsync(credential: ICredential, groupId: string, cascade = false) {
        this.deleteCredential(credential, groupId, cascade);

        const deleteSuccess = this.actions.pipe(ofType(deleteCredentialSuccess));
        const deleteError = this.actions.pipe(ofType(deleteCredentialError));

        return firstValueFrom(merge(deleteSuccess, deleteError));
    }

    public onCreate() {
        return this.actions.pipe(
            ofType(createCredentialSuccess),
            map((payload) => payload.credential),
        );
    }

    public setViewed(credential: ICredential) {
        return this.store.dispatch(setCredentialViewed({ credentialId: credential.id }));
    }
}
