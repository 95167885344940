import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { PermissionsCountPipe, PermissionsModule } from '@passbot/angular/permissions';
import { AdminComponent } from './components/admin/admin.component';
import { CreateVaultModalComponent } from './components/create-vault-modal/create-vault-modal.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule.forChild([]), NgSelectModule, PermissionsModule],
    declarations: [AdminComponent, CreateVaultModalComponent],
    exports: [AdminComponent, CreateVaultModalComponent, PermissionsCountPipe],
})
export class AdminModule {}
