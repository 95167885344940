import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { filter, map, Observable, Subject, switchMap, tap, zip } from 'rxjs';

import { UserFacade } from '@passbot/angular/auth';
import { ILayoutProperties, LayoutService, ViewContainerService } from '@passbot/angular/common';
import { AddCredentialModalComponent, CredentialGroupsFacade, CredentialsFacade, ViewCredentialModalComponent } from '@passbot/angular/credentials';
import { KeepaliveService } from '@passbot/angular/keepalive';
import { TenantService } from '@passbot/angular/tenant';
import { ICredential, ICredentialGroup, ITenant, IUser, Permissions } from '@passbot/shared';
import { ModalService } from '@passbot/angular/modal';
import { CreateVaultModalComponent } from '@passbot/angular/admin';
import { UsersFacade } from '@passbot/angular/permissions';

@Component({
    selector: 'passbot-root',
    templateUrl: './app.component.html',
    animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()],
})
export class AppComponent {
    @ViewChild('searchInput') public searchInput: ElementRef;
    public layoutProps: ILayoutProperties;
    public tenant: ITenant;
    public user: IUser;
    public showMobileMenu = false;
    public showUserMenu = false;
    public credentialsGroups$: Observable<ICredentialGroup[]> = this.credentialGroupsFacade.getAll$;
    public ready = false;
    public writePermission: Permissions.Write;
    public searchSubject = new Subject<string>();
    public searchResultsVisible = false;
    public haveVaults$ = this.credentialGroupsFacade.haveVaults$;
    public searchResults$ = this.searchSubject.asObservable().pipe(
        tap((searchStr) => (this.searchResultsVisible = searchStr !== '')),
        filter((searchStr) => searchStr !== ''),
        switchMap((searchStr) =>
            zip(this.credentialGroupsFacade.search$(searchStr), this.credentialsFacade.search$(searchStr), this.usersFacade.searchUsers$(searchStr)),
        ),
        map((results) => ({
            groups: results[0],
            credentials: results[1],
            users: results[2],
        })),
    );

    constructor(
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly layoutService: LayoutService,
        private readonly router: Router,
        private readonly tenantService: TenantService,
        private readonly userFacade: UserFacade,
        private readonly keepalive: KeepaliveService,
        private readonly modalService: ModalService,
        private readonly credentialsFacade: CredentialsFacade,
        private readonly usersFacade: UsersFacade,
        private readonly viewRef: ViewContainerRef,
        private readonly viewContainerService: ViewContainerService,
    ) {
        this.layoutService.getProperties().subscribe((props) => (this.layoutProps = props));

        this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(() => {
            this.showMobileMenu = false;
            this.keepalive.trackActivity();
        });

        this.tenant = this.tenantService.getTenant() as ITenant;

        this.userFacade.getUser$.subscribe((user) => {
            this.user = user;
            this.ready = true;
        });

        this.viewContainerService.setRoot(this.viewRef);
    }

    public closeMobileMenu() {
        this.showMobileMenu = false;
    }

    public toggleUserMenu() {
        this.showUserMenu = !this.showUserMenu;
    }

    public logout() {
        this.userFacade.logout();
    }

    public createVault() {
        this.modalService.show(CreateVaultModalComponent);
    }

    public addCredential() {
        this.modalService.show(AddCredentialModalComponent);
    }

    public doSearch(ev: any) {
        this.searchSubject.next(ev.target.value);
    }

    public async editCredential(credential: ICredential) {
        this.closeSearch();
        const groups = await this.credentialGroupsFacade.groupsByCredentialIdAsync(credential.id);
        this.modalService.show(AddCredentialModalComponent, { data: { credential: { ...credential, credentialGroups: groups } } });
    }

    public viewCredential(credential: ICredential) {
        this.closeSearch();
        this.modalService.show(ViewCredentialModalComponent, { data: { credentialId: credential.id } });
    }

    public closeSearch() {
        this.searchInput.nativeElement.value = '';
        this.searchResultsVisible = false;
    }
}
