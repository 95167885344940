import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';

import { ConfigService, HostService, IConfig } from './services';

export const APP_CONFIG = new InjectionToken<IConfig>('app.config');

@NgModule({
    imports: [CommonModule],
    providers: [
        ConfigService,
        HostService,
    ],
})
export class ConfigModule {
    constructor(@Optional() @SkipSelf() parentModule?: ConfigModule) {
        if (parentModule) {
            throw new Error('ConfigModule is already loaded. Import it in the AppModule only');
        }
    }
}
