<div class="h-full space-y-8 p-8 flex flex-col overflow-hidden">
    <div class="flex space-x-4">
        <div class="flex flex-col justify-center">
            <i class="fa-light fa-file-invoice text-brand text-5xl"></i>
        </div>
        <div class="flex flex-col justify-center">
            <h2 class="text-brand text-2xl font-semibold">Billing</h2>
            <h4 class="text-white/70">Manage your organisations subscription and billing information</h4>
        </div>
    </div>
    <div class="flex-1 flex text-white flex-col items-center space-y-4 overflow-y-auto scrollbar-thumb-border-l-8 pr-2 scrollbar-thin scrollbar-thumb-slate-700 scrollbar-thumb-rounded-full scrollbar scrollbar-track-transparent" *ngIf="subscription">
        <div class="rounded-lg bg-slate-900/50 border border-gray-700 text-white overflow-hidden w-full">
            <div class="bg-slate-900 p-4">
                <h5 class="text-semibold text-lg text-brand">Plan</h5>
            </div>
            <div class="p-4">
                <div class="grid grid-cols-2 md:grid-cols-3 justify-center items-center">
                    <div class="space-y-2">
                        <h2 class="text-xl font-semibold">
                            <ng-container *ngIf="subscription.billing_period_unit === 'month'">Monthly Pay As You Go</ng-container>
                            <ng-container *ngIf="subscription.billing_period_unit === 'year'">Annual Upfront</ng-container>
                        </h2>
                        <div class="text-sm text-white/50"><span class="font-semibold text-white">Active Since:</span> {{ subscription.created_at! * 1000 | date }}</div>
                        <div class="text-sm text-white/50"><span class="font-semibold text-white">Next billing date:</span> {{ subscription.next_billing_at! * 1000 | date }}</div>
                    </div>
                    <div class="text-center">
                        <div class="text-4xl font-semibold">{{ subscription.pending?.subscription_items![0].amount || subscription.subscription_items![0].amount | passbotCurrency }}</div>
                        <div class="text-xs text-white/50">Estimated Monthly Cost</div>
                        <div (click)="priceBreakdownVisible = !priceBreakdownVisible" class="text-xs text-brand cursor-pointer mt-2">{{ priceBreakdownVisible ? 'hide' : 'show' }} price breakdown <i class="fa-light" [ngClass]="{ 'fa-chevron-down': !priceBreakdownVisible, 'fa-chevron-up': priceBreakdownVisible }"></i></div>

                    </div>

                    <div class="flex items-center md:justify-end justify-center pt-6 md:pt-0 space-x-4 col-span-2 md:col-span-1">
                        <div>
                            <div class="flex items-center justify-center relative w-[124px] h-[124px]">
                                <svg class="absolute w-full h-full top-0 left-0 -rotate-90" aria-hidden="true">
                                    <circle
                                            class="text-gray-300"
                                            stroke-width="10"
                                            stroke="rgb(255 255 255 / 0.2)"
                                            fill="transparent"
                                            r="50"
                                            cx="62"
                                            cy="62"
                                    />
                                    <circle
                                            class="text-red-600"
                                            stroke-width="10"
                                            [attr.stroke-dasharray]="circumference"
                                            [attr.stroke-dashoffset]="circumference - activePercent / 100 * circumference"
                                            stroke-linecap="butt"
                                            stroke="currentColor"
                                            fill="transparent"
                                            r="50"
                                            cx="62"
                                            cy="62"
                                    />
                                    <circle
                                            class="text-red-600 origin-center stroke-yellow-400"
                                            stroke-width="10"
                                            [attr.stroke-dasharray]="circumference"
                                            [attr.stroke-dashoffset]="circumference - tempPercent / 100 * circumference"
                                            stroke-linecap="butt"
                                            fill="transparent"
                                            r="50"
                                            cx="62"
                                            cy="62"
                                            [style.transform]="'rotate(' + (360 * (activePercent / 100)) + 'deg)'"
                                    />
                                </svg>
                                <span class="absolute text-2xl text-red-700 left-0 top-0 h-full w-full flex items-center justify-center">{{ activePercent + tempPercent | number:'1.0-2'}}%</span>
                            </div>
                        </div>
                        <div class="space-y-2">
                            <div class="font-semibold text-lg">Usage</div>
                            <div><span>{{ seats.inUse }} out of {{ seats.seats }} seats</span></div>
                            <div class="text-sm text-yellow-400" *ngIf="seats.pending">Reducing to {{ seats.pending }} seats<br/> on {{ seats.pendingDate | date }} </div>
                            <div>
                                <button (click)="editSeats()" class="text-sm bg-brand hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 rounded-lg">
                                    Edit Seats
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="bg-slate-900 px-4 py-0 grid grid-cols-4 text-white/50 transition-all h-0 overflow-hidden" [ngClass]="{ 'h-[56px] py-4': priceBreakdownVisible}">
                <div>Seats</div>
                <div class="">Cost</div>
                <div class="text-center">Used</div>
                <div class="text-right">Subtotal</div>
            </div>
            <ng-container  *ngFor="let tier of subscription.pending?.item_tiers || subscription.item_tiers; let i = index">
                <div class="grid grid-cols-4 px-4 py-0 max-h-0 overflow-hidden transition-all" *ngIf="i !== 1" [ngClass]="{'bg-slate-900': i !== 0 && i%2 === 0, 'max-h-[48px] py-2': priceBreakdownVisible}">
                    <div class="text-sm md:text-md">{{ tier.starting_unit}} - {{ i === 0 ? 5 : (tier.ending_unit || '*') }}</div>
                    <div class="hidden md:block">{{ tier.price | passbotCurrency }} <span class="text-xs text-white/50">{{ i === 0 ? 'Flat Fee' : 'Per User, Per Month' }}</span></div>
                    <div class="md:hidden tex-sm">{{ tier.price | passbotCurrency }} <span class="text-xs text-white/50">{{ i === 0 ? 'Flat Fee' : 'User, Month' }}</span></div>
                    <div class="text-center" [ngClass]="{ 'text-white/30': (seats.pending || seats.seats || 5) - (tier.starting_unit! - 1 || 0) <= 0}">{{ (seats.pending || seats.seats || 5) - (tier.starting_unit! - 1 || 0) | passbotMinMax:0: (i === 0 ? 5 : tier.ending_unit!) - (tier.starting_unit! - 1) }} </div>
                    <div class="text-right font-semibold" [ngClass]="{ 'text-white/30 text-normal': (i === 0 ? tier.price : ((seats.pending || seats.seats || 5) - (tier.starting_unit! - 1 || 0) | passbotMinMax:0: (i === 0 ? 5 : tier.ending_unit!) - (tier.starting_unit! - 1)) * tier.price!)  === 0}">{{ (i === 0 ? tier.price : ((seats.pending || seats.seats || 5) - (tier.starting_unit! - 1 || 0) | passbotMinMax:0: (i === 0 ? 5 : tier.ending_unit!) - (tier.starting_unit! - 1)) * tier.price!) | passbotCurrency }} </div>
                </div>
            </ng-container>
        </div>


        <div class="rounded-lg bg-slate-900/50 border border-gray-700 text-white overflow-hidden m-auto w-full">
            <div class="bg-slate-900 p-4">
                <h5 class="text-semibold text-lg text-brand">Payment Methods</h5>
            </div>
            <div class="bg-slate-900 px-4 py-4 grid grid-cols-3 text-white/50 transition-all overflow-hidden text-sm md:text-md items-center">
                <div>Card</div>
                <div class="text-center">Expiry</div>
                <div class="text-right">Billing Post Code</div>
            </div>
            <div *ngFor="let source of paymentSources; let i = index" class="grid grid-cols-3 px-4 py-2" [ngClass]="{'bg-slate-900': i%2 !== 0}">
                <ng-container *ngIf="source.payment_source.card">
                    <div><span class="hidden md:inline-block"><i class=" fa-brands text-xl fa-cc-{{ source.payment_source.card.brand }}"></i></span> {{ source.payment_source.card.masked_number}}</div>
                    <div class="text-center">{{ source.payment_source.card.expiry_month}}/{{ source.payment_source.card.expiry_year}}</div>
                    <div class="text-right">{{ source.payment_source.card.billing_zip}}</div>
                </ng-container>
            </div>
            <div class="p-4 text-right">
                <button (click)="updatePaymentMethods()" class="text-sm bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg">
                    Update Payment Methods
                </button>
            </div>
        </div>

        <div class="rounded-lg bg-slate-900/50 border border-gray-700 text-white overflow-hidden m-auto w-full">
            <div class="bg-slate-900 p-4">
                <h5 class="text-semibold text-lg text-brand">Billing History</h5>
            </div>
            <div class="bg-slate-900 px-4 py-4 grid grid-cols-5 text-white/50 transition-all overflow-hidden text-sm md:text-md items-center">
                <div>Date</div>
                <div class="text-center">Amount</div>
                <div class="text-center">Payment Method</div>
                <div class="text-center">Status</div>
                <div class="text-right">Invoice</div>
            </div>
            <div *ngFor="let t of transactions; let i = index" class="grid grid-cols-5 px-4 py-2 text-sm md:text-md items-center align-middle" [ngClass]="{'bg-slate-900': i%2 !== 0}">
                <div>{{ t.transaction.date! * 1000 | date }}</div>
                <div class="text-center">{{ t.transaction.amount | passbotCurrency}}</div>
                <div class="text-center">{{ t.transaction.payment_method }}</div>
                <div class="text-center" [ngClass]="{'text-green-500': t.transaction.status === 'success'}">{{ t.transaction.status }}</div>
                <div class="text-right"><i *ngIf="t.transaction.status === 'success' && t.transaction.linked_invoices && t.transaction.linked_invoices.length > 0" class="fa-regular cursor-pointer fa-download text-brand" data-tooltip="download invoice" data-tooltip-position="left" (click)="downloadInvoice(t.transaction.linked_invoices[0].invoice_id!)"></i></div>
            </div>
        </div>
    </div>
</div>
