import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs/operators';
import { APIService } from '@passbot/angular/api';
import { IUser } from '@passbot/shared';
import { of } from 'rxjs';
import {
    createUser,
    createUserError,
    createUserSuccess,
    loadUsers,
    loadUsersError,
    loadUsersSuccess,
    removeUser,
    removeUserError,
    removeUserSuccess,
    updateUser,
    updateUserError,
    updateUserSuccess,
} from './users.actions';

@Injectable()
export class UsersEffects {
    public loadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadUsers),
            fetch({
                // provides an action
                run: () => {
                    return this.apiService.call<IUser[]>('/user').pipe(map((users) => loadUsersSuccess({ users })));
                },

                onError: (err) => {
                    return of(loadUsersError({ msg: 'Failed to load users' }));
                },
            }),
        ),
    );

    public updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUser),
            fetch({
                // provides an action
                run: (props) => {
                    return this.apiService.put<IUser>(`/user/${props.id}`, props).pipe(map((user) => updateUserSuccess(user)));
                },

                onError: (err) => {
                    return of(updateUserError({ msg: 'Failed to update user' }));
                },
            }),
        ),
    );

    public createUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createUser),
            fetch({
                // provides an action
                run: (props) => {
                    return this.apiService.post<IUser>('/user', props).pipe(map((user) => createUserSuccess(user)));
                },

                onError: (err) => {
                    return of(createUserError({ msg: 'Failed to create user' }));
                },
            }),
        ),
    );

    public removeUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeUser),
            fetch({
                run: ({ userId }) => {
                    return this.apiService.delete(`/user/${userId}`).pipe(map(() => removeUserSuccess({ userId })));
                },

                onError: (err) => {
                    return of(removeUserError({ msg: 'Failed to remove user' }));
                },
            }),
        ),
    );

    constructor(private readonly actions$: Actions, private readonly apiService: APIService) {}
}
