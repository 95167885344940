import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IConfig } from '@passbot/angular/config';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class SocketConnectionService extends Socket {
    constructor(@Inject(APP_CONFIG) config: IConfig) {
        super({ url: window.location.host, options: { withCredentials: true, path: '/api/socket.io/' } });
    }
}
