import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { isDefined, ITenantWorkspace, IUser } from '@passbot/shared';
import { DevicesFacade } from '@passbot/angular/devices';
import { filter } from 'rxjs/operators';
import { UserFacade } from '../+state';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private windowObjectReference: Window | null;
    private previousUrl: string;
    private user: IUser | undefined;

    constructor(private readonly router: Router, private readonly userFacade: UserFacade, private readonly devicesFacade: DevicesFacade) {}

    public async checkAuth(state: ActivatedRouteSnapshot) {
        if (!this.user) {
            if (state.queryParams.token) {
                await this.userFacade.authWithTokenAsync(state.queryParams.token);
            }

            this.user = await this.userFacade.authCheck();
        }

        if (!this.user) {
            await this.router.navigate(['/login'], {
                queryParams: {
                    ...state.queryParams,
                    ...this.getParamsFromFragment(state.fragment as string),
                },
                preserveFragment: true,
                queryParamsHandling: 'merge',
            });
            return false;
        }

        return !!this.user;
    }

    public hasUser() {
        return !!this.user;
    }

    public async checkUserHasDevices(redirect = true) {
        const devices = await this.devicesFacade.loadDevicesAsync();

        if (!devices.items?.length) {
            if (redirect) {
                await this.router.navigate(['/onboarding']);
            }

            return false;
        }

        return true;
    }

    public async login(workspace: Partial<ITenantWorkspace>, isSignup = false) {
        return new Promise<IUser>((resolve, reject) => {
            const handleMessage = (message: MessageEvent) => {
                // debug
                // if (message.origin.includes('passbot.io')) {
                //     console.log(message);
                // }

                if (message.origin.includes('passbot.io') && this.router.url.includes('/login') && message.data?.passbotUser !== undefined) {
                    window.removeEventListener('message', (ev) => handleMessage(ev));
                    if (!message.data.passbotUser || message.data.error) {
                        reject(message.data.error);
                        return;
                    }

                    this.userFacade.loadUser();

                    this.userFacade.getUser$.pipe(filter(isDefined)).subscribe((user) => {
                        this.user = user;
                        resolve(user);
                    });
                }
            };

            // remove any existing event listeners
            window.removeEventListener('message', (ev) => handleMessage(ev));

            const windowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
            const url = `/api/auth/${workspace.platform}/${isSignup ? 'signup' : 'login'}?${
                workspace.platformId ? `workspaceId=${workspace.platformId}` : ''
            }`;
            const name = 'login';

            if (!this.windowObjectReference || this.windowObjectReference.closed) {
                /* if the pointer to the window object in memory does not exist
                 or if such pointer exists but the window was closed */
                this.windowObjectReference = window.open(url, name, windowFeatures);
            } else if (this.previousUrl !== url) {
                /* if the resource to load is different,
                 then we load it in the already opened secondary window and then
                 we bring such window back on top/in front of its parent window. */
                this.windowObjectReference = window.open(url, name, windowFeatures);
                (this.windowObjectReference as Window).focus();
            } else {
                /* else the window reference must exist and the window
                 is not closed; therefore, we can bring it back on top of any other
                 window with the focus() method. There would be no need to re-create
                 the window or to reload the referenced resource. */
                this.windowObjectReference.location.href = url;
                this.windowObjectReference.focus();
            }
            this.windowObjectReference!.name = name;

            window.addEventListener('message', (ev) => handleMessage(ev), false);

            this.previousUrl = url;
        });
    }

    public async logout(url?: string) {
        this.user = undefined;
        await this.userFacade.logoutAsync();
        if (url) {
            window.location.href = url;
        } else {
            if (window.location.href.includes('/login')) {
                window.location.reload();
            } else {
                window.location.href = '/login';
            }
        }
    }

    private getParamsFromFragment(fragment: string) {
        if (fragment && fragment.includes('?')) {
            const queryString = fragment.split('?').pop();
            return (queryString as string).split('&').reduce((params, param) => {
                const parts = param.split('=');
                return { ...params, [parts[0]]: parts[1] };
            }, {} as Record<string, string>);
        }
        return {};
    }
}
