import { ActivatedRouteSnapshot, Route, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { AuthService } from '@passbot/angular/auth';
import { inject } from '@angular/core';
import { NotFoundComponent } from '@passbot/angular/not-found';
import { AdminComponent } from '@passbot/angular/admin';

export const appRoutes: Route[] = [
    {
        path: '',
        data: { sidebarVisible: true },
        canActivate: [async (state: ActivatedRouteSnapshot) => inject(AuthService).checkAuth(state)],
        children: [
            {
                canActivate: [() => inject(AuthService).checkUserHasDevices()],
                //canMatch: [() => inject(CredentialsFacade).initCredentials()],
                path: '',
                component: AdminComponent,
                data: {
                    title: 'Home',
                    sidebarVisible: true,
                },
                children: [
                    {
                        path: '',
                        loadChildren: async () => (await import('@passbot/angular/dashboard')).DashboardModule,
                    },
                    {
                        path: 'credentials',
                        data: {
                            title: 'Credentials',
                        },
                        loadChildren: async () => (await import('@passbot/angular/credentials')).CredentialsModuleRouted,
                    },
                    {
                        path: 'devices',
                        data: {
                            title: 'Manage Auth Devices',
                        },
                        loadChildren: async () => (await import('@passbot/angular/devices')).DevicesModuleRouted,
                    },
                    {
                        path: 'users',
                        data: {
                            title: 'Manage Users',
                        },
                        loadChildren: async () => (await import('@passbot/angular/permissions')).PermissionsModuleRouted,
                    },
                    {
                        path: 'billing',
                        data: {
                            title: 'Manage Billing',
                        },
                        loadChildren: async () => (await import('@passbot/angular/billing')).BillingModuleRouted,
                    },
                    {
                        path: 'audit-log',
                        data: {
                            title: 'Audit Log',
                        },
                        loadChildren: async () => (await import('@passbot/angular/audit-log')).AuditLogModule,
                    },
                    {
                        path: 'health-check',
                        data: {
                            title: 'Health Check',
                        },
                        loadChildren: async () => (await import('@passbot/angular/reports')).ReportsModule,
                    },
                ],
            },
            {
                path: '',
                data: {
                    title: 'Onboarding',
                    sidebarVisible: false,
                },
                canActivate: [
                    async () => {
                        const hasDevices = await inject(AuthService).checkUserHasDevices(false);

                        if (hasDevices) {
                            // Redirect to /devices.
                            return new UrlTree(
                                new UrlSegmentGroup([], {
                                    primary: new UrlSegmentGroup([new UrlSegment('devices', {})], {}),
                                }),
                            );
                        }

                        return true;
                    },
                ],
                children: [
                    {
                        path: 'onboarding',
                        loadChildren: async () => (await import('@passbot/angular/onboarding')).OnboardingModule,
                    },
                ],
            },
            { path: '404', component: NotFoundComponent },
            { path: '**', redirectTo: '/404' },
        ],
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' },
];
