<div
        class="space-y-8 text-white w-[500px] text-left"
        [formGroup]="userForm"
>
    <h3 class="text-center font-semibold text-lg text-brand">Promote User</h3>
    <div class="space-y-8">
        <div>
            As you have disabled a user, you can choose a user currently marked for de-activation at the end of the billing cycle to
            remain as an active user.
        </div>
        <div>
            <label for="user" class="text-xs mb-1" >User *</label>
            <ng-select [items]="users$ | async | passbotAlphabeticalSort: 'name'"
                       bindLabel="name"
                       labelForId="group"
                       id="user"
                       [addTag]="false"
                       [multiple]="false"
                       [hideSelected]="false"
                       [minTermLength]="2"
                       [loading]="false"
                       typeToSearchText="Please enter 2 or more characters"
                       formControlName="user"
                       >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div class="flex space-x-2 items-center" [style.color]="item.colorHex === '#000000' ? '#fff' : item.colorHex"><img class="rounded-full w-10 h-10" [src]="item.avatarUrl"> <span class="flex-1">{{ item.name }}</span></div>
                </ng-template>
            </ng-select>
        </div>
        <div class="flex justify-between">
            <div (click)="close()" class="cursor-pointer inline-flex justify-center bg-slate-200 cursor-pointer hover:bg-slate-100 text-slate-800 py-2 px-4 border-b-4 border-white rounded-lg">
                Cancel
            </div>
            <div class="text-center text-xs text-white flex items-center justify-center">
                * required field
            </div>
            <button
                    (click)="promoteUser()"
                    [disabled]="!(userForm.valid && userForm.dirty)"
                    class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
                Save
            </button>
        </div>
    </div>
</div>
