import { ICredential, StoreActions } from '@passbot/shared';
import { createAction, props } from '@ngrx/store';

export const createCredential = createAction(StoreActions.Credentials.createCredential, props<Partial<ICredential>>());
export const createCredentialSuccess = createAction(StoreActions.Credentials.createCredentialSuccess, props<{ credential: ICredential }>());
export const createCredentialError = createAction(StoreActions.Credentials.createCredentialError, props<{ msg: string }>());

export const updateCredential = createAction(StoreActions.Credentials.updateCredential, props<ICredential>());
export const updateCredentialSuccess = createAction(StoreActions.Credentials.updateCredentialSuccess, props<{ credential: ICredential }>());
export const updateCredentialError = createAction(StoreActions.Credentials.updateCredentialError, props<{ msg: string }>());

export const deleteCredential = createAction(
    StoreActions.Credentials.deleteCredential,
    props<{ credential: ICredential; groupId: string; cascade?: boolean }>(),
);
export const deleteCredentialSuccess = createAction(
    StoreActions.Credentials.deleteCredentialSuccess,
    props<{ id: string; groupId: string; cascade: boolean }>(),
);
export const deleteCredentialError = createAction(StoreActions.Credentials.deleteCredentialError, props<{ msg: string }>());

export const setCredentialViewed = createAction(StoreActions.Credentials.setCredentialViewed, props<{ credentialId: string }>());
