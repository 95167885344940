import { Route } from '@angular/router';
import { RegisterComponent } from './components/register/register.component';
import { AuthComponent } from './components/auth/auth.component';

export const webauthnRoutes: Route[] = [
    {
        path: 'webauth',
        data: {
            title: 'Authenticate',
            sidebarVisible: false,
        },
        children: [
            { path: '', component: AuthComponent },
            { path: 'register', component: RegisterComponent },
        ],
    },
];
