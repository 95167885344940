import { Component, Inject } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WebauthService } from '@passbot/angular/webauthn';
import { UserFacade } from '@passbot/angular/auth';
import { MODAL_REF, ModalComponent } from '@passbot/angular/modal';

@Component({
    selector: 'passbot-add-local-device',
    templateUrl: './add-local-device.component.html',
})
export class AddLocalDeviceComponent {
    public isProcessing = false;
    public destroyed$ = new Subject<void>();
    public error: string;

    public localForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        private readonly toastr: ToastrService,
        private readonly webAuthN: WebauthService,
        private readonly userFacade: UserFacade,
    ) {}

    public close() {
        if (this.modal) {
            this.modal.close();
        }
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public async addDevice() {
        if (this.localForm.invalid) {
            return;
        }

        this.isProcessing = true;

        const user = await firstValueFrom(this.userFacade.getUser$);

        const response = await this.webAuthN.registerDevice(user.name, 'local', this.localForm.controls.name.value as string);
        if (response.error) {
            this.toastr.error('Issue registering auth device, please try again');
            this.isProcessing = false;
            return;
        }
        this.toastr.success('Local authentication device added');
        this.modal.close();
    }
}
