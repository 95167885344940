import { Route } from '@angular/router';
import { inject } from '@angular/core';
import { TenantGuard } from '@passbot/angular/tenant';
import { LoginComponent } from './components/login/login.component';

export const authRoutes: Route[] = [
    {
        path: 'login',
        data: { sidebarVisible: false },
        pathMatch: 'full',
        component: LoginComponent,
        canActivate: [async () => inject(TenantGuard).canActivate()],
    },
];
