import { IUserAuthDevice, StoreActions } from '@passbot/shared';
import { createAction, props } from '@ngrx/store';

export const loadDevices = createAction(StoreActions.Devices.loadDevices);
export const loadDevicesSuccess = createAction(StoreActions.Devices.loadDevicesSuccess, props<{ items: IUserAuthDevice[] }>());
export const loadDevicesError = createAction(StoreActions.Devices.loadDevicesError, props<{ msg: string }>());

export const clearDevices = createAction(StoreActions.Devices.clearDevices);

export const addDevice = createAction(
    StoreActions.Devices.addDevice,
    props<{ deviceType: string; target: string; name: string; isRegistration?: boolean }>(),
);
export const addDeviceInProgess = createAction(StoreActions.Devices.addDeviceInProgress);
export const addDeviceSuccess = createAction(StoreActions.Devices.addDeviceSuccess, props<{ item: IUserAuthDevice }>());
export const removeDevice = createAction(StoreActions.Devices.removeDevice, props<{ item: IUserAuthDevice }>());
export const removeDeviceSuccess = createAction(StoreActions.Devices.removeDeviceSuccess, props<{ item: Partial<IUserAuthDevice> }>());
export const removeDeviceError = createAction(StoreActions.Devices.removeDeviceError, props<{ msg: string }>());
