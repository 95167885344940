import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getDefaultSelectors, IEntityState } from '@passbot/angular/common';
import { calculateSecurityStats, ICredential } from '@passbot/shared';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { CredentialsState } from './credentials.reducer';

export const getState = createFeatureSelector<CredentialsState>('credentials');

const { selectAll, getIsProcessing, getError, getSelectedId, getSelected, getById } = getDefaultSelectors<ICredential>(
    getState as unknown as MemoizedSelector<object, IEntityState<ICredential>>,
);

export const getByGroupId = (groupId: string) =>
    createSelector(selectAll, (credentials) => {
        return credentials.filter((cred) => cred?.credentialGroups?.map((group) => group.id).includes(groupId)) as ICredential[];
    });

export const getStats = createSelector(selectAll, (credentials) => {
    return calculateSecurityStats(credentials);
});

export const getCredentialsAlphabetically = createSelector(selectAll, (credentials) => {
    return credentials.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
});

export const searchCredentials = (searchStr: string) =>
    createSelector(getCredentialsAlphabetically, (credentials) => {
        return credentials.filter((cred) => cred.name.toLowerCase().includes(searchStr.toLowerCase())).slice(0, 5);
    });

export const getViewed = createSelector(getState, (state) => state.viewed);

export const credentialsSelectors = {
    selectAll,
    getIsProcessing,
    getError,
    getSelected,
    getSelectedId,
    getById,
    getByGroupId,
    getStats,
    getCredentialsAlphabetically,
    searchCredentials,
    getViewed,
};

// export const getHydratedGroup = (credentialGroupId: string) => createSelector(getById(credentialGroupId), (credentialGroup) => credentialGroup);
