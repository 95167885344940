import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { filter } from 'rxjs/operators';
import { isDefined, IUser } from '@passbot/shared';
import { firstValueFrom, map, merge, Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { getInProgress, getUser, getUserError, getUserLoaded } from './user.selectors';
import * as userActions from './user.actions';
import { authWithDevice, authWithLocalDevice, authWithTokenError, authWithTokenSuccess, logoutSuccess } from './user.actions';

@Injectable()
export class UserFacade {
    constructor(private readonly store: Store, private readonly actions: Actions) {}

    public loaded$ = this.store.pipe(select(getUserLoaded));
    public getUser$ = this.store.pipe(select(getUser)) as Observable<IUser>;
    public getError$ = this.store.pipe(select(getUserError));
    public inProgress$ = this.store.pipe(select(getInProgress));

    public loadUser() {
        this.store.dispatch(userActions.loadUser());
    }

    public logout() {
        this.store.dispatch(userActions.logout());
    }

    public logoutAsync() {
        this.store.dispatch(userActions.logout());

        const logoutSuccessAction = this.actions.pipe(ofType(logoutSuccess));
        return firstValueFrom(logoutSuccessAction);
    }

    public async getUser() {
        return (await firstValueFrom(this.getUser$.pipe(filter(isDefined)))) as Promise<IUser>;
    }

    public async authCheck() {
        this.loadUser();

        return firstValueFrom(
            merge(this.getUser$.pipe(filter(isDefined)), this.getError$.pipe(filter(isDefined))).pipe(
                map((response) => {
                    // combineLatest returns an array of values, here we map those values to an object
                    if (response && response.id) {
                        return response;
                    }

                    // eslint-disable-next-line consistent-return
                    return undefined;
                }),
            ),
        );
    }

    public authWithToken(token: string) {
        return this.store.dispatch(userActions.authWithToken({ token }));
    }

    public authWithTokenAsync(token: string) {
        this.authWithToken(token);

        const authSuccess = this.actions.pipe(ofType(authWithTokenSuccess));
        const authError = this.actions.pipe(ofType(authWithTokenError));

        return firstValueFrom(merge(authSuccess, authError));
    }

    public authDevice(method: string) {
        if (method === 'local') {
            return this.store.dispatch(authWithLocalDevice());
        }

        return this.store.dispatch(authWithDevice({ method }));
    }

    public async hasLocalWebauthnDevice() {
        if (!window.PublicKeyCredential) {
            return false;
        }

        try {
            const available = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
            return available;
        } catch (e) {
            return false;
        }
    }
}
