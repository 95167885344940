import { createReducer, on } from '@ngrx/store';
import { IUser } from '@passbot/shared';
import { createEntityAdapter } from '@ngrx/entity';
import { entityErrorFn, entityStartProcessing, entityStopProcessing, getDefaultEntityState, IEntityState } from '@passbot/angular/common';
import {
    createUser,
    createUserError,
    createUserSuccess,
    loadUsers,
    loadUsersError,
    loadUsersSuccess,
    removeUser,
    removeUserError,
    removeUserSuccess,
    updateUser,
    updateUserError,
    updateUserSuccess,
} from './users.actions';

export const USERS_FEATURE_KEY = 'users';

export const adapter = createEntityAdapter<IUser>();

export type UsersState = IEntityState<IUser>;

export const initialState: UsersState = getDefaultEntityState(adapter);

export const UsersReducers = createReducer(
    initialState,
    on(loadUsers, (state) => entityStartProcessing(state)),
    on(loadUsersSuccess, (state, { users }) => entityStopProcessing(adapter.upsertMany(users, state))),
    on(loadUsersError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),
    on(createUser, (state) => entityStartProcessing(state)),
    on(createUserSuccess, (state, props) => adapter.upsertOne(props, entityStopProcessing(state))),
    on(createUserError, (state, { msg }) => entityStopProcessing({ ...state, error: msg })),
    on(updateUser, (state) => entityStartProcessing(state)),
    on(updateUserSuccess, (state, props) => adapter.upsertOne(props, entityStopProcessing(state))),
    on(updateUserError, (state, { msg }) => entityStopProcessing({ ...state, error: msg })),
    on(removeUser, (state) => entityStartProcessing(state)),
    on(removeUserSuccess, (state, { userId }) => entityStopProcessing(adapter.removeOne(userId, state))),
    on(removeUserError, (state, { msg }) => entityStopProcessing({ ...state, error: msg })),
);
