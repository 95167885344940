<div class="h-full p-8 flex flex-col">
    <div class="md:flex">
        <div class="flex-1 flex space-x-4">
            <div class="flex flex-col justify-center">
                <i class="fa-light fa-fingerprint text-brand text-5xl"></i>
            </div>
            <div class="flex flex-col justify-center">
                <h2 class="text-brand text-2xl font-semibold">Auth Devices</h2>
                <h4 class="text-white/70">Manage your registered authentication devices</h4>
            </div>
        </div>
        <div class="md:flex hidden items-center relative">
            <a (click)="createOptions()" class="relative peer block z-[20] bg-brand shadow cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg"><i class="fa-light fa-plus"></i> Add new auth device</a>
            <div class="absolute max-h-0 top-[30px] z-[10] divide-y divide-gray-100 rounded-b-lg shadow w-full bg-black hover:max-h-[200px] peer-hover:max-h-[200px] transition-all overflow-hidden">
                <ul class="text-sm text-gray-200 text-center pt-3" aria-labelledby="dropdownDefaultButton">
                    <li class="border-b border-b-slate-800">
                        <a
                            [ngClass]="{ 'hover:bg-slate-900/20 text-gray-300 hover:text-white cursor-pointer': !hasMobile, 'text-slate-700 cursor-not-allowed': hasMobile }"
                            class="block px-4 py-4"
                            [attr.data-tooltip]=" hasMobile ? 'Can only have 1 mobile device added at a time' : null"
                            [attr.data-tooltip-position]="'left'"
                            (click)="addDevice('mobile')"
                        >
                            <i *ngIf="!hasMobile" class="fa-regular fa-mobile text-lg mr-2"></i><i *ngIf="hasMobile" class="fa-regular fa-check text-green-500 text-lg mr-2"></i> <span>Mobile Device</span>
                        </a>
                    </li>
                    <li class="border-b border-b-slate-800">
                        <a (click)="addDevice('local')" class="cursor-pointer block px-4 py-4 hover:bg-slate-900/20 text-gray-300 hover:text-white">
                            <i class="fa-regular fa-up-right-from-square text-lg mr-2"></i> <span>Local Device</span>
                        </a>
                    </li>
                    <li class="">
                        <a (click)="addDevice('email')" class="cursor-pointer block px-4 py-4 hover:bg-slate-900/20 text-gray-300 hover:text-white">
                            <i class="fa-regular fa-envelope text-lg mr-2"></i><span>Other via Email</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="flex-1 flex pt-10">
        <passbot-manage-devices #manageDevicesComponent class="block w-full" [showAddButtons]="false" ></passbot-manage-devices>
    </div>
    <div class="text-center md:hidden relative">
        <a (click)="createOptions()" class="relative block z-[20] bg-brand shadow cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg"><i class="fa-light fa-plus"></i> Add new auth device</a>
        <div [ngClass]="{'max-h-[200px]': optionsVisible, 'max-h-0': !optionsVisible}" class="absolute bottom-[30px] z-[10] divide-y divide-gray-100 rounded-b-lg shadow w-full bg-black  transition-all overflow-hidden">
            <ul class="text-sm text-gray-200 text-center pb-3" aria-labelledby="dropdownDefaultButton">
                <li class="border-b border-b-slate-800">
                    <a
                            href="#"
                            [ngClass]="{ 'hover:bg-slate-900/20 text-gray-300 hover:text-white cursor-pointer': !hasMobile, 'text-slate-700 cursor-not-allowed': hasMobile }"
                            class="block px-4 py-4"
                            [attr.data-tooltip]=" hasMobile ? 'Can only have 1 mobile device added at a time' : null"
                            [attr.data-tooltip-position]="'left'"
                            (click)="addDevice('mobile')"
                    >
                        <i *ngIf="!hasMobile" class="fa-regular fa-mobile text-lg mr-2"></i><i *ngIf="hasMobile" class="fa-regular fa-check text-green-500 text-lg mr-2"></i> <span>Mobile Device</span>
                    </a>
                </li>
                <li class="border-b border-b-slate-800">
                    <a (click)="addDevice('local')" class="cursor-pointer block px-4 py-4 hover:bg-slate-900/20 text-gray-300 hover:text-white">
                        <i class="fa-regular fa-up-right-from-square text-lg mr-2"></i> <span>Local Device</span>
                    </a>
                </li>
                <li class="">
                    <a (click)="addDevice('email')" class="cursor-pointer block px-4 py-4 hover:bg-slate-900/20 text-gray-300 hover:text-white">
                        <i class="fa-regular fa-envelope text-lg mr-2"></i><span>Other via Email</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
