<ng-container *ngIf="group">
    <div class="px-4 last:rounded-b-lg grid grid-cols-3 items-center">

        <div class="flex space-x-2 items-center">
            <span class="flex h-8 w-8 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[1.1rem] text-gray-400 group-hover:text-white"><i [attr.before]="group.name![0].toUpperCase()" class="before:content-[attr(before)]" ></i></span>
            <span class="flex-1">{{ group.id | credentialGroupName }}</span>
        </div>
        <div class="text-center text-xs text-slate-50">
            <span [ngClass]="{'text-green-500': permission.permission === 'write' }">{{ permission.permission }}</span>
        </div>
        <div class="text-right space-x-4">
            <i (click)="editPermission(permission, user)" class="fa-light fa-edit text-brand cursor-pointer" [attr.data-tooltip]="'Edit ' + group.name" [attr.data-tooltip-position]="'left'"></i>
            <i (click)="deletePermission(permission, user)" class="fa-light fa-trash text-red-600 cursor-pointer hover:fa-regularsolid-hover" [attr.data-tooltip]="'Delete ' + group.name" [attr.data-tooltip-position]="'left'"></i>
        </div>
    </div>
</ng-container>
