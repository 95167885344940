import { ActivatedRoute } from '@angular/router';

const getChildParams = (child: ActivatedRoute, inputParams = {}): Record<string, string> => {
    const params = child.children.reduce((p, r) => {
        const childData = getChildParams(r);
        return { ...p, ...r.snapshot.params, ...childData };
    }, {} as Record<string, string>);

    return { ...params, ...inputParams };
};

export const getMergedRouteParams = (route: ActivatedRoute) => {
    return getChildParams(route);
};
