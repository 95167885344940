import { Component, OnInit } from '@angular/core';
import { CredentialGroupsFacade } from '@passbot/angular/credentials';
import { UserFacade } from '@passbot/angular/auth';
import { UsersFacade } from '@passbot/angular/permissions';

@Component({
    selector: 'passbot-admin-component',
    template: '<router-outlet class="hidden"></router-outlet>',
    host: { class: 'h-full block' },
})
export class AdminComponent implements OnInit {
    constructor(
        private readonly credentialsFacade: CredentialGroupsFacade,
        private readonly userFacade: UserFacade,
        private readonly usersFacade: UsersFacade, // private readonly permissionsFacade: PermissionsFacade,
    ) {}

    public ngOnInit() {
        this.userFacade.getUser$.subscribe(async (user) => {
            if (user) {
                this.credentialsFacade.loadCredentials();
                this.usersFacade.loadUsers();
            }
        });
    }
}
