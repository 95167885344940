<div *ngIf="permissions$ | async as permissions">
    <div class="bg-slate-900 grid grid-cols-3 text-xs text-white/30 text-center px-4 py-0 max-h-0  transition-all overflow-hidden" [ngClass]="{'max-h-[50px] !py-4': isOpen && permissions.length > 0}">
        <div class="text-left">Credential Group</div>
        <div>Permission</div>
        <div class="text-right">Actions</div>
    </div>
    <div *ngFor="let permission of permissions; let i = index;"
         class="py-0 max-h-0 overflow-hidden transition-all"
         [ngClass]="{'bg-slate-900': (i%2), 'max-h-[65px] !py-4': isOpen}"
    >

        <ng-container *ngIf="permission.credentialGroup.id">
            <passbot-group-permission-row [permission]="permission" [user]="user" ></passbot-group-permission-row>
        </ng-container>
    </div>
</div>
