import { Component, Input } from '@angular/core';
import { Platform, Platforms } from '@passbot/shared';

@Component({
    selector: 'passbot-platform',
    templateUrl: './platform.component.html',
})
export class PlatformComponent {
    @Input() public platform: Platform;
    public readonly Platforms = Platforms;
}
