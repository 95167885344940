import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'passbotMinMax',
    pure: true,
})
export class MaxMinPipe implements PipeTransform {
    public transform(value: number, min: number, max: number) {
        if (value < min) {
            return min;
        }

        return Math.min(value, max);
    }
}
