import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PassbotCommonModule } from '@passbot/angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from '@passbot/angular/modal';
import { BillingModule } from '@passbot/angular/billing';
import { CredentialsModule } from '@passbot/angular/credentials';
import { permissionsRoutes } from './lib.routes';
import { PermissionsModule } from './permissions.module';
import { IndexComponent } from './components/index/index.component';
import { AddNewUserComponent } from './components/add-new-user/add-new-user.component';
import { EditPermissionModal } from './components/edit-permission/edit-permission.modal';
import { PromoteUserComponent } from './components/promote-user/promote-user.component';
import { GroupPermissionRowComponent } from './components/index/group-permission-row/group-permission-row.component';
import { UserPermissionsTableComponent } from './components/index/user-permissions-table/user-permissions-table.component';

@NgModule({
    imports: [
        CommonModule,
        PermissionsModule,
        RouterModule.forChild(permissionsRoutes),
        PassbotCommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        ModalModule,
        BillingModule,
        CredentialsModule,
    ],
    declarations: [
        IndexComponent,
        AddNewUserComponent,
        EditPermissionModal,
        PromoteUserComponent,
        GroupPermissionRowComponent,
        UserPermissionsTableComponent,
    ],
})
export class PermissionsModuleRouted {}
