import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { SocketConnectionService } from './socket-connection.service';

@Injectable()
export class SocketIoService {
    constructor(private readonly connection: SocketConnectionService, private readonly store: Store) {}

    public init() {
        this.connection.connect();

        this.connection.on('update-action', (action: Action) => {
            this.store.dispatch(action);
        });
    }

    public awaitResponse<T = unknown>(uuid: string) {
        const responseSubject = new Subject<T>();
        this.connection.on(`response-${uuid}`, (payload: T) => {
            responseSubject.next(payload);
            responseSubject.complete();
        });
        return responseSubject.asObservable();
    }

    public disconnect() {
        this.connection.disconnect();
    }
}
