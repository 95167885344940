export const StoreActions = {
    User: {
        loadUser: 'USER.LOAD_USER',
        loadUserSuccess: 'USER.LOAD_USER_SUCCESS',
        loadUserError: 'USER.LOAD_USER_ERROR',
        logout: 'USER.LOGOUT',
        logoutSuccess: 'USER.LOGOUT_SUCCESS',
        disconnectPlatform: 'USER.DISCONNECT_PLATFORM',
        disconnectPlatformSuccess: 'USER.DISCONNECT_PLATFORM_SUCCESS',
        authWithDevice: 'USER.AUTH_WITH_DEVICE',
        authWithDeviceSuccess: 'USER.AUTH_WITH_DEVICE_SUCCESS',
        authWithDeviceInProgress: 'USER.AUTH_WITH_DEVICE_IN_PROGRESS',
        authWithDeviceError: 'USER.AUTH_WITH_DEVICE_ERROR',
        authWithLocalDevice: 'USER.AUTH_WITH_LOCAL_DEVICE',
        needsDeviceAuth: 'USER.NEEDS_DEVICE_AUTH',
        authWithToken: 'USER.AUTH_WITH_TOKEN',
        authWithTokenSuccess: 'USER.AUTH_WITH_TOKEN_SUCCESS',
        authWithTokenError: 'USER.AUTH_WITH_TOKEN_ERROR',
    },

    Devices: {
        loadDevices: 'DEVICES.LOAD_DEVICES',
        loadDevicesSuccess: 'DEVICES.LOAD_DEVICES_SUCCESS',
        loadDevicesError: 'DEVICES.LOAD_DEVICES_ERROR',
        clearDevices: 'DEVICES.CLEAR_DEVICES',
        addDevice: 'DEVICES.ADD_DEVICE',
        addDeviceInProgress: 'DEVICES.ADD_DEVICE_IN_PROGRESS',
        addDeviceSuccess: 'DEVICES.ADD_DEVICE_SUCCESS',
        removeDevice: 'DEVICES.REMOVE_DEVICE',
        removeDeviceSuccess: 'DEVICES.REMOVE_DEVICE_SUCCESS',
        removeDeviceError: 'DEVICES.REMOVE_DEVICE_ERROR',
    },

    Credentials: {
        loadCredentials: 'CREDENTIALS.LOAD_CREDENTIALS',
        loadCredentialsSuccess: 'CREDENTIALS.LOAD_CREDENTIALS_SUCCESS',
        loadCredentialsError: 'CREDENTIALS.LOAD_CREDENTIALS_ERROR',
        createCredentialGroup: 'CREDENTIALS.CREATE_CREDENTIAL_GROUP',
        createCredentialGroupSuccess: 'CREDENTIALS.CREATE_CREDENTIAL_GROUP_SUCCESS',
        createCredentialGroupError: 'CREDENTIALS.CREATE_CREDENTIAL_GROUP_ERROR',
        createCredential: 'CREDENTIALS.CREATE_CREDENTIAL',
        createCredentialSuccess: 'CREDENTIALS.CREATE_CREDENTIAL_SUCCESS',
        createCredentialError: 'CREDENTIALS.CREATE_CREDENTIAL_ERROR',
        updateCredential: 'CREDENTIALS.UPDATE_CREDENTIAL',
        updateCredentialSuccess: 'CREDENTIALS.UPDATE_CREDENTIAL_SUCCESS',
        updateCredentialError: 'CREDENTIALS.UPDATE_CREDENTIAL_ERROR',
        deleteCredentialGroup: 'CREDENTIALS.DELETE_CREDENTIAL_GROUP',
        deleteCredentialGroupSuccess: 'CREDENTIALS.DELETE_CREDENTIAL_GROUP_SUCCESS',
        deleteCredentialGroupError: 'CREDENTIALS.DELETE_CREDENTIAL_GROUP_ERROR',
        deleteCredential: 'CREDENTIALS.DELETE_CREDENTIAL',
        deleteCredentialSuccess: 'CREDENTIALS.DELETE_CREDENTIAL_SUCCESS',
        deleteCredentialError: 'CREDENTIALS.DELETE_CREDENTIAL_ERROR',
        fetchCredentialGroup: 'CREDENTIALS.FETCH_CREDENTIAL_GROUP',
        fetchCredentialGroupSuccess: 'CREDENTIALS.FETCH_CREDENTIAL_GROUP_SUCCESS',
        fetchCredentialGroupError: 'CREDENTIALS.FETCH_CREDENTIAL_GROUP_ERROR',
        updateCredentialGroupPermissions: 'CREDENTIALS.UPDATE_GROUP_PERMISSIONS',
        updateCredentialGroupPermissionsSuccess: 'CREDENTIALS.UPDATE_GROUP_PERMISSIONS_SUCCESS',
        updateCredentialGroupPermissionsError: 'CREDENTIALS.UPDATE_GROUP_PERMISSIONS_ERROR',
        updateCredentialGroup: 'CREDENTIALS.UPDATE_GROUP',
        updateCredentialGroupSuccess: 'CREDENTIALS.UPDATE_GROUP_SUCCESS',
        updateCredentialGroupError: 'CREDENTIALS.UPDATE_GROUP_ERROR',
        setCredentialViewed: 'CREDENTIALS.SET_VIEWED',
    },

    Users: {
        loadUsers: 'USER.LOAD_USERS',
        loadUsersSuccess: 'USER.LOAD_USERS_SUCCESS',
        loadUsersError: 'USER.LOAD_USERS_ERROR',
        createUser: 'USER.CREATE_USER',
        createUserSuccess: 'USER.CREATE_USER_SUCCESS',
        createUserError: 'USER.CREATE_USER_ERROR',
        updateUser: 'USER.UPDATE_USER',
        updateUserSuccess: 'USER.UPDATE_USER_SUCCESS',
        updateUserError: 'USER.UPDATE_USER_ERROR',
        removeUser: 'USER.REMOVE_USER',
        removeUserSuccess: 'USER.REMOVE_USER_SUCCESS',
        removeUserError: 'USER.REMOVE_USER_ERROR',
    },

    Permissions: {
        loadPermissions: 'PERMISSIONS.LOAD_PERMISSIONS',
        loadPermissionsSuccess: 'PERMISSIONS.LOAD_PERMISSIONS_SUCCESS',
        loadPermissionsError: 'PERMISSIONS.LOAD_PERMISSIONS_ERROR',
        removePermission: 'PERMISSIONS.REMOVE_PERMISSION',
        removePermissionSuccess: 'PERMISSIONS.REMOVE_PERMISSION_SUCCESS',
        removePermissionError: 'PERMISSIONS.REMOVE_PERMISSION_ERROR',
        updatePermission: 'PERMISSIONS.UPDATE_PERMISSION',
        updatePermissionSuccess: 'PERMISSIONS.UPDATE_PERMISSION_SUCCESS',
        updatePermissionError: 'PERMISSIONS.UPDATE_PERMISSION_ERROR',
    },
};
