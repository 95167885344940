import { Webapps } from '../constants';

export abstract class AbstractHostService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected constructor(private readonly configService: any) {}

    protected getConfig<T = unknown>(key: string, defaultValue?: T): T | undefined {
        // Check to make sure we have a getter function on the provided ConfigService that we can use.
        if (this.configService.get && typeof this.configService.get === 'function') {
            return this.configService.get(key) || defaultValue;
        }

        throw new Error('The ConfigService passed to AbstractHostService does not support getting values');
    }

    public isDevelopment(): boolean {
        return !this.getConfig<boolean>('production', false);
    }

    public getDefaultSubdomain() {
        const defaultValue = this.isDevelopment() ? '' : 'www.';

        return this.getConfig<string>('DEFAULT_SUBDOMAIN', defaultValue)!;
    }

    public getBaseDomain() {
        const defaultValue = this.isDevelopment() ? 'dev.passbot.io' : 'passbot.io';

        return this.getConfig<string>('BASE_DOMAIN', defaultValue)!;
    }

    public getApplicationUrl(webapp?: Webapps, tenantName?: string, baseDomain?: string) {
        const host = this.getBaseHost(tenantName, baseDomain);
        const path = webapp ? `/${webapp}/` : '/';

        return new URL(`https://${host}${path}`);
    }

    public getBaseHost(tenantName?: string, baseDomain?: string) {
        const defaultSubdomain = this.getDefaultSubdomain();
        const domain = baseDomain || this.getBaseDomain();

        if (tenantName) {
            return `${tenantName}.${domain}`;
        }

        return `${defaultSubdomain}${domain}`;
    }

    public getTenantUrl(tenantName: string, webapp?: Webapps, baseDomain?: string) {
        return this.getApplicationUrl(webapp, tenantName, baseDomain);
    }
}
