import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '@passbot/angular/api';
import { IUser } from '@passbot/shared';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import { PublicKeyCredentialCreationOptionsJSON, PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types';

@Injectable()
export class WebauthService {
    private user: IUser;
    constructor(private readonly activatedRoute: ActivatedRoute, private readonly api: APIService) {}

    public async handleToken(
        isRegistration = false,
        type?: string,
        deviceName?: string,
        target?: string,
    ): Promise<{ error?: string; ok?: boolean; expiration?: number }> {
        try {
            const jwt = this.activatedRoute.snapshot.queryParams.token;

            if (!jwt) {
                throw new Error('Invalid or missing token');
            }

            try {
                await this.api.getAsync(`/auth/token/${jwt}`);
                this.user = await this.api.getAsync('/auth/session');
            } catch (e: any) {
                throw new Error('invalid token');
            }

            if (isRegistration) {
                return await this.registerDevice(this.user.name, type, deviceName, target);
            }

            return await this.authenticateDevice();
        } catch (e: any) {
            await this.api.postAsync('/auth/webauthn/authentication-failed', {});
            return { error: e.message };
        }
    }

    public async registerDevice(userName: string, type?: string, deviceName?: string, target?: string) {
        return this.handleRegistration(userName, type, deviceName, target);
    }

    public async authenticateDevice() {
        return this.authenticate();
    }

    private async handleRegistration(userName: string, type?: string, deviceName?: string, target?: string) {
        try {
            const registration = await this.api.getAsync<PublicKeyCredentialCreationOptionsJSON>('/auth/webauthn/registration-options');
            const response = await startRegistration(registration);
            await this.api.postAsync('/auth/webauthn/register', { credential: response, type, deviceName, target });
            return { ok: true };
        } catch (e: any) {
            console.error(e);
            return { error: e.message, ok: false };
        }
    }

    private async authenticate() {
        // get user devices
        const authentication = await this.api.getAsync<PublicKeyCredentialRequestOptionsJSON>('/auth/webauthn/authentication-options');
        let response;
        try {
            response = await startAuthentication(authentication);
        } catch (e) {
            throw new Error('Authentication failed or cancelled');
        }

        const verification = await this.api.postAsync<{ verified: boolean; expiration: number }>('/auth/webauthn/authenticate', {
            credential: response,
        });

        if (verification.verified) {
            return { expiration: verification.expiration, ok: true };
        }

        throw new Error('Authentication failed or cancelled');
    }
}
