import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'passbot-not-found',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent {}
