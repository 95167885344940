import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map, of } from 'rxjs';

import { APIService } from '@passbot/angular/api';
import { ICredential } from '@passbot/shared';

import {
    createCredential,
    createCredentialError,
    createCredentialSuccess,
    deleteCredential,
    deleteCredentialError,
    deleteCredentialSuccess,
    updateCredential,
    updateCredentialError,
    updateCredentialSuccess,
} from './credentials.actions';

@Injectable()
export class CredentialsEffects {
    public createCredential$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createCredential),
            fetch({
                run: (props) =>
                    this.apiService.post<ICredential>('/credential', props).pipe(map((credential) => createCredentialSuccess({ credential }))),
                onError: (err) => of(createCredentialError({ msg: 'Failed to create credential' })),
            }),
        ),
    );

    public updateCredential$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCredential),
            fetch({
                run: (props) =>
                    this.apiService.put<ICredential>(`/credential/${props.id}`, props).pipe(
                        // tap(() => {
                        //     setTimeout(() => this.store.dispatch(loadCredentials()), 200);
                        // }),
                        map((credential) => updateCredentialSuccess({ credential })),
                    ),
                onError: (err) => of(updateCredentialError({ msg: 'Failed to update credential' })),
            }),
        ),
    );

    public deleteCredential$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteCredential),
            fetch({
                run: (props) =>
                    this.apiService
                        .delete<{ id: string; groupIds: number[] }>(`/credential/${props.credential.id}/${props.groupId}`, { cascade: props.cascade })
                        .pipe(
                            map((response) => deleteCredentialSuccess({ id: props.credential.id, groupId: props.groupId, cascade: !!props.cascade })),
                        ),
                onError: (err) => of(deleteCredentialError({ msg: 'Failed to delete credential' })),
            }),
        ),
    );

    constructor(private readonly actions$: Actions, private readonly apiService: APIService) {}
}
