import { Component, OnInit } from '@angular/core';
import { APIService } from '@passbot/angular/api';
import { Download, PaymentSource, Subscription, Transaction } from 'chargebee';
import { ModalService } from '@passbot/angular/modal';
import { firstValueFrom } from 'rxjs';
import { IBillingSeats } from '@passbot/shared';
import { AdjustSeatsModalComponent } from '../adjust-seats-modal/adjust-seats-modal.component';

@Component({
    selector: 'passbot-billing-index',
    templateUrl: './index.component.html',
})
export class IndexComponent implements OnInit {
    public seats: IBillingSeats;
    public activePercent: number;
    public tempPercent: number;
    public subscription: Subscription;
    public circumference = 50 * 2 * Math.PI;
    public priceBreakdownVisible = false;
    public transactions: { transaction: Transaction }[];
    public paymentSources: { payment_source: PaymentSource }[];

    constructor(private readonly api: APIService, private readonly modalService: ModalService) {}

    public async ngOnInit() {
        this.seats = await this.api.getAsync<IBillingSeats>('/billing/seats');
        this.activePercent = Math.min((this.seats.active / this.seats.seats) * 100, 100);
        this.tempPercent = Math.min((this.seats.temp / this.seats.seats) * 100, 100);
        this.subscription = await this.api.getAsync<Subscription>('/billing/subscription');
        const paymentInfo = await this.api.getAsync<{
            transactions: { transaction: Transaction }[];
            paymentSources: { payment_source: PaymentSource }[];
        }>('/billing/payment-details');
        this.transactions = paymentInfo.transactions;
        this.paymentSources = paymentInfo.paymentSources;
    }

    public async editSeats() {
        const updated = await firstValueFrom(this.modalService.show(AdjustSeatsModalComponent));
        if (updated) {
            void this.ngOnInit();
        }
    }

    public async updatePaymentMethods() {
        const portalInfo = await this.api.getAsync<{ portalSession: any; subscriptionId: string }>('/billing/portal');
        const chargebee = (window as any).Chargebee.getInstance();
        await chargebee.setPortalSession(portalInfo.portalSession);
        const portal = chargebee.createChargebeePortal();

        portal.openSection(
            {
                sectionType: (window as any).Chargebee.getPortalSections().PAYMENT_SOURCES,
                params: { subscriptionId: portalInfo.subscriptionId },
            },
            { close: this.ngOnInit },
        );
    }

    public async downloadInvoice(invoiceId: string) {
        const download = await this.api.getAsync<Download>(`/billing/invoice/${invoiceId}`);
        window.open(download.download_url);
    }
}
