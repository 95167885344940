import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService } from '@passbot/angular/api';
import { CredentialGroupsFacade } from '@passbot/angular/credentials';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import {
    IBillingSeats,
    ICredentialGroup,
    IPlatformUser,
    ITenantWorkspace,
    IUser,
    IUserPermission,
    IWorkspaceUser,
    Permissions,
    UserStatus,
} from '@passbot/shared';

import { UsersFacade } from '../../+state/users';

@Component({
    selector: 'passbot-add-new-user',
    templateUrl: './add-new-user.component.html',
})
export class AddNewUserComponent implements OnDestroy, OnInit {
    public platformUsers$: Observable<IPlatformUser[]>;
    public isProcessing$ = this.permissionsFacade.isProcessing$;
    public editing = false;
    public selectedWorkspace: ITenantWorkspace;
    public userDisableDate: Date | undefined;
    public UserStatus = UserStatus;
    public seatsInfo: IBillingSeats;
    public canGoActive = true;
    public goPending = false;
    public user = {} as IUser;
    public userForm = new FormGroup(
        {
            workspaceUser: new FormControl<IPlatformUser | undefined>(undefined, [Validators.required]),
            groupPerms: new FormArray<FormGroup<{ credentialGroupId: FormControl; permission: FormControl; id: FormControl }>>([
                this.getNewGroupForm(),
            ]),
            tenantAdmin: new FormControl(false),
            billingAdmin: new FormControl(false),
            status: new FormControl(UserStatus.active),
            active: new FormControl(true),
        },
        [this.validateGroups.bind(this)],
    );

    public items: ICredentialGroup[][] = [];
    private workspaces: ITenantWorkspace[];
    private readonly destroyed$ = new Subject<void>();

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) private readonly data: { user?: IUser },
        private readonly api: APIService,
        private readonly permissionsFacade: UsersFacade,
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
    ) {
        if (!this.data) {
            this.data = {};
        }
    }

    public async ngOnInit() {
        this.seatsInfo = await this.api.getAsync<IBillingSeats>('/billing/seats');
        // let's pretend we are supporting multiple workspaces for now, gets me the data needed anyways
        this.workspaces = await this.api.getAsync<ITenantWorkspace[]>('/tenant-workspace');

        this.goPending = this.seatsInfo && this.seatsInfo.pending ? this.seatsInfo.active >= this.seatsInfo.pending : false;

        this.canGoActive = this.seatsInfo ? this.seatsInfo.seats > this.seatsInfo.inUse : true;

        if (this.data && this.data.user && this.data.user.workspaceUsers) {
            this.initEditUser();
        } else {
            this.selectedWorkspace = this.workspaces[0];
            this.userDisableDate =
                this.seatsInfo.pending && this.seatsInfo.inUse + 1 > this.seatsInfo.pending ? this.seatsInfo.pendingDate : undefined;
        }

        this.platformUsers$ = this.api.get<IPlatformUser[]>('/user/platform', { platformId: this.selectedWorkspace.platformId }).pipe(
            withLatestFrom(this.permissionsFacade.users$),
            map(([platformUsers, addedUsers]: [IPlatformUser[], IUser[]]) => {
                const addedUserIds = addedUsers
                    .filter((u) => !this.data || !this.data.user || this.data.user.id !== u.id)
                    .map((u) => (u.workspaceUsers![0] as IWorkspaceUser).platformUserId);
                return platformUsers.filter((u) => !addedUserIds.includes(u.platformUserId));
            }),
        );

        if (!this.canGoActive) {
            this.userForm.controls.active.disable({ emitEvent: false });
        }

        await this.setGroupItems();

        this.userForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(this.checkFormUpdates.bind(this));
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public trackByFn(user: IPlatformUser) {
        return user.platformUserId;
    }

    public trackByIdFn(group: ICredentialGroup) {
        return group.id;
    }

    public close() {
        this.modal.close();
    }

    public async saveUser() {
        if (!this.userForm.valid) {
            return;
        }

        const formValue = this.userForm.value;

        // convert form to a user!
        const user = {
            workspaceUsers: [
                {
                    platformUserId: formValue.workspaceUser!.platformUserId,
                    platform: this.selectedWorkspace.platform,
                    tenantWorkspaceId: this.selectedWorkspace.id,
                    id: this.data.user?.workspaceUsers![0].id,
                } as IWorkspaceUser,
            ],
            name: formValue.workspaceUser!.name as string,
            avatarUrl: formValue.workspaceUser!.avatarUrl as string,
            tenantAdmin: !!formValue.tenantAdmin,
            billingAdmin: !!formValue.billingAdmin,
            permissions: (formValue.tenantAdmin
                ? []
                : [
                      ...(formValue.groupPerms || [])
                          .filter((perm) => perm.credentialGroupId)
                          .map((perm) => ({
                              credentialGroup: { id: perm.credentialGroupId },
                              permission: perm.permission,
                          })),
                  ]) as IUserPermission[],
            id: this.data.user ? this.data.user.id : undefined,
            status: formValue.status,
        } as IUser;

        if (!this.editing) {
            await this.permissionsFacade.createUserAsync(user);
        } else {
            await this.permissionsFacade.updateUserAsync(user);
        }

        this.modal.close(user);
    }

    public async checkGroups(index: number) {
        // has been unset?
        const control = this.userForm.controls.groupPerms.at(index);
        if (!control.value.credentialGroupId && (index !== 0 || this.userForm.controls.groupPerms.length > 1)) {
            this.userForm.controls.groupPerms.removeAt(index);
        }

        await this.setGroupItems();

        const lastControl = (this.userForm.controls.groupPerms as FormArray).at(this.userForm.controls.groupPerms.length - 1);
        if (lastControl.value.credentialGroupId && this.items[this.items.length - 1].length > 0) {
            this.userForm.controls.groupPerms.push(this.getNewGroupForm());
        }
    }

    private async setGroupItems() {
        const allGroups = [...(await firstValueFrom(this.credentialGroupsFacade.getAll$))];
        this.items = [];

        // get all selected
        const selected = this.userForm.controls.groupPerms.value.map((perm) => perm.credentialGroupId);

        for (let i = 0; i <= this.userForm.controls.groupPerms.length; i++) {
            const formGroup = this.userForm.controls.groupPerms.at(i);
            const myId = formGroup?.controls.credentialGroupId.value;
            this.items[i] = [...allGroups.filter((g) => g.id === myId || !selected.includes(g.id))];

            if (formGroup) {
                const selectedIndex = allGroups.findIndex((g) => g.id === formGroup.controls.credentialGroupId?.value);
                if (selectedIndex >= 0) {
                    allGroups.splice(selectedIndex, 1);
                }
            }
        }
    }

    private getNewGroupForm() {
        return new FormGroup({
            credentialGroupId: new FormControl(''),
            permission: new FormControl(Permissions.Read),
            id: new FormControl(''),
        });
    }

    private validateGroups(control: AbstractControl) {
        if (!this.userForm || this.userForm.value.tenantAdmin || this.userForm.value.billingAdmin) {
            return null;
        }

        // need at least one
        if (!this.userForm.controls.groupPerms.value[0].credentialGroupId) {
            return { needsGroup: true };
        }

        return null;
    }

    private initEditUser() {
        this.editing = true;

        if (this.data.user!.status !== UserStatus.disabled) {
            this.canGoActive = true;
            this.goPending = this.data.user!.status === UserStatus.temp;
        }

        // get the first one for now @todo prob need to look at this when users can be on multiple workspaces, but feels a long way off
        const workspaceUser = this.data.user!.workspaceUsers![0] as IWorkspaceUser;
        this.selectedWorkspace = this.workspaces.find((w) => w.id === workspaceUser.tenantWorkspaceId) as ITenantWorkspace;

        for (let i = 1; i < (this.data.user!.permissions || []).length; i++) {
            this.userForm.controls.groupPerms.push(this.getNewGroupForm());
        }

        this.userForm.patchValue({
            workspaceUser: { platformUserId: workspaceUser.platformUserId, name: this.data.user!.name, avatarUrl: this.data.user!.avatarUrl },
            groupPerms: ((this.data.user!.permissions || []) as IUserPermission[]).map((perm) => ({
                credentialGroupId: perm.credentialGroup!.id,
                permission: perm.permission,
                id: perm.id,
            })),
            tenantAdmin: this.data.user!.tenantAdmin,
            billingAdmin: this.data.user!.billingAdmin,
            status: this.data.user!.status,
            active: this.data.user!.status !== UserStatus.disabled,
        });

        void this.checkGroups(((this.data.user!.permissions || []).length || 1) - 1);
    }

    private checkFormUpdates(changes: Partial<typeof this.userForm.getRawValue>) {
        if ('active' in changes) {
            if (changes.active === false) {
                this.userForm.controls.tenantAdmin.disable({ emitEvent: false });
                this.userForm.controls.billingAdmin.disable({ emitEvent: false });
                this.userForm.controls.status.setValue(UserStatus.disabled, { emitEvent: false });
            } else {
                this.userForm.controls.tenantAdmin.enable({ emitEvent: false });
                this.userForm.controls.billingAdmin.enable({ emitEvent: false });
                this.userForm.controls.status.setValue(this.goPending ? UserStatus.temp : UserStatus.active, { emitEvent: false });
            }
        }
    }
}
