<div class="h-full p-8 flex flex-col overflow-hidden">
    <div class="md:flex ">
        <div class="flex-1 flex space-x-4">
            <div class="flex flex-col justify-center">
                <i class="fa-light fa-user-group text-brand text-5xl"></i>
            </div>
            <div class="flex flex-col justify-center">
                <h2 class="text-brand text-2xl font-semibold">Users</h2>
                <h4 class="text-white/70">Manage your users and permissions</h4>
            </div>
        </div>
        <div class="items-center hidden md:flex">
            <a (click)="addUser()" class="bg-brand block cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg"><i class="fa-light fa-plus"></i> Add New User</a>
        </div>

    </div>



    <div class="flex flex-1 pt-10 overflow-y-auto scrollbar-thumb-border-l-8 pr-2 pl-1 scrollbar-thin scrollbar-thumb-slate-700 scrollbar-thumb-rounded-full scrollbar scrollbar-track-transparent ">
        <ng-container *ngIf="users$ | async as users">
            <div class="w-full space-y-2 lg:space-y-4" *ngIf="users.length > 0">
                <div [ngClass]="{'border-gray-700': user.status < 3, 'border-red-900': user.status === 3, 'animate-highlight': highlight === user.id }" class="rounded-lg bg-slate-900/60 border text-white" *ngFor="let user of users">
                    <div class="grid grid-cols-2 md:grid-cols-3 align-middle p-4" (click)="toggleOpen(user)">
                        <div class="font-semibold flex items-center" [class.opacity-50]="user.status === 3">
                            <img [src]="user.avatarUrl" class="bg-logo rounded-full inline-block mr-2 w-10 h-10 bg-s"/>
                            <div class="flex flex-col justify-center">
                                {{ user.name }}
                                <ng-container *ngIf="user.tenantAdmin">
                                    <span class="text-brand text-xs font-normal">Account Admin</span>
                                </ng-container>
                                <ng-container *ngIf="!user.tenantAdmin && user.billingAdmin">
                                    <span class="text-brand text-xs font-normal">Billing Admin</span>
                                </ng-container>
                            </div>


                        </div>
                        <div class="text-center text-xs text-gray-500 items-center justify-center hidden md:flex">
                            <ng-container *ngIf="user.tenantAdmin && user.status < 3">
                                Inherited access to all groups
                            </ng-container>
                            <ng-container *ngIf="!user.tenantAdmin && user.status < 3">
                                <span class="text-brand">Member of {{ user.permissions!.length }} groups</span>
                            </ng-container>
                            <ng-container *ngIf="user.status === 3">
                                <span class="text-red-500">ACCOUNT DISABLED</span>
                            </ng-container>
                        </div>
                        <div class="text-right space-x-4 flex items-center justify-end">
                            <i *ngIf="user.status === 2" class="fa-light fa-info-circle text-yellow-500 cursor-pointer" data-tooltip="This user will currently get disabled at the next billing date" [attr.data-tooltip-position]="'left'"></i>
                            <i *ngIf="user.id !== loggedInUser?.id" (click)="editUser(user, $event)" class="fa-light fa-edit text-brand cursor-pointer hover-solid" [attr.data-tooltip]="'Edit ' + user.name" [attr.data-tooltip-position]="'left'"></i>
                            <i *ngIf="user.id !== loggedInUser?.id" (click)="deleteUser(user, $event)" [attr.data-tooltip]="'delete ' + user.name" [attr.data-tooltip-position]="'left'" class="fa-light fa-trash text-red-500 hover-solid cursor-pointer"></i>
                            <i *ngIf="!user.tenantAdmin" class="cursor-pointer" [ngClass]="{ 'fa-light': user.permissions!.length > 0, 'fa-chevron-up': isOpen[user.id] && user.permissions!.length > 0, 'fa-chevron-down': !isOpen[user.id] && user.permissions!.length > 0, 'fa-dash fa-regular text-slate-700': user.permissions!.length === 0 }"></i>
                        </div>
                    </div>
                    <passbot-user-permissions-table [user]="user" [isOpen]="isOpen[user.id]"></passbot-user-permissions-table>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="text-center block md:hidden">
        <a (click)="addUser()" class="bg-brand block cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg"><i class="fa-light fa-plus"></i> Add New User</a>
    </div>

</div>
