<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="(isProcessing$ | async) === false" [formGroup]="deleteCredentialForm">
    <h3 class="text-center font-semibold text-lg text-brand">Delete Credential</h3>
    <p>Are you sure you want to delete this credential?</p>
    <p *ngIf="otherCredentialGroups.length > 0">
        This credential is also included in the other groups: <span [innerHTML]="otherCredentialGroups | passbotPrettyList: 'name'"></span>
    </p>
    <div *ngIf="otherCredentialGroups.length > 0" class="flex justify-between items-center text-sm">
        <div>Delete from all groups</div>
        <div>
            <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" formControlName="deleteAll">
                <div class="w-11 h-6 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-brand rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-brand"></div>
            </label>
        </div>
    </div>

    <div *ngIf="credential.totpKey && (otherCredentialGroups.length < 1 || deleteCredentialForm.controls.deleteAll.value)" class="border border-red-600 rounded p-4 space-y-2 text-red-500 text-sm">
        <div>
            <span class="font-bold">PLEASE NOTE:</span><br/>
            As this credential has 2FA enabled and we hold the authenticator code, please ensure you have off-boarded this credential and are happy to delete it from our records. 
            This deletetion is non-reversable.<br/>
            Check here to confirm that we are no longer the 2FA authentication method for this service.
        </div>
        <input
                formControlName="confirm2FA"
                type="text"
                [ngClass]="{'!ring-red-500': deleteCredentialForm.invalid && deleteCredentialForm.touched}"
                [placeholder]="credential.name"
                class="block text-sm min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
    </div>
    <div class="flex justify-between mt-8">
        <div (click)="close()" class="cursor-pointer inline-flex justify-center bg-slate-200 cursor-pointer hover:bg-slate-100 text-slate-800 py-2 px-4 border-b-4 border-white rounded-lg">
            Cancel
        </div>
        <button
                [disabled]="deleteCredentialForm.invalid"
                (click)="deleteFromGroup()"
                class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
            Delete {{ credential.name }}
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>

