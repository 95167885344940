import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Permissions } from '@passbot/shared';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'passbot-group-access-selector',
    templateUrl: './group-access-selector.component.html',
})
export class GroupAccessSelectorComponent {
    @Input() public userPermissionFormGroup: FormGroup;
    @Input() public userPermission: Permissions;
    @Output() public userPermissionChange = new EventEmitter<Permissions>();

    public permissionStr = '';
    public wasInside = false;
    public isVisible = false;
    protected readonly Permissions = Permissions;

    @HostListener('click')
    public clickInside() {
        this.wasInside = true;
    }

    @HostListener('document:click')
    public clickOutside() {
        if (!this.wasInside) {
            this.isVisible = false;
        }
        this.wasInside = false;
    }

    public ngOnInit() {
        if (this.userPermissionFormGroup && !this.userPermission) {
            this.userPermission = this.userPermissionFormGroup.controls.permission!.value;
        }
        this.setPermissionStr();
    }

    public toggleVisible() {
        this.isVisible = !this.isVisible;
    }

    public selectPermission(perm: Permissions) {
        this.userPermission = perm;
        this.userPermissionChange.emit(this.userPermission);
        this.setPermissionStr();
        this.isVisible = false;
        if (this.userPermissionFormGroup) {
            this.userPermissionFormGroup.controls.permission!.setValue(this.userPermission, { emit: true });
            this.userPermissionFormGroup.markAsTouched({ onlySelf: false });
            this.userPermissionFormGroup.markAsDirty({ onlySelf: false });
        }
    }

    private setPermissionStr() {
        switch (this.userPermission) {
            case Permissions.Admin:
                this.permissionStr = 'Group Admin';
                break;
            case Permissions.Write:
                this.permissionStr = 'Read &  Write';
                break;
            case Permissions.Read:
            default:
                this.permissionStr = 'Read Only';
        }
    }
}
