import { createAction, props } from '@ngrx/store';
import { ICredentialGroup, IUserPermission, PartialWithId, StoreActions } from '@passbot/shared';

export const loadCredentials = createAction(StoreActions.Credentials.loadCredentials);
export const loadCredentialsSuccess = createAction(StoreActions.Credentials.loadCredentialsSuccess, props<{ items: ICredentialGroup[] }>());
export const loadCredentialsError = createAction(StoreActions.Credentials.loadCredentialsError, props<{ msg: string }>());

export const createCredentialGroup = createAction(StoreActions.Credentials.createCredentialGroup, props<Partial<ICredentialGroup>>());
export const createCredentialGroupSuccess = createAction(StoreActions.Credentials.createCredentialGroupSuccess, props<ICredentialGroup>());
export const createCredentialGroupError = createAction(StoreActions.Credentials.createCredentialGroupError, props<{ msg: string }>());

export const fetchCredentialGroup = createAction(StoreActions.Credentials.fetchCredentialGroup, props<{ id: string }>());
export const fetchCredentialGroupSuccess = createAction(StoreActions.Credentials.fetchCredentialGroupSuccess, props<ICredentialGroup>());
export const fetchCredentialGroupError = createAction(StoreActions.Credentials.fetchCredentialGroupError, props<{ msg: string }>());

export const updateCredentialGroupPermissions = createAction(
    StoreActions.Credentials.updateCredentialGroupPermissions,
    props<{ id: string; permissions: IUserPermission[] }>(),
);
export const updateCredentialGroupPermissionsSuccess = createAction(
    StoreActions.Credentials.updateCredentialGroupPermissionsSuccess,
    props<{ id: string; permissions: IUserPermission[] }>(),
);
export const updateCredentialGroupPermissionsError = createAction(
    StoreActions.Credentials.updateCredentialGroupPermissionsError,
    props<{ msg: string }>(),
);

export const deleteCredentialGroup = createAction(
    StoreActions.Credentials.deleteCredentialGroup,
    props<{ credentialGroupId: string; cascadeDelete: boolean }>(),
);
export const deleteCredentialGroupSuccess = createAction(
    StoreActions.Credentials.deleteCredentialGroupSuccess,
    props<{ credentialGroupId: string }>(),
);
export const deleteCredentialGroupError = createAction(StoreActions.Credentials.deleteCredentialGroupError, props<{ msg: string }>());

export const updateCredentialGroup = createAction(
    StoreActions.Credentials.updateCredentialGroup,
    props<{ credentialGroup: PartialWithId<ICredentialGroup> }>(),
);

export const updateCredentialGroupSuccess = createAction(
    StoreActions.Credentials.updateCredentialGroupSuccess,
    props<{ credentialGroup: PartialWithId<ICredentialGroup> }>(),
);

export const updateCredentialGroupError = createAction(StoreActions.Credentials.updateCredentialGroupError, props<{ msg: string }>());
