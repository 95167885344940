import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getUserLocation } from '@passbot/shared';
import { ChangeData } from 'ngx-intl-tel-input';
import { allCountries, ICountryPhoneInput } from './data';
import { phoneNumberValidator } from './phone-input.validator';

@Component({
    selector: 'passbot-phone-input',
    templateUrl: './phone-input.component.html',
    animations: [
        trigger('dropdownAnimation', [
            state('hidden', style({ opacity: 0, height: 0, 'pointer-events': 'none' })),
            state('visible', style({ opacity: 1, height: '*', 'pointer-events': 'auto' })),
            transition('hidden <=> visible', animate('200ms ease-in-out')),
        ]),
    ],
})
export class PhoneInputComponent {
    @Input() public control: FormControl;
    @ViewChild('dropdown', { static: false }) public dropdown: ElementRef;
    @ViewChild('dropdownContent', { static: false }) public dropdownContent: ElementRef;
    @ViewChild('numberInput', { static: false }) public numberInput: ElementRef;

    public open = false;
    public selectedValue: ICountryPhoneInput;
    public query = '';
    public countries = allCountries;
    public selectedCountry: ICountryPhoneInput;
    public phoneUtil = PhoneNumberUtil.getInstance();
    public placeholder = '';
    public phoneNumber: string;

    // private readonly numberFormat: PhoneNumberFormat = PhoneNumberFormat.INTERNATIONAL;

    public async ngOnInit() {
        this.selectedValue = this.control.value;

        this.control.addValidators([phoneNumberValidator, Validators.required]);

        await this.initValue();
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement) {
        if (!this.dropdownContent.nativeElement.contains(targetElement) && !this.dropdown.nativeElement.contains(targetElement)) {
            this.closeDropdown();
        }
    }

    public toggleDropdown() {
        this.open = !this.open;
    }

    public closeDropdown() {
        this.open = false;
        this.query = '';
    }

    public selectCountry(value: ICountryPhoneInput) {
        this.selectedCountry = value;
        this.placeholder = this.getPhoneNumberPlaceHolder(value.iso.toUpperCase());
        this.closeDropdown();
        this.numberInput.nativeElement.focus();

        // if (this.control.value && this.control.value.length > 0) {
        //     const number = this.getParsedNumber(this.control.value, this.selectedCountry.iso);
        //     const intlNo = number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : '';
        // }
    }

    // eslint-disable-next-line complexity
    public onPhoneNumberChange(): void {
        let countryCode: string | undefined;
        // Handle the case where the user sets the value programatically based on a persisted ChangeData obj.
        if (this.phoneNumber && typeof this.phoneNumber === 'object') {
            const numberObj: ChangeData = this.phoneNumber;
            this.phoneNumber = numberObj.number as string;
            // eslint-disable-next-line prefer-destructuring
            countryCode = numberObj.countryCode;
        }

        countryCode = countryCode || this.selectedCountry.iso;
        const number = this.getParsedNumber(this.phoneNumber, countryCode) as PhoneNumber;
        this.phoneNumber = this.phoneUtil.formatInOriginalFormat(number, countryCode);

        if (!this.phoneUtil.isValidNumber(number)) {
            this.control.setErrors({ phoneNumberInvalid: true });
            return;
        }

        this.control.setErrors(null);

        // auto select country based on the extension (and areaCode if needed) (e.g select Canada if number starts with +1 416)
        countryCode =
            number && number.getCountryCode() ? this.getCountryIsoCode(number.getCountryCode() as number, number) : this.selectedCountry.iso;

        if (countryCode && countryCode !== this.selectedCountry.iso) {
            const newCountry = this.countries
                .sort((a, b) => {
                    return a.priority - b.priority;
                })
                .find((c) => c.iso === countryCode);

            if (newCountry) {
                this.selectedCountry = newCountry;
            }
        }

        const intlNo = number ? this.phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL) : '';
        this.control.setValue(intlNo);
    }

    public filterCountries() {
        return this.countries.filter((country) => country.title.toLowerCase().startsWith(this.query.toLowerCase()));
    }

    private async initValue() {
        const location = await getUserLocation();
        const ipValue = this.countries.find((country) => country.iso.toUpperCase() === location);
        if (ipValue) {
            this.selectCountry(ipValue);
        }

        if (this.control.value) {
            const number = this.getParsedNumber(this.control.value, this.selectedCountry.iso) as PhoneNumber;
            this.phoneNumber = this.phoneUtil.formatInOriginalFormat(number, this.selectedCountry.iso);
        }
    }

    private getPhoneNumberPlaceHolder(countryCode: string): string {
        try {
            return this.phoneUtil.format(this.phoneUtil.getExampleNumber(countryCode), PhoneNumberFormat.NATIONAL);
        } catch (e) {
            return '';
        }
    }

    private getParsedNumber(phoneNumber: string, countryCode: string): PhoneNumber {
        let number: PhoneNumber;
        try {
            number = this.phoneUtil.parse(phoneNumber, countryCode.toUpperCase());
            return number;
        } catch (e) {
            return undefined as unknown as PhoneNumber;
        }
    }

    private getCountryIsoCode(countryCode: number, number: PhoneNumber): string | undefined {
        const rawNumber = (number as any).values_['2'].toString();

        // List of all countries with countryCode (can be more than one. e.x. US, CA, DO, PR all have +1 countryCode)
        const countries = this.countries.filter((c) => c.dialCode === countryCode.toString());

        // Main country is the country, which has no areaCodes specified in country-code.ts file.
        const mainCountry = countries.find((c) => c.areaCodes.length === 0);
        // Secondary countries are all countries, which have areaCodes specified in country-code.ts file.
        const secondaryCountries = countries.filter((c) => c.areaCodes.length > 0);
        let matchedCountry = mainCountry ? mainCountry.iso : undefined;

        /*
            Iterate over each secondary country and check if nationalNumber starts with any of areaCodes available.
            If no matches found, fallback to the main country.
        */
        secondaryCountries.forEach((country) => {
            country.areaCodes.forEach((areaCode) => {
                if (rawNumber.startsWith(areaCode)) {
                    matchedCountry = country.iso;
                }
            });
        });

        return matchedCountry;
    }
}
