// eslint-disable-next-line max-classes-per-file
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PassbotCommonModule } from '@passbot/angular/common';
import { DevicesStoreModule } from '@passbot/angular/devices';
import { WebauthnModule } from '@passbot/angular/webauthn';
import { authRoutes } from './lib.routes';
import { LoginComponent } from './components/login/login.component';
import * as fromUser from './+state/user.reducer';
import { UserEffects } from './+state/user.effects';
import { UserFacade } from './+state/user.facade';
import { AuthService } from './services/auth.service';
import { DeviceAuthComponent } from './components/device-auth/device-auth.component';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromUser.USER_FEATURE_KEY, fromUser.UserReducers),
        EffectsModule.forFeature([UserEffects]),
        DevicesStoreModule,
        WebauthnModule,
    ],
    providers: [UserFacade, AuthService],
})
export class AuthModuleBase {}

@NgModule({
    imports: [AuthModuleBase, CommonModule, RouterModule.forChild(authRoutes), PassbotCommonModule],
    declarations: [LoginComponent, DeviceAuthComponent],
})
export class AuthModule {}
