/*
We use "control: any" instead of "control: FormControl" to silence:
"Property 'nativeElement' does not exist on type 'FormControl'".
This happens because I've expanded control with nativeElement via
'NativeElementInjectorDirective' to get an access to the element.
More about this approach and reasons for this:
https://github.com/angular/angular/issues/18025
https://stackoverflow.com/a/54075119/1617590
*/
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';

// eslint-disable-next-line complexity
export const phoneNumberValidator = (control: any): Record<string, any> | null => {
    if (!control.value) {
        return null;
    }
    // Find <input> inside injected nativeElement and get its "id".
    const el: HTMLElement = control.nativeElement as HTMLElement;
    const inputBox: HTMLInputElement | any = el ? el.querySelector('input[type="tel"]') : undefined;
    if (inputBox) {
        const isCheckValidation = inputBox.getAttribute('validation');
        if (isCheckValidation === 'true') {
            const isRequired = control.errors && control.errors.required === true;
            const error = { validatePhoneNumber: { valid: false } };

            inputBox.setCustomValidity('Invalid field.');

            let number: PhoneNumber | undefined = undefined;

            try {
                number = PhoneNumberUtil.getInstance().parse(control.value.number, control.value.countryCode);
            } catch (e) {
                if (isRequired) {
                    // eslint-disable-next-line consistent-return
                    return error;
                } else {
                    inputBox.setCustomValidity('');
                }
            }

            if (control.value) {
                if (number === undefined) {
                    // eslint-disable-next-line consistent-return
                    return error;
                } else {
                    if (PhoneNumberUtil.getInstance().isValidNumberForRegion(number, control.value.countryCode)) {
                        // eslint-disable-next-line consistent-return
                        return error;
                    } else {
                        inputBox.setCustomValidity('');
                    }
                }
            }
        } else if (isCheckValidation === 'false') {
            inputBox.setCustomValidity('');

            control.clearValidators();
        }
    }

    return null;
};
