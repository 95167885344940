<div class="space-y-8 text-white" *ngIf="(isProcessing$ | async) === false" [formGroup]="mobileForm">
    <h3 class="text-center font-semibold text-lg">Add mobile device</h3>
    <p class="mb-4">Enter your mobile number and a device name and we will dispatch an SMS with a special link to add it as an auth device</p>
    <passbot-phone-input class="block mb-4" [control]="mobileForm.controls.mobileNumber"></passbot-phone-input>

    <div class="">
        <input formControlName="name" type="text" class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Device Name"/>
    </div>

    <div class="flex justify-between mt-8">
        <div (click)="close()" class="cursor-pointer inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
            Cancel
        </div>
        <button
            (click)="addMobile()"
            [disabled]="mobileForm.invalid"
            class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed">
            Send
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
    <div>Awaiting authentication on device, check your device for a SMS message with a URL to authenticate the device</div>
</div>
