import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { scanRoutes } from './lib.routes';
import { ScanComponent } from './components/scan/scan.component';

@NgModule({
    imports: [CommonModule, RouterModule.forChild(scanRoutes)],
    declarations: [ScanComponent],
})
export class ScanModule {}
