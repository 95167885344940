import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IUser, UserStatus } from '@passbot/shared';
import { Actions, ofType } from '@ngrx/effects';
import { firstValueFrom, merge } from 'rxjs';
import {
    createUser,
    createUserError,
    createUserSuccess,
    loadUsers,
    removeUser,
    removeUserError,
    removeUserSuccess,
    updateUser,
    updateUserError,
    updateUserSuccess,
} from './users.actions';
import { usersSelectors } from './users.selectors';

@Injectable()
export class UsersFacade {
    public users$ = this.store.pipe(select(usersSelectors.selectAll));
    public isProcessing$ = this.store.pipe(select(usersSelectors.getIsProcessing));
    public getById$ = (id: string) => this.store.pipe(select(usersSelectors.getById(id)));
    public getByGroupId$ = (groupId: string, includeTenants = false) => this.store.pipe(select(usersSelectors.getByGroupId(groupId, includeTenants)));
    public getOtherUsers$ = this.store.pipe(select(usersSelectors.getOtherUsers));
    public searchUsers$ = (searchStr: string) => this.store.pipe(select(usersSelectors.searchUsers(searchStr)));
    public usersAlphabetically$ = this.store.pipe(select(usersSelectors.getUsersAlphabetically));

    constructor(private readonly store: Store, private readonly actions: Actions) {}

    public loadUsers() {
        this.store.dispatch(loadUsers());
    }

    public createUser(user: IUser) {
        this.store.dispatch(createUser(user));
    }

    public createUserAsync(user: IUser) {
        this.createUser(user);

        const createSuccess = this.actions.pipe(ofType(createUserSuccess));
        const createError = this.actions.pipe(ofType(createUserError));

        return firstValueFrom(merge(createSuccess, createError));
    }

    public deleteUser(userId: IUser['id']) {
        this.store.dispatch(removeUser({ userId }));
    }

    public deleteUserAsync(userId: IUser['id']) {
        this.deleteUser(userId);

        const deleteSuccess = this.actions.pipe(ofType(removeUserSuccess));
        const deleteError = this.actions.pipe(ofType(removeUserError));

        return firstValueFrom(merge(deleteSuccess, deleteError));
    }

    public updateUser(user: IUser) {
        this.store.dispatch(updateUser(user));
    }

    public updateUserAsync(user: IUser) {
        this.updateUser(user);

        const updateSuccess = this.actions.pipe(ofType(updateUserSuccess));
        const updateError = this.actions.pipe(ofType(updateUserError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }

    public async promoteUser(user?: IUser) {
        if (user) {
            return this.updateUserAsync({ ...user, status: UserStatus.active });
        }

        // find a temp user (should only be 1)
        const users = await firstValueFrom(this.store.pipe(select(usersSelectors.getByStatus(UserStatus.temp))));
        if (users.length > 0) {
            const tempUser = users[0];
            return this.updateUserAsync({ ...tempUser, status: UserStatus.active });
        }

        return user;
    }
}
