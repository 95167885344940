import { Component, Inject } from '@angular/core';
import { APIService } from '@passbot/angular/api';
import { ICredential } from '@passbot/shared';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { ToastrService } from 'ngx-toastr';
import { CredentialsFacade } from '../../+state';

@Component({
    selector: 'passbot-view-credential-modal',
    templateUrl: './view-credential-modal.component.html',
})
export class ViewCredentialModalComponent {
    public credential: ICredential;
    public mfaCode: string;
    public loadingMfa = false;
    public timeRemaining = 30;
    private timer: number;

    constructor(
        private readonly api: APIService,
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) public readonly data: { credentialId: string },
        private readonly toastr: ToastrService,
        private readonly credentialsFacade: CredentialsFacade,
    ) {}

    public async ngOnInit() {
        this.credential = await this.api.getAsync(`/credential/${this.data.credentialId}`);
        this.runTimer();
    }

    public ngOnDestroy() {
        this.killTimer();
    }

    public close() {
        this.modal.close();
        this.credentialsFacade.setViewed(this.credential);
    }

    public copy(text: string | undefined, element: string) {
        void navigator.clipboard.writeText(text || '');
        this.toastr.success(`${element} copied to clipboard`);
    }

    public async getMFACode() {
        this.loadingMfa = true;
        const mfaCode = await this.api.getAsync<{ token: string }>(`/credential/${this.credential.id}/token`);
        this.mfaCode = mfaCode.token;
    }

    private killTimer() {
        clearTimeout(this.timer);
    }

    private runTimer() {
        this.timer = setTimeout(() => {
            this.timeRemaining--;

            if (this.timeRemaining === 0) {
                this.close();
                return;
            }

            this.runTimer();
        }, 1000) as unknown as number;
    }
}
