<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="(isProcessing$ | async) === false && (awaiting2FA$ | async ) !== true" [formGroup]="credentialForm">
    <h3 class="text-center font-semibold text-lg">{{ editing ? 'Edit' : 'Create'}} Credential</h3>
    <div class="text-left">
        <label for="group" class="text-xs mb-1" [ngClass]="{'!text-red-500': credentialForm.controls.credentialGroups.invalid && credentialForm.controls.credentialGroups.touched }">Vault(s) *</label>
        <ng-select [items]="groups$ | async"
                   bindLabel="name"
                   labelForId="group"
                   id="group"
                   [addTag]="false"
                   [multiple]="true"
                   [hideSelected]="true"
                   [trackByFn]="trackByFn"
                   [minTermLength]="2"
                   [loading]="false"
                   typeToSearchText="Please enter 2 or more characters"
                   formControlName="credentialGroups"
        >
        </ng-select>
    </div>

    <div class="">
        <label class="text-xs mb-1" [ngClass]="{'!text-red-500': credentialForm.controls.name.invalid && credentialForm.controls.name.touched }">Service Name *</label>
        <input
            formControlName="name"
            type="text"
            [ngClass]="{'!ring-red-500': credentialForm.controls.name.invalid && credentialForm.controls.name.touched}"
            class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
    </div>

    <div class="">
        <label class="text-xs mb-1" [ngClass]="{'!text-red-500': credentialForm.controls.url.invalid && credentialForm.controls.url.touched }">Service Website URL</label>
        <input
            formControlName="url"
            type="text"
            [ngClass]="{'!ring-red-500': credentialForm.controls.url.invalid && credentialForm.controls.url.touched }"
            class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
    </div>

    <div class="">
        <label class="text-xs mb-1" [ngClass]="{'!text-red-500': credentialForm.controls.username.invalid && credentialForm.controls.username.touched }">Username *</label>
        <input
            formControlName="username"
            [ngClass]="{'!ring-red-500': credentialForm.controls.username.invalid && credentialForm.controls.username.touched }"
            type="text"
            class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
    </div>

    <div>
        <div class="flex justify-between">
            <label class="text-xs mb-1" [ngClass]="{'!text-red-500': credentialForm.controls.password.invalid && credentialForm.controls.password.touched }">Password *</label>
            <span (click)="generateStrongPassword()" class="text-xs text-brand cursor-pointer">Generate strong password</span>
        </div>
        <input
            formControlName="password"
            type="password"
            passbotTogglePassword
            #passwordInput
            [ngClass]="{'!ring-red-500': credentialForm.controls.password.invalid && credentialForm.controls.password.touched }"
            class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
    </div>

    <div>
        <label class="text-xs mb-1" [ngClass]="{'!text-red-500': credentialForm.controls.totpKey.invalid && credentialForm.controls.totpKey.touched }">2FA Token</label>
        <input
            formControlName="totpKey"
            type="text"
            [ngClass]="{'!ring-red-500': credentialForm.controls.totpKey.invalid && credentialForm.controls.totpKey.touched }"
            class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"
        />
        <div class="flex justify-between">
            <div>
                <a *ngIf="credentialForm.controls.totpKey.value && credentialForm.controls.totpKey.valid && credentialForm.controls.totpKey.value !== '*****'" (click)="generateTotp()" class="text-sm text-brand cursor-pointer">Generate verification code</a>
            </div>
            <a *ngIf="credentialForm.controls.totpKey.invalid || credentialForm.controls.totpKey.value === '' || credentialForm.controls.totpKey.value === '*****'" (click)="scanWithPhone()" class="text-sm text-brand cursor-pointer"><i class="fa-light fa-qrcode"></i> Scan with my phone</a>
        </div>
    </div>
    <div *ngIf="editing && this.credentialForm.controls.totpKey.value === '' && had2FA" class="border border-red-600 rounded p-4 space-y-2 text-red-500">

        <div>
            <span class="font-bold">PLEASE NOTE:</span>
            <div class="text-sm">
                Deleting the 2FA code from this credential is non-reversible and without changing your 2FA device or method for {{ credentialForm.value.name }} you could lose access to the service.<br/>
                To confirm this action, enter the name of the service in the input below
            </div>
        </div>
        <input
                formControlName="confirm2FA"
                type="text"
                [ngClass]="{'!ring-red-500': credentialForm.controls.confirm2FA.invalid && credentialForm.controls.confirm2FA.touched }"
                [placeholder]="credentialForm.controls.name.value"
                class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"
        />
    </div>


    <div class="flex justify-center items-center" *ngIf="totp">
        <div class="border border-white/20 rounded-lg inline-block p-4 text-center mx-auto">
            <div class="text-white font-semibold">2FA Code:</div>
            <div class="text-white font-semibold text-3xl">{{ totp }}</div>
        </div>
    </div>

    <div class="flex justify-between mt-8">
        <div (click)="close()" class="cursor-pointer inline-flex justify-center bg-slate-200 cursor-pointer hover:bg-slate-100 text-slate-800 py-2 px-4 border-b-4 border-white rounded-lg">
            Cancel
        </div>
        <div class="text-center text-xs text-white flex items-center justify-center">
            * required field <span class="text-brand" (click)="debug()">debug</span>
        </div>
        <button
                (click)="saveCredential()"
                [disabled]="!(credentialForm.valid && credentialForm.dirty)"
                class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
            {{ editing ? 'Save' : 'Create' }}
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="(awaiting2FA$ | async) === true">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
    <div>Awaiting 2FA token, check your phone for a link to scan the barcode.</div>
    <a (click)="cancel2FAScan()" class="text-sm cursor-pointer text-brand">Cancel</a>
</div>

