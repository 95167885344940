import { Component, Inject } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { Observable } from 'rxjs';

export interface IDeleteModalData {
    itemType: string;
    itemName: string;
    processing$: Observable<boolean>;
    callback: () => Promise<any>;
}

@Component({
    selector: 'passbot-delete-item-modal',
    templateUrl: './delete-item-modal.component.html',
})
export class DeleteItemModalComponent {
    public isProcessing$;

    constructor(@Inject(MODAL_REF) private readonly modal: ModalComponent, @Inject(MODAL_DATA) public data: IDeleteModalData) {
        this.isProcessing$ = data.processing$;
    }

    public cancel() {
        this.modal.close(false);
    }

    public async delete() {
        await this.data.callback();
        this.modal.close(true);
    }
}
