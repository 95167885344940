<div class='flex relative space-x-2'>

<div #dropdown>
    <div class="relative w-28">
        <button
            type="button"
            class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            [class.bg-black]="control.disabled"
            [attr.aria-haspopup]="open"
            [attr.aria-expanded]="open"
            (click)="toggleDropdown()"
            [disabled]="control.disabled"
        >
      <span class="truncate flex items-center">
        <img *ngIf='selectedCountry'
             [alt]="selectedCountry.iso"
             [src]="'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + selectedCountry.iso.toUpperCase() + '.svg'"
             class="inline mr-2 h-4 rounded-sm"
        />
           <span *ngIf='selectedCountry?.dialCode'>+{{ selectedCountry.dialCode }}</span>
      </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" [class.hidden]="control.disabled">
        <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
        >
          <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
          ></path>
        </svg>
      </span>
        </button>


    </div>
</div>
<div class='flex-1'>
    <input #numberInput [(ngModel)]='phoneNumber' (ngModelChange)='onPhoneNumberChange()' [placeholder]='placeholder' class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
</div>

    <ul
        class="absolute z-10 mt-1 w-full bg-slate-800 shadow-lg max-h-80 rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-3"
        #dropdownContent
        [ngClass]="{ 'visible': open, 'invisible pointer-events-none': !open}"
    >
        <div class="sticky top-0 z-10 bg-slate-800">
            <li class="text-gray-200 cursor-default select-none relative py-2 px-3">
                <input
                    [ngClass]='{"ring-red-500": control.invalid && control.touched }'
                    type="search"
                    name="search"
                    autocomplete="off"
                    class="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-slate-900 rounded-md"
                    placeholder="Search a country"
                    [(ngModel)]="query"
                />
            </li>
            <hr class='border-black' />
        </div>

        <div
            class="max-h-64 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll"
        >
            <ng-container *ngIf="filterCountries().length === 0; else countriesList">
                <li class="text-gray-200 cursor-default select-none relative py-2 pl-3 pr-9">
                    No countries found
                </li>
            </ng-container>

            <ng-template #countriesList>
                <li
                    *ngFor="let country of filterCountries(); let i = index"
                    class="cursor-pointer text-gray-200 cursor-default select-none relative py-2 pl-3 pr-9 flex items-center hover:bg-slate-900 hover:text-white transition"
                    [id]="'listbox-option-' + i"
                    role="option"
                    (click)="selectCountry(country)"
                >
                    <img
                        [alt]="country.iso"
                        [src]="'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + country.iso.toUpperCase() + '.svg'"
                        class="inline mr-2 h-4 rounded-sm"
                    />

                    <span class="font-normal truncate">{{ country.title }}</span>
                    &nbsp;&nbsp;
                    <span class='font-grey-300'>+{{ country.dialCode }}</span>

                    <span
                        *ngIf="country.iso === selectedCountry?.iso"
                        class="text-blue-500 absolute inset-y-0 right-0 flex items-center pr-8"
                    >
              <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
              >
                <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                ></path>
              </svg>
            </span>
                </li>
            </ng-template>
        </div>
    </ul>
</div>

