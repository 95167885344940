// eslint-disable-next-line max-classes-per-file
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PassbotCommonModule } from '@passbot/angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { billingRoutes } from './lib.routes';
import { IndexComponent } from './components/index/index.component';
import { AdjustSeatsModalComponent } from './components/adjust-seats-modal/adjust-seats-modal.component';

@NgModule({
    imports: [CommonModule, PassbotCommonModule, ReactiveFormsModule, FormsModule],
    declarations: [IndexComponent, AdjustSeatsModalComponent],
    exports: [AdjustSeatsModalComponent],
})
export class BillingModule {}

@NgModule({
    imports: [CommonModule, BillingModule, RouterModule.forChild(billingRoutes)],
})
export class BillingModuleRouted {}
