import { Component, Inject } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ICredentialGroup } from '@passbot/shared';
import { CredentialGroupsFacade } from '@passbot/angular/credentials';
import { ToastrService } from 'ngx-toastr';

export interface IEditCredentialGroupData {
    credentialGroup: ICredentialGroup;
}

@Component({
    templateUrl: './create-vault-modal.component.html',
    selector: 'passbot-create-vault-modal',
})
export class CreateVaultModalComponent {
    public processing = false;
    public existingGroups: ICredentialGroup[] = [];

    public nameForm = new FormGroup({
        name: new FormControl('', { validators: [Validators.required, this.exists.bind(this)] }),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) public readonly data: IEditCredentialGroupData,
        private readonly groupsFacade: CredentialGroupsFacade,
        private readonly toastr: ToastrService,
    ) {}

    public async ngOnInit() {
        this.existingGroups = await firstValueFrom(this.groupsFacade.getAll$);

        if (this.data?.credentialGroup) {
            this.nameForm.patchValue({ name: this.data.credentialGroup.name });
        }
    }

    public async save() {
        if (!this.nameForm.valid) {
            return;
        }

        this.processing = true;

        if (this.data?.credentialGroup) {
            await this.groupsFacade.updateCredentialGroupAsync({ name: this.nameForm.controls.name.value!, id: this.data.credentialGroup.id });
            this.toastr.success(this.nameForm.controls.name.value!, 'Vault updated');
        } else {
            await this.groupsFacade.createAsync(this.nameForm.controls.name.value!);
            this.toastr.success(this.nameForm.controls.name.value!, 'New vault created');
        }

        this.modal.close();
    }

    public cancel() {
        this.modal.close();
    }

    public exists(control: FormControl) {
        const name = control.value.toLowerCase();
        const existingNames = this.existingGroups.map((g) => g.name.toLowerCase());
        if (existingNames.includes(name)) {
            return { matching: true } as ValidationErrors;
        }

        return null;
    }
}
