import { Inject, Injectable } from '@angular/core';
import { AuthService, UserFacade } from '@passbot/angular/auth';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ModalService } from '@passbot/angular/modal';
import { DOCUMENT } from '@angular/common';
import { TimeoutModalComponent } from '../components/timeout-modal/timeout-modal.component';

@Injectable({ providedIn: 'root' })
export class KeepaliveService {
    private hadActivity = false;
    private notSeenCount = 0;
    private lastSeen: number = new Date().getTime();
    private tracking = false;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly userFacade: UserFacade,
        private readonly authService: AuthService,
        private readonly modal: ModalService,
        @Inject(DOCUMENT) private readonly document: Document,
    ) {}

    public init() {
        this.trackUser();
        this.initListeners();
    }

    public trackActivity() {
        if (!this.tracking) {
            return true;
        }

        this.hadActivity = true;
        return true;
    }

    public keepAlive() {
        this.trackActivity();
        void this.sendKeepAlive();
    }

    private async checkActivity() {
        if (this.hadActivity) {
            await this.sendKeepAlive();
            this.notSeenCount = 0;
            this.hadActivity = false;
            this.scheduleActivityTrack();
            return;
        }

        this.notSeenCount++;

        if (this.notSeenCount === 4) {
            const response = await firstValueFrom(this.modal.show(TimeoutModalComponent));

            if (!response) {
                void this.authService.logout();
                return;
            }

            this.keepAlive();
        }

        this.scheduleActivityTrack();
    }

    private scheduleActivityTrack() {
        setTimeout(this.checkActivity.bind(this), 30000);
    }

    private sendKeepAlive() {
        const { hostname, protocol } = window.location;
        const tenantApiHost = `${protocol}//${hostname}/api`;
        return firstValueFrom(this.httpClient.post<{ ok: boolean }>(`${tenantApiHost}/auth/session/keep-alive`, { body: {}, withCredentials: true }));
    }

    private trackUser() {
        this.userFacade.getUser$.subscribe((user) => {
            if (user) {
                this.tracking = true;
                this.scheduleActivityTrack();
                this.initListeners();
                return;
            }
        });
    }

    private initListeners() {
        window.addEventListener('focus', this.checkLastSeen.bind(this));

        this.document.addEventListener('keyup', this.trackActivity.bind(this));

        this.document.addEventListener('mouseup', this.trackActivity.bind(this));
    }

    private checkLastSeen() {
        if (!this.tracking) {
            return true;
        }

        const timeNow = new Date().getTime();

        if (timeNow - this.lastSeen > 230 * 1000) {
            void this.authService.logout();
            return false;
        }

        this.lastSeen = timeNow;

        return true;
    }
}
