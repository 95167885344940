<div class="fixed pointer-events-none inset-0 z-[100] flex flex-col justify-end items-end overflow-hidden pt-16 top-0 left-0 w-screen h-screen" role="sidebar">
    <div *ngIf="visible && !closing" class="flex flex-col h-full self-end border border-black/20 pointer-events-auto relative max-w-screen lg:max-w-[600px] min-w-[450px] rounded-lg bg-slate-950 text-center dark:bg-slate-950 shadow-[0_35px_60px_-15px_rgba(0,0,0,1)]" [@slideInRightOnEnter] [@slideOutRightOnLeave]>
        <div class="flex h-16 text-slate-200 items-center border-b border-slate-900">
            <div class="border-r border-slate-900 flex items-center justify-center h-16 px-4 cursor-pointer" (click)="cancel()"><i class="fa-light fa-close text-2xl"></i></div>
            <div class="px-4 flex-1 text-xl font-semibold">{{ title }}</div>
        </div>
        <div class="flex-1 flex flex-col justify-center overflow-hidden">
            <ng-template *ngIf="childComponent" modalContent></ng-template>

            <ng-container *ngIf="template">
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </ng-container>

            <div class="text-white" *ngIf="trustedHtml" [innerHTML]="trustedHtml"></div>
        </div>
        <div class="border-t border-slate-900 flex justify-between">
            <div>
                <div (click)="close()" class="cursor-pointer px-4 py-4 inline-flex justify-center cursor-pointer hover:text-slate-100 text-slate-700 hover:border-b-slate-300 transition-all border-b-4 border-r border-slate-900">
                    Cancel
                </div>
            </div>
            <div>
                <button
                        (click)="saveClick()"
                        [disabled]="formValid && ((formValid | async) !== false)"
                        class="inline-flex px-4 py-4 justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white border-b-4 border-orange-700 hover:border-orange-800 disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
                    {{ saveText }}
                </button>
            </div>
        </div>
    </div>
</div>




<!--<div class="modal__backdrop {{ class }}" [class.modal__backdrop&#45;&#45;closing]="closing">-->
<!--    <div class="modal__container" [class.modal__container&#45;&#45;closing]="closing">-->
<!--        <div class="modal__header" *ngIf='title'>-->
<!--            <h2 class="modal__title" *ngIf="title">{{ title }}</h2>-->
<!--            <a class="modal__close" (click)="close()"><i class='fal fa-times-square'></i></a>-->
<!--        </div>-->

<!--        &lt;!&ndash; Body &ndash;&gt;-->
<!--        <div class="modal__content">-->
<!--            <ng-template *ngIf="childComponent" modalContent></ng-template>-->

<!--            <ng-container *ngIf="template">-->
<!--                <ng-container *ngTemplateOutlet="template"></ng-container>-->
<!--            </ng-container>-->

<!--            <div *ngIf="trustedHtml" [innerHTML]="trustedHtml"></div>-->
<!--        </div>-->

<!--        &lt;!&ndash; Footer &ndash;&gt;-->
<!--        <div class="modal__footer" *ngIf="closeText && closeText !== ''">-->
<!--            <button type="button" class="btn" (click)="close()">{{ closeText }}</button>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="modal__outside-capture" *ngIf="hideOnClickOutside" (click)="close()"></div>-->
<!--</div>-->
