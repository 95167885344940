import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { RegisterComponent } from './components/register/register.component';
import { WebauthService } from './services/webauth.service';
import { AuthComponent } from './components/auth/auth.component';
import { webauthnRoutes } from './lib.routes';

@NgModule({
    imports: [CommonModule, RouterModule.forChild(webauthnRoutes)],
    declarations: [AuthComponent, RegisterComponent],
    providers: [WebauthService],
})
export class WebauthnModule {}
