import { createReducer, on } from '@ngrx/store';
import { IUserPermission } from '@passbot/shared';
import { createEntityAdapter } from '@ngrx/entity';
import { entityStartProcessing, entityStopProcessing, getDefaultEntityState, IEntityState } from '@passbot/angular/common';
import { updateCredentialGroupPermissionsSuccess } from '@passbot/angular/credentials';
import { loadUsersSuccess, updateUserSuccess } from '../users/users.actions';
import {
    removeUserPermission,
    removeUserPermissionError,
    removeUserPermissionSuccess,
    updateUserPermission,
    updateUserPermissionSuccess,
} from './permissions.actions';

export const PERMISSIONS_FEATURE_KEY = 'permissions';

export const permissionsAdapter = createEntityAdapter<IUserPermission>();

export type PermissionsState = IEntityState<IUserPermission>;

export const initialPermissionsState: PermissionsState = getDefaultEntityState(permissionsAdapter);

export const PermissionsReducers = createReducer(
    initialPermissionsState,
    on(removeUserPermission, (state) => entityStartProcessing(state)),
    on(removeUserPermissionSuccess, (state, { permissionId }) => {
        const perm = state.entities[permissionId];
        if (!perm) {
            return state;
        }

        return entityStopProcessing(permissionsAdapter.removeOne(permissionId, state));
    }),
    on(removeUserPermissionError, (state, { msg }) => entityStopProcessing({ ...state, error: msg })),
    on(updateUserPermission, (state) => entityStartProcessing(state)),
    on(updateUserPermissionSuccess, (state, { permission }) => {
        const perm = state.entities[permission.id];
        if (!perm) {
            return state;
        }

        return permissionsAdapter.upsertOne({ ...perm, ...permission }, entityStopProcessing(state));
    }),
    on(loadUsersSuccess, (state, { users }) => {
        // @todo can remove this once permissions has it's own endpoint
        let updatedState = state;
        users.forEach((user) => {
            updatedState = permissionsAdapter.upsertMany(user.permissions as IUserPermission[], updatedState);
        });
        return updatedState;
    }),
    on(updateCredentialGroupPermissionsSuccess, (state, props) => {
        let updatedState = state;
        updatedState = permissionsAdapter.removeMany(
            Object.values(state.entities)
                .filter((perm) => perm!.credentialGroup.id)
                .map((perm) => perm!.id),
            state,
        );
        return permissionsAdapter.upsertMany(props.permissions, updatedState);
    }),
    on(updateUserSuccess, (state, props) => {
        if (props.permissions) {
            // get all the user permissions
            const userPermissionIds = Object.values(state.entities)
                .filter((perm) => perm!.user.id === props.id)
                .map((perm) => perm!.id);

            return permissionsAdapter.upsertMany(props.permissions as IUserPermission[], permissionsAdapter.removeMany(userPermissionIds, state));
        }
        return state;
    }),
);
