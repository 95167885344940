import { Component, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { IUser, UserStatus } from '@passbot/shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { UsersFacade } from '../../+state/users/users.facade';

@Component({
    selector: 'passbot-promote-user',
    templateUrl: './promote-user.component.html',
})
export class PromoteUserComponent {
    public users$ = this.permissionsFacade.users$.pipe(map((users) => users.filter((user) => user.status === UserStatus.temp)));

    public userForm = new FormGroup({
        user: new FormControl<IUser | null>(null, [Validators.required]),
    });

    constructor(@Inject(MODAL_REF) private readonly modal: ModalComponent, private readonly permissionsFacade: UsersFacade) {}

    public async promoteUser() {
        // do something
        await this.permissionsFacade.promoteUser(this.userForm.controls.user.value!);
        this.modal.close();
    }

    public close() {
        // close modal
        this.modal.close();
    }
}
