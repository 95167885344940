import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'passbotPrettyList',
})
export class PrettyListPipe implements PipeTransform {
    public transform(items: unknown[], outputField?: string, makeBold = true) {
        const itemOutput = (item: unknown) => {
            const output = outputField ? (item as any)[outputField] : item;
            return makeBold ? `<span class="font-semibold text-brand">${output}</span>` : output;
        };

        if (items.length === 1) {
            return itemOutput(items[0]);
        }

        const sanitisedItems = items.map((item) => itemOutput(item));
        const lastItem = sanitisedItems.pop();

        return `${sanitisedItems.join(', ')} and ${lastItem}`;
    }
}
