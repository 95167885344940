<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="(isProcessing$ | async) === false">
    <h3 class="text-center font-semibold text-lg text-brand">Delete {{ data.itemType }}</h3>
    <p>Are you sure you want to delete this {{ data.itemType }}?</p>
    <div class="flex justify-between mt-8">
        <div (click)="cancel()" class="cursor-pointer inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
            Cancel
        </div>
        <button
                (click)="delete()"
                class="inline-flex justify-center rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed">
            Delete {{ data.itemName }}
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>

