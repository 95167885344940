import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser, IUserPermission } from '@passbot/shared';
import { PermissionsFacade } from '../../../+state';

@Component({
    selector: 'passbot-user-permissions-table',
    templateUrl: './user-permissions-table.component.html',
})
export class UserPermissionsTableComponent {
    @Input() public user: IUser;
    @Input() public isOpen: boolean;

    public permissions$: Observable<IUserPermission[]>;

    constructor(private readonly permissionsFacade: PermissionsFacade) {}

    public ngOnInit() {
        this.permissions$ = this.permissionsFacade.getByUserId$(this.user.id);
    }
}
