import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectUrl,
} = getRouterSelectors(selectRouter);

export const selectRouteData = createSelector(selectCurrentRoute, (state) => {
    if (!state) {
        return {} as Record<string, any>;
    }

    /*const root = state;

    console.log('root', root);

    let routeData: Record<string, any> = {};

    const getChildrenData = (route: ActivatedRouteSnapshot) => {
        route.children.forEach((childRoute) => {
            routeData = { ...routeData, ...childRoute.data };
            getChildrenData(childRoute);
        });
    };

    getChildrenData(root);

    return routeData;*/
    return state.data;
}); // select the current route data
