<div class="space-y-8 text-white min-w-[400px] text-left">
    <h3 class="text-center font-semibold text-lg text-brand">Inactivity Timeout</h3>
    <div>
        You will automatically be logged out in <span class="font-bold">{{ timer }}</span> seconds due to inactivity
    </div>
    <div class="flex justify-between mt-8">
        <div (click)="logout()" class="cursor-pointer inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
            Logout
        </div>
        <button
                (click)="stayLoggedIn()"
                class="inline-flex justify-center rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed">
            Stay logged in
        </button>
    </div>
</div>
