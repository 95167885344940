import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalService } from './modal.service';
import { ModalContentDirective } from './modal/modal-content.directive';
import { ModalComponent } from './modal/modal.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ModalComponent, ModalContentDirective],
    exports: [ModalComponent],
    providers: [ModalService, ModalContentDirective],
})
export class ModalModule {}
