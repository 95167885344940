import { CountryISO } from './country-iso';

export interface ICountryPhoneInput {
    title: string;
    iso: string;
    dialCode: string;
    priority: number;
    areaCodes: (number | string)[];
}

export const allCountries: ICountryPhoneInput[] = [
    {
        title: 'Afghanistan (‫افغانستان‬‎)',
        iso: CountryISO.Afghanistan,
        dialCode: '93',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Albania (Shqipëri)',
        iso: CountryISO.Albania,
        dialCode: '355',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Algeria (‫الجزائر‬‎)',
        iso: CountryISO.Algeria,
        dialCode: '213',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'American Samoa',
        iso: 'as',
        dialCode: '1',
        priority: 1,
        areaCodes: ['684'],
    },
    {
        title: 'Andorra',
        iso: CountryISO.Andorra,
        dialCode: '376',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Angola',
        iso: CountryISO.Angola,
        dialCode: '244',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Anguilla',
        iso: 'ai',
        dialCode: '1',
        priority: 1,
        areaCodes: ['264'],
    },
    {
        title: 'Antigua and Barbuda',
        iso: 'ag',
        dialCode: '1',
        priority: 1,
        areaCodes: ['268'],
    },
    {
        title: 'Argentina',
        iso: CountryISO.Argentina,
        dialCode: '54',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Armenia (Հայաստան)',
        iso: CountryISO.Armenia,
        dialCode: '374',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Aruba',
        iso: CountryISO.Aruba,
        dialCode: '297',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Australia',
        iso: CountryISO.Australia,
        dialCode: '61',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Austria (Österreich)',
        iso: CountryISO.Austria,
        dialCode: '43',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Azerbaijan (Azərbaycan)',
        iso: CountryISO.Azerbaijan,
        dialCode: '994',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Bahamas',
        iso: 'bs',
        dialCode: '1',
        priority: 1,
        areaCodes: ['242'],
    },
    {
        title: 'Bahrain (‫البحرين‬‎)',
        iso: CountryISO.Bahrain,
        dialCode: '973',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Bangladesh (বাংলাদেশ)',
        iso: CountryISO.Bangladesh,
        dialCode: '880',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Barbados',
        iso: 'bb',
        dialCode: '1',
        priority: 1,
        areaCodes: ['246'],
    },
    {
        title: 'Belarus (Беларусь)',
        iso: CountryISO.Belarus,
        dialCode: '375',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Belgium (België)',
        iso: CountryISO.Belgium,
        dialCode: '32',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Belize',
        iso: CountryISO.Belize,
        dialCode: '501',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Benin (Bénin)',
        iso: CountryISO.Benin,
        dialCode: '229',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Bermuda',
        iso: 'bm',
        dialCode: '1',
        priority: 1,
        areaCodes: ['441'],
    },
    {
        title: 'Bhutan (འབྲུག)',
        iso: CountryISO.Bhutan,
        dialCode: '975',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Bolivia',
        iso: CountryISO.Bolivia,
        dialCode: '591',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Bosnia and Herzegovina (Босна и Херцеговина)',
        iso: CountryISO.BosniaAndHerzegovina,
        dialCode: '387',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Botswana',
        iso: CountryISO.Botswana,
        dialCode: '267',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Brazil (Brasil)',
        iso: CountryISO.Brazil,
        dialCode: '55',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'British Indian Ocean Territory',
        iso: CountryISO.BritishIndianOceanTerritory,
        dialCode: '246',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'British Virgin Islands',
        iso: 'vg',
        dialCode: '1',
        priority: 1,
        areaCodes: ['284'],
    },
    {
        title: 'Brunei',
        iso: CountryISO.Brunei,
        dialCode: '673',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Bulgaria (България)',
        iso: CountryISO.Bulgaria,
        dialCode: '359',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Burkina Faso',
        iso: CountryISO.BurkinaFaso,
        dialCode: '226',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Burundi (Uburundi)',
        iso: CountryISO.Burundi,
        dialCode: '257',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Cambodia (កម្ពុជា)',
        iso: CountryISO.Cambodia,
        dialCode: '855',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Cameroon (Cameroun)',
        iso: CountryISO.Cameroon,
        dialCode: '237',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Canada',
        iso: CountryISO.Canada,
        dialCode: '1',
        priority: 1,
        areaCodes: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '306',
            '343',
            '365',
            '387',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '705',
            '709',
            '742',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
    },
    {
        title: 'Cape Verde (Kabu Verdi)',
        iso: CountryISO.CapeVerde,
        dialCode: '238',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Caribbean Netherlands',
        iso: CountryISO.CaribbeanNetherlands,
        dialCode: '599',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Cayman Islands',
        iso: 'ky',
        dialCode: '1',
        priority: 1,
        areaCodes: ['345'],
    },
    {
        title: 'Central African Republic (République centrafricaine)',
        iso: CountryISO.CentralAfricanRepublic,
        dialCode: '236',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Chad (Tchad)',
        iso: CountryISO.Chad,
        dialCode: '235',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Chile',
        iso: CountryISO.Chile,
        dialCode: '56',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'China (中国)',
        iso: CountryISO.China,
        dialCode: '86',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Christmas Island',
        iso: CountryISO.ChristmasIsland,
        dialCode: '61',
        priority: 2,
        areaCodes: [],
    },
    {
        title: 'Cocos (Keeling) Islands',
        iso: CountryISO.Cocos,
        dialCode: '61',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Colombia',
        iso: CountryISO.Colombia,
        dialCode: '57',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Comoros (‫جزر القمر‬‎)',
        iso: CountryISO.Comoros,
        dialCode: '269',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
        iso: CountryISO.CongoDRCJamhuriYaKidemokrasiaYaKongo,
        dialCode: '243',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Congo (Republic) (Congo-Brazzaville)',
        iso: CountryISO.CongoRepublicCongoBrazzaville,
        dialCode: '242',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Cook Islands',
        iso: CountryISO.CookIslands,
        dialCode: '682',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Costa Rica',
        iso: CountryISO.CostaRica,
        dialCode: '506',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Côte d’Ivoire',
        iso: CountryISO.CôteDIvoire,
        dialCode: '225',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Croatia (Hrvatska)',
        iso: CountryISO.Croatia,
        dialCode: '385',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Cuba',
        iso: CountryISO.Cuba,
        dialCode: '53',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Curaçao',
        iso: CountryISO.Curaçao,
        dialCode: '599',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Cyprus (Κύπρος)',
        iso: CountryISO.Cyprus,
        dialCode: '357',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Czech Republic (Česká republika)',
        iso: CountryISO.CzechRepublic,
        dialCode: '420',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Denmark (Danmark)',
        iso: CountryISO.Denmark,
        dialCode: '45',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Djibouti',
        iso: CountryISO.Djibouti,
        dialCode: '253',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Dominica',
        iso: CountryISO.Dominica,
        dialCode: '1767',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Dominican Republic (República Dominicana)',
        iso: CountryISO.DominicanRepublic,
        dialCode: '1',
        priority: 2,
        areaCodes: ['809', '829', '849'],
    },
    {
        title: 'Ecuador',
        iso: CountryISO.Ecuador,
        dialCode: '593',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Egypt (‫مصر‬‎)',
        iso: CountryISO.Egypt,
        dialCode: '20',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'El Salvador',
        iso: CountryISO.ElSalvador,
        dialCode: '503',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Equatorial Guinea (Guinea Ecuatorial)',
        iso: CountryISO.EquatorialGuinea,
        dialCode: '240',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Eritrea',
        iso: CountryISO.Eritrea,
        dialCode: '291',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Estonia (Eesti)',
        iso: CountryISO.Estonia,
        dialCode: '372',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Ethiopia',
        iso: CountryISO.Ethiopia,
        dialCode: '251',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Falkland Islands (Islas Malvinas)',
        iso: CountryISO.FalklandIslands,
        dialCode: '500',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Faroe Islands (Føroyar)',
        iso: CountryISO.FaroeIslands,
        dialCode: '298',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Fiji',
        iso: CountryISO.Fiji,
        dialCode: '679',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Finland (Suomi)',
        iso: CountryISO.Finland,
        dialCode: '358',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'France',
        iso: CountryISO.France,
        dialCode: '33',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'French Guiana (Guyane française)',
        iso: CountryISO.FrenchGuiana,
        dialCode: '594',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'French Polynesia (Polynésie française)',
        iso: CountryISO.FrenchPolynesia,
        dialCode: '689',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Gabon',
        iso: CountryISO.Gabon,
        dialCode: '241',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Gambia',
        iso: CountryISO.Gambia,
        dialCode: '220',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Georgia (საქართველო)',
        iso: CountryISO.Georgia,
        dialCode: '995',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Germany (Deutschland)',
        iso: CountryISO.Germany,
        dialCode: '49',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Ghana (Gaana)',
        iso: CountryISO.Ghana,
        dialCode: '233',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Gibraltar',
        iso: CountryISO.Gibraltar,
        dialCode: '350',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Greece (Ελλάδα)',
        iso: CountryISO.Greece,
        dialCode: '30',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Greenland (Kalaallit Nunaat)',
        iso: CountryISO.Greenland,
        dialCode: '299',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Grenada',
        iso: CountryISO.Grenada,
        dialCode: '1473',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Guadeloupe',
        iso: CountryISO.Guadeloupe,
        dialCode: '590',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Guam',
        iso: 'gu',
        dialCode: '1',
        priority: 1,
        areaCodes: ['671'],
    },
    {
        title: 'Guatemala',
        iso: CountryISO.Guatemala,
        dialCode: '502',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Guernsey',
        iso: CountryISO.Guernsey,
        dialCode: '44',
        priority: 1,
        areaCodes: [1481],
    },
    {
        title: 'Guinea (Guinée)',
        iso: CountryISO.Guinea,
        dialCode: '224',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Guinea-Bissau (Guiné Bissau)',
        iso: CountryISO.GuineaBissau,
        dialCode: '245',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Guyana',
        iso: CountryISO.Guyana,
        dialCode: '592',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Haiti',
        iso: CountryISO.Haiti,
        dialCode: '509',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Honduras',
        iso: CountryISO.Honduras,
        dialCode: '504',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Hong Kong (香港)',
        iso: CountryISO.HongKong,
        dialCode: '852',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Hungary (Magyarország)',
        iso: CountryISO.Hungary,
        dialCode: '36',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Iceland (Ísland)',
        iso: CountryISO.Iceland,
        dialCode: '354',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'India (भारत)',
        iso: CountryISO.India,
        dialCode: '91',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Indonesia',
        iso: CountryISO.Indonesia,
        dialCode: '62',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Iran (‫ایران‬‎)',
        iso: CountryISO.Iran,
        dialCode: '98',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Iraq (‫العراق‬‎)',
        iso: CountryISO.Iraq,
        dialCode: '964',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Ireland',
        iso: CountryISO.Ireland,
        dialCode: '353',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Isle of Man',
        iso: CountryISO.IsleOfMan,
        dialCode: '44',
        priority: 2,
        areaCodes: [1624],
    },
    {
        title: 'Israel (‫ישראל‬‎)',
        iso: CountryISO.Israel,
        dialCode: '972',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Italy (Italia)',
        iso: CountryISO.Italy,
        dialCode: '39',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Jamaica',
        iso: 'jm',
        dialCode: '1',
        priority: 1,
        areaCodes: ['876'],
    },
    {
        title: 'Japan (日本)',
        iso: CountryISO.Japan,
        dialCode: '81',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Jersey',
        iso: CountryISO.Jersey,
        dialCode: '44',
        priority: 3,
        areaCodes: [1534],
    },
    {
        title: 'Jordan (‫الأردن‬‎)',
        iso: CountryISO.Jordan,
        dialCode: '962',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Kazakhstan (Казахстан)',
        iso: CountryISO.Kazakhstan,
        dialCode: '7',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Kenya',
        iso: CountryISO.Kenya,
        dialCode: '254',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Kiribati',
        iso: CountryISO.Kiribati,
        dialCode: '686',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Kosovo',
        iso: CountryISO.Kosovo,
        dialCode: '383',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Kuwait (‫الكويت‬‎)',
        iso: CountryISO.Kuwait,
        dialCode: '965',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Kyrgyzstan (Кыргызстан)',
        iso: CountryISO.Kyrgyzstan,
        dialCode: '996',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Laos (ລາວ)',
        iso: CountryISO.Laos,
        dialCode: '856',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Latvia (Latvija)',
        iso: CountryISO.Latvia,
        dialCode: '371',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Lebanon (‫لبنان‬‎)',
        iso: CountryISO.Lebanon,
        dialCode: '961',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Lesotho',
        iso: CountryISO.Lesotho,
        dialCode: '266',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Liberia',
        iso: CountryISO.Liberia,
        dialCode: '231',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Libya (‫ليبيا‬‎)',
        iso: CountryISO.Libya,
        dialCode: '218',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Liechtenstein',
        iso: CountryISO.Liechtenstein,
        dialCode: '423',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Lithuania (Lietuva)',
        iso: CountryISO.Lithuania,
        dialCode: '370',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Luxembourg',
        iso: CountryISO.Luxembourg,
        dialCode: '352',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Macau (澳門)',
        iso: CountryISO.Macau,
        dialCode: '853',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Macedonia (FYROM) (Македонија)',
        iso: CountryISO.Macedonia,
        dialCode: '389',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Madagascar (Madagasikara)',
        iso: CountryISO.Madagascar,
        dialCode: '261',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Malawi',
        iso: CountryISO.Malawi,
        dialCode: '265',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Malaysia',
        iso: CountryISO.Malaysia,
        dialCode: '60',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Maldives',
        iso: CountryISO.Maldives,
        dialCode: '960',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Mali',
        iso: CountryISO.Mali,
        dialCode: '223',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Malta',
        iso: CountryISO.Malta,
        dialCode: '356',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Marshall Islands',
        iso: CountryISO.MarshallIslands,
        dialCode: '692',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Martinique',
        iso: CountryISO.Martinique,
        dialCode: '596',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Mauritania (‫موريتانيا‬‎)',
        iso: CountryISO.Mauritania,
        dialCode: '222',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Mauritius (Moris)',
        iso: CountryISO.Mauritius,
        dialCode: '230',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Mayotte',
        iso: CountryISO.Mayotte,
        dialCode: '262',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Mexico (México)',
        iso: CountryISO.Mexico,
        dialCode: '52',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Micronesia',
        iso: CountryISO.Micronesia,
        dialCode: '691',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Moldova (Republica Moldova)',
        iso: CountryISO.Moldova,
        dialCode: '373',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Monaco',
        iso: CountryISO.Monaco,
        dialCode: '377',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Mongolia (Монгол)',
        iso: CountryISO.Mongolia,
        dialCode: '976',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Montenegro (Crna Gora)',
        iso: CountryISO.Montenegro,
        dialCode: '382',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Montserrat',
        iso: 'ms',
        dialCode: '1',
        priority: 1,
        areaCodes: ['664'],
    },
    {
        title: 'Morocco (‫المغرب‬‎)',
        iso: CountryISO.Morocco,
        dialCode: '212',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Mozambique (Moçambique)',
        iso: CountryISO.Mozambique,
        dialCode: '258',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Myanmar (Burma) (မြန်မာ)',
        iso: CountryISO.Myanmar,
        dialCode: '95',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Namibia (Namibië)',
        iso: CountryISO.Namibia,
        dialCode: '264',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Nauru',
        iso: CountryISO.Nauru,
        dialCode: '674',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Nepal (नेपाल)',
        iso: CountryISO.Nepal,
        dialCode: '977',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Netherlands (Nederland)',
        iso: CountryISO.Netherlands,
        dialCode: '31',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'New Caledonia (Nouvelle-Calédonie)',
        iso: CountryISO.NewCaledonia,
        dialCode: '687',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'New Zealand',
        iso: CountryISO.NewZealand,
        dialCode: '64',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Nicaragua',
        iso: CountryISO.Nicaragua,
        dialCode: '505',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Niger (Nijar)',
        iso: CountryISO.Niger,
        dialCode: '227',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Nigeria',
        iso: CountryISO.Nigeria,
        dialCode: '234',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Niue',
        iso: CountryISO.Niue,
        dialCode: '683',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Norfolk Island',
        iso: CountryISO.NorfolkIsland,
        dialCode: '672',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'North Korea (조선 민주주의 인민 공화국)',
        iso: CountryISO.NorthKorea,
        dialCode: '850',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Northern Mariana Islands',
        iso: CountryISO.NorthernMarianaIslands,
        dialCode: '1670',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Norway (Norge)',
        iso: CountryISO.Norway,
        dialCode: '47',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Oman (‫عُمان‬‎)',
        iso: CountryISO.Oman,
        dialCode: '968',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Pakistan (‫پاکستان‬‎)',
        iso: CountryISO.Pakistan,
        dialCode: '92',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Palau',
        iso: CountryISO.Palau,
        dialCode: '680',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Palestine (‫فلسطين‬‎)',
        iso: CountryISO.Palestine,
        dialCode: '970',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Panama (Panamá)',
        iso: CountryISO.Panama,
        dialCode: '507',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Papua New Guinea',
        iso: CountryISO.PapuaNewGuinea,
        dialCode: '675',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Paraguay',
        iso: CountryISO.Paraguay,
        dialCode: '595',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Peru (Perú)',
        iso: CountryISO.Peru,
        dialCode: '51',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Philippines',
        iso: CountryISO.Philippines,
        dialCode: '63',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Poland (Polska)',
        iso: CountryISO.Poland,
        dialCode: '48',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Portugal',
        iso: CountryISO.Portugal,
        dialCode: '351',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Puerto Rico',
        iso: CountryISO.PuertoRico,
        dialCode: '1',
        priority: 3,
        areaCodes: ['787', '939'],
    },
    {
        title: 'Qatar (‫قطر‬‎)',
        iso: CountryISO.Qatar,
        dialCode: '974',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Réunion (La Réunion)',
        iso: CountryISO.Réunion,
        dialCode: '262',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Romania (România)',
        iso: CountryISO.Romania,
        dialCode: '40',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Russia (Россия)',
        iso: CountryISO.Russia,
        dialCode: '7',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Rwanda',
        iso: CountryISO.Rwanda,
        dialCode: '250',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Saint Barthélemy (Saint-Barthélemy)',
        iso: CountryISO.SaintBarthélemy,
        dialCode: '590',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Saint Helena',
        iso: CountryISO.SaintHelena,
        dialCode: '290',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Saint Kitts and Nevis',
        iso: CountryISO.SaintKittsAndNevis,
        dialCode: '1869',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Saint Lucia',
        iso: 'lc',
        dialCode: '1',
        priority: 1,
        areaCodes: ['758'],
    },
    {
        title: 'Saint Martin (Saint-Martin (partie française))',
        iso: CountryISO.SaintMartin,
        dialCode: '590',
        priority: 2,
        areaCodes: [],
    },
    {
        title: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
        iso: CountryISO.SaintPierreAndMiquelon,
        dialCode: '508',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Saint Vincent and the Grenadines',
        iso: 'vc',
        dialCode: '1',
        priority: 1,
        areaCodes: ['784'],
    },
    {
        title: 'Samoa',
        iso: CountryISO.Samoa,
        dialCode: '685',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'San Marino',
        iso: CountryISO.SanMarino,
        dialCode: '378',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'São Tomé and Príncipe (São Tomé e Príncipe)',
        iso: CountryISO.SãoToméAndPríncipe,
        dialCode: '239',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
        iso: CountryISO.SaudiArabia,
        dialCode: '966',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Senegal (Sénégal)',
        iso: CountryISO.Senegal,
        dialCode: '221',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Serbia (Србија)',
        iso: CountryISO.Serbia,
        dialCode: '381',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Seychelles',
        iso: CountryISO.Seychelles,
        dialCode: '248',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Sierra Leone',
        iso: CountryISO.SierraLeone,
        dialCode: '232',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Singapore',
        iso: CountryISO.Singapore,
        dialCode: '65',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Sint Maarten',
        iso: 'sx',
        dialCode: '1',
        priority: 1,
        areaCodes: ['721'],
    },
    {
        title: 'Slovakia (Slovensko)',
        iso: CountryISO.Slovakia,
        dialCode: '421',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Slovenia (Slovenija)',
        iso: CountryISO.Slovenia,
        dialCode: '386',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Solomon Islands',
        iso: CountryISO.SolomonIslands,
        dialCode: '677',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Somalia (Soomaaliya)',
        iso: CountryISO.Somalia,
        dialCode: '252',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'South Africa',
        iso: CountryISO.SouthAfrica,
        dialCode: '27',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'South Korea (대한민국)',
        iso: CountryISO.SouthKorea,
        dialCode: '82',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'South Sudan (‫جنوب السودان‬‎)',
        iso: CountryISO.SouthSudan,
        dialCode: '211',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Spain (España)',
        iso: CountryISO.Spain,
        dialCode: '34',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Sri Lanka (ශ්‍රී ලංකාව)',
        iso: CountryISO.SriLanka,
        dialCode: '94',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Sudan (‫السودان‬‎)',
        iso: CountryISO.Sudan,
        dialCode: '249',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Suriname',
        iso: CountryISO.Suriname,
        dialCode: '597',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Svalbard and Jan Mayen',
        iso: CountryISO.SvalbardAndJanMayen,
        dialCode: '47',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Swaziland',
        iso: CountryISO.Swaziland,
        dialCode: '268',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Sweden (Sverige)',
        iso: CountryISO.Sweden,
        dialCode: '46',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Switzerland (Schweiz)',
        iso: CountryISO.Switzerland,
        dialCode: '41',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Syria (‫سوريا‬‎)',
        iso: CountryISO.Syria,
        dialCode: '963',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Taiwan (台灣)',
        iso: CountryISO.Taiwan,
        dialCode: '886',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Tajikistan',
        iso: CountryISO.Tajikistan,
        dialCode: '992',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Tanzania',
        iso: CountryISO.Tanzania,
        dialCode: '255',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Thailand (ไทย)',
        iso: CountryISO.Thailand,
        dialCode: '66',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Timor-Leste',
        iso: CountryISO.TimorLeste,
        dialCode: '670',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Togo',
        iso: CountryISO.Togo,
        dialCode: '228',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Tokelau',
        iso: CountryISO.Tokelau,
        dialCode: '690',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Tonga',
        iso: CountryISO.Tonga,
        dialCode: '676',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Trinidad and Tobago',
        iso: 'tt',
        dialCode: '1',
        priority: 1,
        areaCodes: ['868'],
    },
    {
        title: 'Tunisia (‫تونس‬‎)',
        iso: CountryISO.Tunisia,
        dialCode: '216',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Turkey (Türkiye)',
        iso: CountryISO.Turkey,
        dialCode: '90',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Turkmenistan',
        iso: CountryISO.Turkmenistan,
        dialCode: '993',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Turks and Caicos Islands',
        iso: CountryISO.TurksAndCaicosIslands,
        dialCode: '1649',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Tuvalu',
        iso: CountryISO.Tuvalu,
        dialCode: '688',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'U.S. Virgin Islands',
        iso: 'vi',
        dialCode: '1',
        priority: 1,
        areaCodes: ['340'],
    },
    {
        title: 'Uganda',
        iso: CountryISO.Uganda,
        dialCode: '256',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Ukraine (Україна)',
        iso: CountryISO.Ukraine,
        dialCode: '380',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
        iso: CountryISO.UnitedArabEmirates,
        dialCode: '971',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'United Kingdom',
        iso: CountryISO.UnitedKingdom,
        dialCode: '44',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'United States',
        iso: CountryISO.UnitedStates,
        dialCode: '1',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Uruguay',
        iso: CountryISO.Uruguay,
        dialCode: '598',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Uzbekistan (Oʻzbekiston)',
        iso: CountryISO.Uzbekistan,
        dialCode: '998',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Vanuatu',
        iso: CountryISO.Vanuatu,
        dialCode: '678',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Vatican City (Città del Vaticano)',
        iso: CountryISO.VaticanCity,
        dialCode: '39',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Venezuela',
        iso: CountryISO.Venezuela,
        dialCode: '58',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Vietnam (Việt Nam)',
        iso: CountryISO.Vietnam,
        dialCode: '84',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Wallis and Futuna',
        iso: CountryISO.WallisAndFutuna,
        dialCode: '681',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Western Sahara (‫الصحراء الغربية‬‎)',
        iso: CountryISO.WesternSahara,
        dialCode: '212',
        priority: 1,
        areaCodes: [],
    },
    {
        title: 'Yemen (‫اليمن‬‎)',
        iso: CountryISO.Yemen,
        dialCode: '967',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Zambia',
        iso: CountryISO.Zambia,
        dialCode: '260',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Zimbabwe',
        iso: CountryISO.Zimbabwe,
        dialCode: '263',
        priority: 0,
        areaCodes: [],
    },
    {
        title: 'Åland Islands',
        iso: CountryISO.ÅlandIslands,
        dialCode: '358',
        priority: 1,
        areaCodes: [],
    },
];
