import { ComponentRef, Injectable, TemplateRef, Type } from '@angular/core';

import { Observable } from 'rxjs';
import { ViewContainerService } from '@passbot/angular/common';
import { IModalOptions } from './modal.interfaces';
import { ModalComponent } from './modal/modal.component';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private modalInstance: ComponentRef<ModalComponent>;
    private destroyTimeout: number;

    constructor(private readonly viewContainerService: ViewContainerService) {}

    public show<O = unknown, R = unknown>(
        componentOrTemplateRef: Type<unknown> | TemplateRef<unknown> | string,
        options: IModalOptions<O> = {},
    ): Observable<R> {
        if (this.destroyTimeout) {
            clearTimeout(this.destroyTimeout);
        }

        this.modalInstance = this.viewContainerService.attachComponentRef<ModalComponent>(ModalComponent, {
            childComponent: componentOrTemplateRef instanceof Type ? componentOrTemplateRef : undefined,
            template: componentOrTemplateRef instanceof TemplateRef ? componentOrTemplateRef : undefined,
            html: typeof componentOrTemplateRef === 'string' ? componentOrTemplateRef : undefined,
            ...options,
        });

        this.modalInstance.instance.onClose().subscribe(this.detachModal.bind(this));

        return this.modalInstance.instance.onClose<R>();
    }

    private detachModal() {
        // in timeout to allow for close animation to complete
        this.destroyTimeout = setTimeout(() => {
            this.viewContainerService.destroyComponentRef(this.modalInstance);
        }, 300) as unknown as number;
    }
}
