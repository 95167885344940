import { ApplicationRef, ComponentRef, EmbeddedViewRef, Injectable, Type, ViewContainerRef } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ViewContainerService {
    private root: ViewContainerRef;

    constructor(private readonly applicationRef: ApplicationRef) {}

    public setRoot(root: ViewContainerRef) {
        this.root = root;
    }

    public getRoot() {
        return this.root;
    }

    public attachComponentRef<T>(componentClass: Type<T>, options: Record<string, unknown> = {}) {
        const componentRef = this.root.createComponent<T>(componentClass);
        const componentRootNode = this.getComponentRootNode(componentRef);

        // project the options passed to the component instance
        this.projectComponentInputs(componentRef, options);

        componentRef.onDestroy(() => {
            this.applicationRef.detachView(componentRef.hostView);
        });

        this.getRootViewContainer().appendChild(componentRootNode);

        return componentRef;
    }

    public destroyComponentRef(componentRef: ComponentRef<any>) {
        this.applicationRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }

    private getComponentRootNode(componentRef: ComponentRef<unknown>): HTMLElement {
        // eslint-disable-next-line
        return (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0];
    }

    private projectComponentInputs(component: ComponentRef<any>, options: Record<string, unknown>): ComponentRef<unknown> {
        if (options) {
            const props = Object.getOwnPropertyNames(options);
            for (const prop of props) {
                // eslint-disable-next-line no-param-reassign
                component.instance[prop] = options[prop];
            }
        }

        return component;
    }

    private getRootViewContainer(): HTMLElement {
        // eslint-disable-next-line
        return (this.applicationRef.components[0].hostView as EmbeddedViewRef<unknown>).rootNodes[0];
    }
}
