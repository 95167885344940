import { IUser, StoreActions } from '@passbot/shared';
import { createAction, props } from '@ngrx/store';

export const loadUsers = createAction(StoreActions.Users.loadUsers);
export const loadUsersSuccess = createAction(StoreActions.Users.loadUsersSuccess, props<{ users: IUser[] }>());
export const loadUsersError = createAction(StoreActions.Users.loadUsersError, props<{ msg: string }>());
export const createUser = createAction(StoreActions.Users.createUser, props<Partial<IUser>>());
export const createUserSuccess = createAction(StoreActions.Users.createUserSuccess, props<IUser>());

export const createUserError = createAction(StoreActions.Users.createUserError, props<{ msg: string }>());
export const updateUser = createAction(StoreActions.Users.updateUser, props<IUser>());

export const updateUserSuccess = createAction(StoreActions.Users.updateUserSuccess, props<IUser>());
export const updateUserError = createAction(StoreActions.Users.updateUserError, props<{ msg: string }>());
export const removeUser = createAction(StoreActions.Users.removeUser, props<{ userId: IUser['id'] }>());

export const removeUserSuccess = createAction(StoreActions.Users.removeUserSuccess, props<{ userId: IUser['id'] }>());
export const removeUserError = createAction(StoreActions.Users.removeUserError, props<{ msg: string }>());
