import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { firstValueFrom, Subject, take, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { UserFacade } from '@passbot/angular/auth';
import { addDeviceSuccess, DevicesFacade } from '../../../+state';

@Component({
    selector: 'passbot-add-mobile-device',
    templateUrl: './add-mobile-device.component.html',
})
export class AddMobileDeviceComponent implements OnDestroy, OnInit {
    public isProcessing$ = this.devicesFacade.isProcessing$;
    public destroyed$ = new Subject<void>();
    public complete = false;

    public mobileForm = new FormGroup({
        mobileNumber: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        private readonly devicesFacade: DevicesFacade,
        private readonly actions$: Actions,
        private readonly toastr: ToastrService,
        private readonly userFacade: UserFacade,
    ) {}

    public async ngOnInit() {
        const user = await firstValueFrom(this.userFacade.getUser$);
        if (user.mobile) {
            this.mobileForm.controls.mobileNumber.setValue(user.mobile);
        }
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public close() {
        if (this.modal) {
            this.modal.close();
        }
    }

    public addMobile() {
        if (this.mobileForm.invalid) {
            return;
        }

        const mobileNumber = this.mobileForm.controls.mobileNumber.value?.replace(/ /g, '') as string;
        // do through store and listen for progress observable
        this.devicesFacade.addDevice('mobile', mobileNumber, this.mobileForm.controls.name.value!);
        this.actions$.pipe(ofType(addDeviceSuccess), take(1), takeUntil(this.destroyed$)).subscribe(() => {
            this.toastr.success('Mobile authentication device added');
            this.modal.close();
        });
    }
}
