import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { ICredential, ICredentialGroup, IUserPermission, PartialWithId } from '@passbot/shared';
import { map, of, tap } from 'rxjs';
import { APIService } from '@passbot/angular/api';
import { Store } from '@ngrx/store';
import {
    createCredentialGroup,
    createCredentialGroupError,
    createCredentialGroupSuccess,
    deleteCredentialGroup,
    fetchCredentialGroup,
    fetchCredentialGroupError,
    fetchCredentialGroupSuccess,
    updateCredentialGroupPermissions,
    updateCredentialGroupPermissionsError,
    updateCredentialGroupPermissionsSuccess,
    loadCredentials,
    loadCredentialsError,
    loadCredentialsSuccess,
    deleteCredentialGroupSuccess,
    deleteCredentialGroupError,
    updateCredentialGroup,
    updateCredentialGroupSuccess,
    updateCredentialGroupError,
} from './credential-groups.actions';

@Injectable()
export class CredentialGroupsEffects {
    public createCredentialGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createCredentialGroup),
            fetch({
                // provides an action
                run: (credential) => {
                    return this.apiService
                        .post<ICredentialGroup>('/credential-group', { name: credential.name })
                        .pipe(map((credentialGroup) => createCredentialGroupSuccess(credentialGroup)));
                },

                onError: (err) => {
                    return of(createCredentialGroupError({ msg: 'Failed to load user devices' }));
                },
            }),
        ),
    );

    public loadCredentials$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCredentials),
            fetch({
                run: () =>
                    this.apiService
                        .get<ICredentialGroup[]>('/credential-group')
                        .pipe(map((credentials) => loadCredentialsSuccess({ items: credentials }))),
                onError: (err) => of(loadCredentialsError({ msg: 'Failed to load credentials' })),
            }),
        ),
    );

    public fetchCredentialGroup = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCredentialGroup),
            fetch({
                run: (props) =>
                    this.apiService
                        .get<ICredentialGroup>(`/credential-group/${props.id}`)
                        .pipe(map((response) => fetchCredentialGroupSuccess(response))),
                onError: (err) => of(fetchCredentialGroupError({ msg: 'Failed to delete credential' })),
            }),
        ),
    );

    public updateCredentialGroupPermissions = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCredentialGroupPermissions),
            fetch({
                run: (props) =>
                    this.apiService.put<IUserPermission[]>(`/credential-group/${props.id}/permissions`, props.permissions).pipe(
                        tap(() => this.store.dispatch(fetchCredentialGroup({ id: props.id }))),
                        map((response) => updateCredentialGroupPermissionsSuccess({ id: props.id, permissions: response })),
                    ),
                onError: (err) => of(updateCredentialGroupPermissionsError({ msg: 'failed to update group permissions' })),
            }),
        ),
    );

    public deleteCredentialGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteCredentialGroup),
            fetch({
                run: ({ credentialGroupId, cascadeDelete }) =>
                    this.apiService
                        .delete<ICredential>(cascadeDelete ? `/credential-group/full/${credentialGroupId}` : `/credential-group/${credentialGroupId}`)
                        .pipe(map((credential) => deleteCredentialGroupSuccess({ credentialGroupId }))),
                onError: (err) => of(deleteCredentialGroupError({ msg: 'Failed to delete credential group' })),
            }),
        ),
    );

    public updateCredentialGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCredentialGroup),
            fetch({
                run: ({ credentialGroup }) =>
                    this.apiService
                        .put<PartialWithId<ICredentialGroup>>(`/credential-group/${credentialGroup.id}`, credentialGroup)
                        .pipe(map((credGroup) => updateCredentialGroupSuccess({ credentialGroup: credGroup }))),
                onError: (err) => of(updateCredentialGroupError({ msg: 'Failed to update credential group' })),
            }),
        ),
    );

    constructor(private readonly actions$: Actions, private readonly apiService: APIService, private readonly store: Store) {}
}
