import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[passbotTogglePassword]',
})
export class TogglePasswordDirective {
    private readonly showHtml = '<i class="fa-light fa-eye text-white/50 hover:text-white absolute right-4 top-2 cursor-pointer"></i>';
    private readonly hideHtml = '<i class="fa-light fa-eye-slash text-white/50 hover:text-white absolute right-4 top-2 cursor-pointer"></i>';

    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {
        this.init();
    }

    public init() {
        // wrap
        const div = this.renderer.createElement('div');
        div.classList.add('relative');
        const el = this.el.nativeElement;
        const parent = el.parentNode;
        this.renderer.insertBefore(parent, div, el);
        this.renderer.appendChild(div, el);

        // create eye span
        const span = document.createElement('span');
        span.innerHTML = this.showHtml;
        span.addEventListener('click', () => {
            this.toggle(span);
        });
        this.renderer.appendChild(div, span);
    }

    private toggle(span: Element) {
        const type = this.el.nativeElement.getAttribute('type');

        if (type === 'password') {
            this.el.nativeElement.setAttribute('type', 'text');
            span.innerHTML = this.hideHtml;
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            span.innerHTML = this.showHtml;
        }
    }
}
