const Allowed = {
    Uppers: 'QWERTYUIOPASDFGHJKLZXCVBNM',
    Lowers: 'qwertyuiopasdfghjklzxcvbnm',
    Numbers: '1234567890',
    Symbols: '!@#$%^&*',
};

const getRandomCharFromString = (str: string, crypto: any) => {
    return str.charAt(Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] % str.length));
};

/**
 * the generated password will be @param length, which default to 8,
 * and will have at least one upper, one lower, one number and one symbol
 * @param {number} length - password's length
 * @returns a generated password
 */
export const generateSecurePassword = (crypto: any, length = 10) => {
    let pwd = '';
    pwd += getRandomCharFromString(Allowed.Uppers, crypto); // pwd will have at least one upper
    pwd += getRandomCharFromString(Allowed.Lowers, crypto); // pwd will have at least one lower
    pwd += getRandomCharFromString(Allowed.Numbers, crypto); // pwd will have at least one number
    pwd += getRandomCharFromString(Allowed.Symbols, crypto); // pwd will have at least one symbol
    for (let i = pwd.length; i < length; i++) pwd += getRandomCharFromString(Object.values(Allowed).join(''), crypto); // fill the rest of the pwd with random characters
    return pwd
        .split('')
        .sort(() => 128 - crypto.getRandomValues(new Uint8Array(1))[0])
        .join('');
};
