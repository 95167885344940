import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocketIoModule } from 'ngx-socket-io';
import { ConfigModule } from '@passbot/angular/config';
import { CookieModule } from 'ngx-cookie';
import { SocketConnectionService } from './services/socket-connection.service';
import { SocketIoService } from './services';

@NgModule({
    imports: [CommonModule, ConfigModule, SocketIoModule, CookieModule.withOptions()],
    providers: [SocketConnectionService, SocketIoService],
})
export class AngularSocketIoModule {}
