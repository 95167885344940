import { createReducer, on } from '@ngrx/store';
import { ICredentialGroup } from '@passbot/shared';
import { createEntityAdapter } from '@ngrx/entity';
import { entityErrorFn, entityStartProcessing, entityStopProcessing, getDefaultEntityState, IEntityState } from '@passbot/angular/common';
import omit from 'lodash-es/omit';
import {
    createCredentialGroup,
    createCredentialGroupError,
    createCredentialGroupSuccess,
    deleteCredentialGroup,
    deleteCredentialGroupError,
    deleteCredentialGroupSuccess,
    fetchCredentialGroup,
    fetchCredentialGroupSuccess,
    loadCredentials,
    loadCredentialsError,
    loadCredentialsSuccess,
    updateCredentialGroup,
    updateCredentialGroupError,
    updateCredentialGroupPermissions,
    updateCredentialGroupPermissionsError,
    updateCredentialGroupPermissionsSuccess,
    updateCredentialGroupSuccess,
} from './credential-groups.actions';

export const CREDENTIAL_GROUPS_FEATURE_KEY = 'credentials-groups';

export const adapter = createEntityAdapter<ICredentialGroup>();

export type CredentialGroupsState = IEntityState<ICredentialGroup>;

export const initialState: CredentialGroupsState = getDefaultEntityState(adapter);

export const CredentialGroupsReducers = createReducer(
    initialState,
    on(loadCredentials, (state) => entityStartProcessing(state)),
    on(loadCredentialsSuccess, (state, credentials) => {
        let updatedState = state;
        credentials.items.forEach((credGroup) => {
            const credentialGroup = {
                ...omit(credGroup, 'credentials'),
                credentials: (credGroup.credentials || []).map((cred) => ({ id: cred.id })),
            } as ICredentialGroup;
            updatedState = adapter.upsertOne(credentialGroup, updatedState);
        });
        return entityStopProcessing(updatedState);
    }),
    on(loadCredentialsError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),

    on(createCredentialGroup, (state) => entityStartProcessing(state)),
    on(createCredentialGroupSuccess, (state, group) => entityStopProcessing(adapter.upsertOne({ ...group, credentials: [] }, state))),
    on(createCredentialGroupError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),

    on(deleteCredentialGroup, (state) => entityStartProcessing(state)),
    on(deleteCredentialGroupSuccess, (state, { credentialGroupId }) => {
        // console.log('deleting', credentialGroupId);
        return entityStopProcessing(adapter.removeOne(credentialGroupId, state));
    }),
    on(deleteCredentialGroupError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),

    on(fetchCredentialGroup, (state) => entityStartProcessing(state)),
    on(fetchCredentialGroupSuccess, (state, credentialGroup) => entityStopProcessing(adapter.upsertOne(credentialGroup, state))),
    on(updateCredentialGroupPermissions, (state) => entityStartProcessing(state)),
    on(updateCredentialGroupPermissionsSuccess, (state) => entityStartProcessing(state)),
    on(updateCredentialGroupPermissionsError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),

    on(updateCredentialGroup, (state) => entityStartProcessing(state)),
    on(updateCredentialGroupSuccess, (state, { credentialGroup }) => {
        return entityStopProcessing(adapter.updateOne({ id: credentialGroup.id, changes: credentialGroup }, state));
    }),
    on(updateCredentialGroupError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),
);
