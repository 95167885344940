import { Injectable } from '@angular/core';

@Injectable()
export class TenantService {

    private readonly ignoreHostPrefixes = [
        'www',
        'passbot',
        'dev',
    ];

    public getTenant() {
        const hostPrefix = window.location.hostname.split('.')[0].toLowerCase();

        if (!this.ignoreHostPrefixes.includes(hostPrefix)) {
            return hostPrefix;
        }

        return null;
    }
}