import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Observable, ReplaySubject, switchMap, tap } from 'rxjs';

import { APIService } from '@passbot/angular/api';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { IBillingSeats } from '@passbot/shared';

@Component({
    selector: 'passbot-adjust-seats',
    templateUrl: './adjust-seats-modal.component.html',
})
export class AdjustSeatsModalComponent implements AfterViewInit, OnInit {
    public numSeats: number;
    public inUse: number;
    public isProcessing = false;
    public gettingUpdateStatus = false;
    public updateStatus$: Observable<any>;
    public seats$ = new ReplaySubject<number>();
    public origNumSeats: number;
    public showReason = false;
    public seatInfo: IBillingSeats;

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) private readonly data: { showReason: boolean },
        private readonly api: APIService,
        private readonly toastr: ToastrService,
    ) {
        this.showReason = this.data?.showReason;
    }

    public async ngOnInit() {
        this.seatInfo = await this.api.getAsync<IBillingSeats>('/billing/seats');
        this.inUse = this.seatInfo.active;
        this.numSeats = this.seatInfo.pending || this.seatInfo.seats;
        this.origNumSeats = this.numSeats;
    }

    public ngAfterViewInit() {
        this.updateStatus$ = this.seats$.pipe(
            tap(() => (this.gettingUpdateStatus = true)),
            debounceTime(1000),
            switchMap((seats) => this.api.get(`/billing/get-usage-estimate/${seats}`)),
            tap(() => (this.gettingUpdateStatus = false)),
        );
    }

    public close() {
        this.modal.close(false);
    }

    public async updateSeats() {
        this.isProcessing = true;
        await this.api.putAsync('/billing/adjust-seats', { numSeats: this.numSeats });
        this.toastr.success('Number of seats successfully updated.');
        this.modal.close(true);
    }

    public checkUpdateStatus() {
        this.seats$.next(this.numSeats);
    }
}
