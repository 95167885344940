import { Component, Inject } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { ICredentialGroup, IUser, IUserPermission, Permissions } from '@passbot/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CredentialGroupsFacade } from '@passbot/angular/credentials';
import { Observable } from 'rxjs';
import { PermissionsFacade } from '../../+state';

@Component({
    selector: 'passbot-edit-permission',
    templateUrl: './edit-permission.modal.html',
})
export class EditPermissionModal {
    public permission: IUserPermission;
    public credentialGroup$: Observable<ICredentialGroup | undefined>;
    public user: IUser;
    public processing$ = this.permissionsFacade.isProcessing$;
    public Permissions = Permissions;
    public permissionForm = new FormGroup({
        access: new FormControl<Permissions>(Permissions.Read),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) private readonly data: { permission: IUserPermission; user: IUser },
        private readonly permissionsFacade: PermissionsFacade,
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly toastr: ToastrService,
    ) {
        this.permission = this.data.permission;
        this.user = this.data.user;
        this.credentialGroup$ = this.credentialGroupsFacade.getById$(this.permission.credentialGroup.id);
    }

    public ngOnInit() {
        this.permissionForm.controls.access.setValue(this.permission.permission);
    }

    public close() {
        this.modal.close();
    }

    public async save() {
        await this.permissionsFacade.updatePermissionAsync(
            { ...this.permission, permission: this.permissionForm.controls.access.value as Permissions },
            this.user.id,
        );
        this.toastr.success('Permission updated successfully');
        this.modal.close();
    }
}
