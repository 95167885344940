import { createReducer, on } from '@ngrx/store';
import { ICredential } from '@passbot/shared';
import { createEntityAdapter } from '@ngrx/entity';
import { entityErrorFn, entityStartProcessing, entityStopProcessing, getDefaultEntityState, IEntityState } from '@passbot/angular/common';
import { deleteCredentialGroupSuccess, loadCredentialsSuccess } from '../credential-groups';
import {
    createCredential,
    createCredentialError,
    createCredentialSuccess,
    deleteCredential,
    deleteCredentialError,
    deleteCredentialSuccess,
    setCredentialViewed,
    updateCredential,
    updateCredentialError,
    updateCredentialSuccess,
} from './credentials.actions';

export const CREDENTIALS_FEATURE_KEY = 'credentials';

export const adapter = createEntityAdapter<ICredential>();

export type CredentialsState = IEntityState<ICredential> & { viewed: string | undefined };

export const initialState: CredentialsState = { ...getDefaultEntityState(adapter), viewed: undefined };

export const CredentialsReducers = createReducer(
    initialState,
    on(loadCredentialsSuccess, (state, credentials) => {
        let updatedState = adapter.removeAll(state);
        credentials.items.forEach((credGroup) => {
            (credGroup.credentials || []).forEach((credential) => {
                const cred = updatedState.entities[credential.id];
                updatedState = adapter.upsertOne(
                    { ...(credential as ICredential), credentialGroups: [{ id: credGroup.id }, ...(cred?.credentialGroups || [])] },
                    updatedState,
                );
            });
        });
        return entityStopProcessing(updatedState);
    }),
    on(createCredential, (state) => entityStartProcessing(state)),
    on(createCredentialSuccess, (state, { credential }) => {
        return entityStopProcessing(adapter.addOne(credential, state));
    }),
    on(createCredentialError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),
    on(updateCredential, (state) => entityStartProcessing(state)),
    on(updateCredentialSuccess, (state, { credential }) => entityStopProcessing(adapter.upsertOne(credential, state))),
    on(updateCredentialError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),
    on(deleteCredential, (state) => entityStartProcessing(state)),
    on(deleteCredentialSuccess, (state, props) => {
        if (props.cascade) {
            return entityStopProcessing(adapter.removeOne(props.id, state));
        }

        const credential = state.entities[props.id];

        if (!credential) {
            return entityStopProcessing(state);
        }

        return entityStopProcessing(
            adapter.updateOne(
                { id: props.id, changes: { credentialGroups: credential!.credentialGroups!.filter((cg) => cg.id !== props.groupId) } },
                state,
            ),
        );
    }),
    on(deleteCredentialError, (state, error) => entityStopProcessing({ ...state, error: error.msg })),
    on(deleteCredentialGroupSuccess, (state, { credentialGroupId }) => {
        let updatedState = state;
        Object.values(state.entities).forEach((cred) => {
            if (!cred) {
                return;
            }

            const groupIndex = (cred.credentialGroups || []).findIndex((g) => g.id === credentialGroupId);
            if (groupIndex >= 0) {
                if (cred.credentialGroups!.length === 1) {
                    updatedState = adapter.removeOne(cred.id, updatedState);
                    return;
                }

                updatedState = adapter.updateOne(
                    { id: cred.id, changes: { credentialGroups: cred.credentialGroups!.filter((g) => g.id !== credentialGroupId) } },
                    state,
                );
            }
        });

        return updatedState;
    }),
    on(setCredentialViewed, (state, { credentialId }) => {
        return { ...state, viewed: credentialId };
    }),
);
