<div class="flex flex-col h-full">
    <div class="flex relative items-center justify-center space-x-4 mb-8 mt-4">
        <div><img class="inline-block h-14 w-auto" src="/assets/images/passbot-logo.svg"></div>
        <div>
            <div class="text-brand font-semibold">PASSBOT.IO</div>
            <div class="text-white font-semibold">{{ tenant.displayName }}</div>
        </div>
    </div>

    <div class="relative" *ngIf="!found">
        <div id="scanFrame" #scanFrame class="w-full"></div>
    </div>
    <div *ngIf="!found && cameras?.length || 0 > 1" class="mt-4 text-center">
        <i class="fa-light fa-camera-rotate text-brand text-4xl" (click)="switchCamera()"></i>
    </div>
    <div *ngIf="found" class="text-center flex-1 items-center flex justify-center">
        <span class="text-white/60 font-semibold">Great! 2FA code scanned, you can now <br/><a (click)="closeWindow()" class="text-brand">close this window.</a></span>
    </div>
</div>
