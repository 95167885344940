<div class="h-full flex flex-col justify-center items-center">
<ng-container *ngIf="!error && !complete">
    <div class="text-center mb-4">
        <i class="fa-regular fa-spinner fa-spin-pulse app-preloader text-7xl text-white"></i>
    </div>
    <div class="text-center text-white">
        Launching Authenticator
    </div>
</ng-container>
<ng-container *ngIf="error">
    <div class="text-center text-white mx-6 space-y-4">
        <div class="mx-6 p-6 border border-red-400 text-red-500">{{ error }}</div>
        <div>You can now close this window and return to the app.</div>

        <div class="btn rounded-lg border border-white/30 bg-indigo-400 text-white p-4 text-center" (click)="closeWindow()">
            CLOSE WINDOW
        </div>
    </div>
</ng-container>
<ng-container *ngIf="complete">
    <div class="text-center text-white mx-6 space-y-4">
        <div class="font-semibold">Authorisation successful.</div>
        <div>You can now close this window and return to the app.</div>

        <div class="btn rounded-lg border border-white/30 bg-indigo-400 text-white p-4 text-center" (click)="closeWindow()">
            CLOSE WINDOW
        </div>
    </div>
</ng-container>
</div>
