import { Component, OnInit } from '@angular/core';
import { firstValueFrom, tap } from 'rxjs';

import { APIService } from '@passbot/angular/api';
import { UserFacade } from '@passbot/angular/auth';
import { AdjustSeatsModalComponent } from '@passbot/angular/billing';
import { DeleteItemModalComponent, IDeleteModalData, MessageModalComponent } from '@passbot/angular/common';
import { ModalService } from '@passbot/angular/modal';
import { IBillingSeats, IUser, UserStatus } from '@passbot/shared';

import { ActivatedRoute } from '@angular/router';
import { UsersFacade } from '../../+state/users';
import { AddNewUserComponent } from '../add-new-user/add-new-user.component';
import { PromoteUserComponent } from '../promote-user/promote-user.component';

@Component({
    selector: 'passbot-permissions',
    templateUrl: './index.component.html',
})
export class IndexComponent implements OnInit {
    public users$ = this.permissionsFacade.usersAlphabetically$.pipe(tap(() => this.updateSeats()));
    public isOpen: Record<string, boolean> = {};
    public loggedInUser: IUser;
    public highlight: string;
    private seatsInfo: IBillingSeats;
    constructor(
        private readonly permissionsFacade: UsersFacade,
        private readonly userFacade: UserFacade,
        private readonly modal: ModalService,
        private readonly api: APIService,
        private readonly activatedRoute: ActivatedRoute,
    ) {}

    public async ngOnInit() {
        this.permissionsFacade.loadUsers();

        this.loggedInUser = await this.userFacade.getUser();

        await this.updateSeats();

        if (this.activatedRoute.snapshot.queryParams.uid) {
            this.highlightUser(this.activatedRoute.snapshot.queryParams.uid);
        }
    }

    public toggleOpen(user: IUser) {
        if (user.permissions?.length || 0 > 0) {
            this.isOpen[user.id] = !this.isOpen[user.id];
        }
    }

    public async addUser() {
        if (this.seatsInfo.inUse < this.seatsInfo.seats) {
            this.modal.show(AddNewUserComponent);
            return;
        }

        if (this.loggedInUser.billingAdmin) {
            const result = await firstValueFrom(this.modal.show(AdjustSeatsModalComponent, { data: { showReason: true } }));

            if (result) {
                setTimeout(async () => {
                    await this.updateSeats();

                    if (this.seatsInfo.inUse < this.seatsInfo.seats) {
                        this.modal.show(AddNewUserComponent);
                    }
                }, 500);

                return;
            }
        }

        this.modal.show(MessageModalComponent, {
            data: {
                title: 'Unable to add users',
                message:
                    "You cannot add a new user as your account is using all of it's allocated seats. Contact a billing admin to see if you can get your subscription updated",
            },
        });
    }

    public deleteUser(user: IUser, clickEvent: Event) {
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
        this.modal.show<IDeleteModalData>(DeleteItemModalComponent, {
            data: {
                itemName: user.name,
                itemType: 'User',
                processing$: this.permissionsFacade.isProcessing$,
                callback: () => {
                    return this.permissionsFacade.deleteUserAsync(user.id);
                },
            },
        });
    }

    public editUser(user: IUser, clickEvent: Event) {
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
        const originalStatus = user.status;
        this.modal.show<{ user?: IUser }, IUser>(AddNewUserComponent, { data: { user } }).subscribe((updatedUser: IUser) => {
            if (updatedUser.status === UserStatus.disabled && originalStatus === UserStatus.active) {
                setTimeout(async () => {
                    if (this.seatsInfo.temp) {
                        if (this.seatsInfo.temp === 1) {
                            await this.permissionsFacade.promoteUser();
                            return;
                        }

                        this.modal.show(PromoteUserComponent);
                    }
                }, 1000);
            }
        });
    }

    private async updateSeats() {
        this.seatsInfo = await this.api.getAsync<IBillingSeats>('/billing/seats');
    }

    private highlightUser(userId: string) {
        this.isOpen[userId] = true;
        this.highlight = userId;
    }
}
