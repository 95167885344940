import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserFacade } from '@passbot/angular/auth';
import { filter, map, switchMap, take } from 'rxjs';
import { ICredentialGroup, isDefined, PartialWithId, Permissions } from '@passbot/shared';
import { PermissionsFacade } from '../+state';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[needPermission]',
})
export class NeedPermissionDirective implements OnInit {
    @Input() public needPermission: {
        permission: Permissions | 'TenantAdmin';
        groupIds?: string[];
        groupId?: string;
        groups?: PartialWithId<ICredentialGroup>[];
    };

    private readonly roleLevelMap = {
        [Permissions.Read]: 0,
        [Permissions.Write]: 1,
        [Permissions.Admin]: 2,
        TenantAdmin: 3,
    };

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly userFacade: UserFacade,
        private readonly permissionsFacade: PermissionsFacade,
    ) {}

    public ngOnInit(): void {
        const groups = this.needPermission.groupId
            ? [this.needPermission.groupId]
            : this.needPermission.groups
            ? this.needPermission.groups.map((g) => g.id)
            : this.needPermission.groupIds;

        if (!groups) {
            this.viewContainer.clear();
            return;
        }

        this.userFacade.getUser$
            .pipe(
                filter(isDefined),
                switchMap((user) =>
                    this.permissionsFacade.getByUserIdAndGroupIds$(user.id, groups).pipe(
                        take(1),
                        map((permissions) => ({ user, permissions })),
                    ),
                ),
            )
            .subscribe(({ user, permissions }) => {
                let hasAccess = user.tenantAdmin;

                if (!hasAccess) {
                    permissions.some((perm) => {
                        if (this.roleLevelMap[this.needPermission.permission] >= this.roleLevelMap[perm.permission]) {
                            hasAccess = true;
                            return true;
                        }

                        return false;
                    });
                }

                if (hasAccess) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    return;
                }

                this.viewContainer.clear();
            });
    }
}
