import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { ICredentialGroup } from '@passbot/shared';
import { PermissionsFacade } from '@passbot/angular/permissions';
import { take } from 'rxjs';

@Pipe({ name: 'permissionsCount', pure: false })
export class PermissionsCountPipe implements PipeTransform {
    public value?: number;
    constructor(private readonly permissionsFacade: PermissionsFacade, private readonly cdr: ChangeDetectorRef) {}

    public transform(credentialGroup: ICredentialGroup): number {
        if (!this.value) {
            this.value = 0;
            this.permissionsFacade
                .getByGroupId$(credentialGroup.id)
                .pipe(take(1))
                .subscribe((permissions) => {
                    this.value = permissions.length;
                    this.cdr.markForCheck();
                });

            return this.value;
        }

        return this.value;
    }
}
