import { Injectable } from '@angular/core';

@Injectable()
export class ScriptService {

    public loadJsScript(src: string, params: Record<string, string> = {}): HTMLScriptElement {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;

        Object.keys(params).forEach((key) => {
            script.setAttribute(key, params[key]);
        });

        document.getElementsByTagName('head')[0].appendChild(script);
        return script;
    }
}
