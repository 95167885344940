<div class="h-full flex flex-col p-4 py-8 lg:p-8" *ngIf="credentialGroup$ | async as credentialGroup">
    <div class="flex items-center" *ngIf="credentialGroup">
        <div class="flex-1 flex space-x-4">
            <div class="flex flex-col justify-center">
                <i class="fa-light fa-key text-brand text-5xl"></i>
            </div>
            <div class="flex flex-col justify-center relative">
                <h2 class="text-brand text-2xl font-semibold capitalize">{{ credentialGroup?.name }}</h2>
                <div class="text-white/70 text-xs group" *needPermission="{ permission: 'TenantAdmin', groupId: credentialGroup.id }">
                    ACTIONS <i class="fa-regular fa-chevron-down font-normal"></i>
                    <div class="bg-slate-950 rounded absolute top-[100%] w-[150px] text-sm h-0 group-hover:h-auto overflow-hidden cursor-pointer">
                        <div (click)="renameGroup(credentialGroup)" class="text-white p-2 hover:bg-brand hover:text-white">
                            <i class="fa-regular fa-edit"></i> Rename Vault
                        </div>
                        <div (click)="deleteGroup(credentialGroup)" class="text-red-600 p-2 hover:bg-red-600 hover:text-white">
                            <i class="fa-regular fa-trash"></i> Delete Vault
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div *needPermission="{ groupId: credentialGroup.id, permission: writePermission }" class="flex justify-end">
            <div *ngFor="let user of users$ | async | passbotAlphabeticalSort:'name'" [attr.data-tooltip]="user.name + (user.tenantAdmin ? ' (Admin)' : '')"  class="-ml-1 transition-all hover:mx-0 hover:pl-2 px-0 hover:z-4">
                <img class="w-10 h-10 rounded-full" [src]="user.avatarUrl" [ngClass]="{'border-2 border-brand': user.tenantAdmin }"/>
            </div>
            <!--            <div (click)="addUser()" class="w-10 h-10 rounded-full bg-brand flex justify-center items-center cursor-pointer" [attr.data-tooltip]="'Add User'">-->
            <!--                <i class="fa-regularfa-plus"></i>-->
            <!--            </div>-->
            <div class="flex flex-col border-l border-brand pl-2 ml-2">
                <div class="text-sm text-gray-500">{{ credentialGroup.userPermissions?.length }} Users Added</div>
                <div class="text-sm text-brand cursor-pointer" (click)="manageUsers(credentialGroup)">Manage Users</div>
            </div>
<!--            <div *ngIf="user.tenantAdmin" class="flex flex-col border-l border-brand pl-2 ml-2 items-center justify-center">-->
<!--                <i class="fa-regular fa-trash text-red-700"></i>-->
<!--            </div>-->
        </div>
    </div>


    <div class="flex flex-1 pt-10 flex-col overflow-hidden" *ngIf="credentialGroup">
        <div class="text-white text-2xl opacity-50" *ngIf="notFound">Invalid credential group or no access</div>

        <div class="rounded-lg bg-slate-900/60 border border-gray-700 text-white overflow-hidden flex flex-col">
            <div class="bg-slate-900 grid grid-cols-4 md:grid-cols-5 text-xs text-white/50 text-center p-4 transition-all">
                <div class="text-left col-span-2 md:col-span-2 xl:col-span-1">Service</div>
                <div class="hidden md:block">URL</div>
                <div class="hidden xl:block">Username</div>
                <div>2FA Enabled?</div>
                <div class="text-right">Actions</div>
            </div>
            <div class="flex-1  overflow-y-auto scrollbar-thumb-border-l-8 pr-2 scrollbar-thin scrollbar-thumb-slate-700 scrollbar-thumb-rounded-full scrollbar scrollbar-track-transparent">
                <ng-container *ngIf="credentials$ | async as credentials">
                    <div *ngFor="let credential of credentials | passbotAlphabeticalSort: 'name'; let i = index;"
                         class="p-4 last:rounded-b-lg grid grid-cols-4 md:grid-cols-5 items-center transition-all hover:bg-slate-950"
                         [ngClass]="{'bg-slate-900': (i%2)}"
                    >
                        <div class="flex space-x-2 items-center col-span-2 md:col-span-2 xl:col-span-1">
                            <passbot-credential-image [item]="credential"></passbot-credential-image>
                            <div class="flex flex-col justify-center">
                                <span class="flex-1 text-sm lg:text-base">{{ credential.name }}</span>
                                <div class="text-xs text-slate-400 xl:hidden">{{ credential.username }}</div>
                            </div>
                        </div>
                        <div class="text-center text-xs align-middle hidden md:block">
                            <ng-container *ngIf="credential.url">{{ credential.url }}</ng-container>
                            <ng-container *ngIf="!credential.url"><span class="text-white/20">Not Specified</span></ng-container>
                        </div>
                        <div class="text-center text-xs hidden xl:block">
                            {{ credential.username }}
                        </div>
                        <div class="text-center text-xs text-slate-50/60">
                            <span class="hidden md:inline-block" [ngClass]="{'text-green-500': credential.totpKey }">{{ credential.totpKey ? '2FA Enabled' : '2FA Disabled' }}</span>
                            <i class="inline-block md:hidden fa-regular" [ngClass]="{'text-green-500 fa-lock': credential.totpKey, 'text-red-500 fa-unlock': !credential.totpKey  }"></i>
                        </div>
                        <div class="text-right space-x-4">
                            <i [id]="'view-credential-' + credential.id" (click)="view(credential)" class="fa-regular fa-eye text-brand cursor-pointer" [attr.data-tooltip]="'View ' + credential.name + ' details'" data-tooltip-position="left"></i>
                            <i (click)="edit(credential)" class="fa-light fa-edit text-brand cursor-pointer" [attr.data-tooltip]="'Edit ' + credential.name"  data-tooltip-position="left" ></i>
                            <i (click)="delete(credential, credentialGroup.id)" class="fa-light fa-trash text-red-600 cursor-pointer hover:fa-regularsolid-hover" [attr.data-tooltip]="'Delete ' + credential.name" data-tooltip-position="left"></i>
                        </div>
                    </div>
                    <div class="text-5xl text-white/30 text-center py-8 font-semibold" *ngIf="credentials.length === 0" >
                        NO CREDENTIALS ADDED
                    </div>
                </ng-container>

            </div>

        </div>
    </div>

</div>
