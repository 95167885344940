import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs/operators';
import { APIService } from '@passbot/angular/api';
import { IUserAuthDevice } from '@passbot/shared';
import { of } from 'rxjs';
import {
    addDevice,
    addDeviceInProgess,
    loadDevices,
    loadDevicesError,
    loadDevicesSuccess,
    removeDevice,
    removeDeviceError,
    removeDeviceSuccess,
} from './devices.actions';

@Injectable()
export class DevicesEffects {
    public loadDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDevices),
            fetch({
                // provides an action
                run: () => {
                    return this.apiService.call<IUserAuthDevice[]>('/devices').pipe(map((devices) => loadDevicesSuccess({ items: devices })));
                },

                onError: (err) => {
                    return of(loadDevicesError({ msg: 'Failed to load user devices' }));
                },
            }),
        ),
    );

    public addDevice = createEffect(() =>
        this.actions$.pipe(
            ofType(addDevice),
            fetch({
                // provides an action
                run: (props) => {
                    return this.apiService.post<IUserAuthDevice[]>('/auth/webauthn/send-to-device', props).pipe(map(() => addDeviceInProgess()));
                },

                onError: (err) => {
                    return of(loadDevicesError({ msg: 'Failed to load user devices' }));
                },
            }),
        ),
    );

    public removeDevice = createEffect(() =>
        this.actions$.pipe(
            ofType(removeDevice),
            fetch({
                run: ({ item }) => {
                    return this.apiService.delete(`/devices/${item.id}`).pipe(map(() => removeDeviceSuccess({ item })));
                },

                onError: (err) => {
                    return of(removeDeviceError({ msg: 'Failed to remove device' }));
                },
            }),
        ),
    );

    constructor(private readonly actions$: Actions, private readonly apiService: APIService) {}
}
