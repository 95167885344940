import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantService } from './services/tenant.service';
import { TenantGuard } from './guards/tenant.guard';

@NgModule({
    imports: [CommonModule],
    providers: [TenantService, TenantGuard],
})
export class TenantModule {}
