import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { CredentialGroupsFacade } from '../+state';

@Pipe({ name: 'credentialGroupName', pure: false })
export class CredentialGroupNamePipe implements PipeTransform, OnDestroy {
    public value?: string;
    private subscription: Subscription;

    constructor(private readonly credentialsGroupsFacade: CredentialGroupsFacade, private readonly cdr: ChangeDetectorRef) {}

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public transform(id: string): string {
        if (!this.value) {
            this.value = '';
            this.subscription = this.credentialsGroupsFacade.getById$(id).subscribe((credentialGroup) => {
                this.value = credentialGroup?.name;
                this.cdr.markForCheck();
            });

            return this.value;
        }

        return this.value;
    }
}
