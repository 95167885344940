import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { commonRoutes } from './lib.routes';
import { ScriptService, TenantService } from './services';
import { RouterFacade } from './facades';
import { TogglePasswordDirective } from './directives/password-toggle.directive';
import { AlphabeticalSortPipe, MaxMinPipe, PassbotCurrencyPipe, PrettyListPipe } from './pipes';
import {
    DeleteItemModalComponent,
    ModalComponent,
    PlatformComponent,
    PhoneInputComponent,
    CountrySelectorComponent,
    GroupAccessSelectorComponent,
    MessageModalComponent,
} from './components';
@NgModule({
    imports: [CommonModule, RouterModule.forChild(commonRoutes), FormsModule],
    declarations: [
        CountrySelectorComponent,
        PhoneInputComponent,
        PlatformComponent,
        ModalComponent,
        TogglePasswordDirective,
        PrettyListPipe,
        AlphabeticalSortPipe,
        DeleteItemModalComponent,
        PassbotCurrencyPipe,
        MaxMinPipe,
        MessageModalComponent,
        GroupAccessSelectorComponent,
    ],
    exports: [
        CountrySelectorComponent,
        PhoneInputComponent,
        PlatformComponent,
        ModalComponent,
        TogglePasswordDirective,
        PrettyListPipe,
        AlphabeticalSortPipe,
        DeleteItemModalComponent,
        PassbotCurrencyPipe,
        MaxMinPipe,
        MessageModalComponent,
        GroupAccessSelectorComponent,
    ],
    providers: [ScriptService, TenantService, RouterFacade],
})
export class PassbotCommonModule {}
