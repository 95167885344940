import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PassbotCommonModule } from '@passbot/angular/common';
import { CREDENTIALS_FEATURE_KEY, CredentialsReducers } from './+state/credentials/credentials.reducer';
import { CredentialsEffects } from './+state/credentials/credentials.effects';
import {
    CredentialsFacade,
    CREDENTIAL_GROUPS_FEATURE_KEY,
    CredentialGroupsEffects,
    CredentialGroupsFacade,
    CredentialGroupsReducers,
} from './+state';
import { AddCredentialModalComponent } from './components/add-credential-modal/add-credential-modal.component';
import { DeleteGroupsByCredentialModalComponent } from './components/delete-groups-by-credential-modal/delete-groups-by-credential-modal.component';
import { ViewCredentialModalComponent } from './components/view-credential-modal/view-credential-modal.component';
import { CredentialImageComponent } from './components/credential-image/credential-image.component';
import { CredentialsCountPipe, CredentialGroupNamePipe } from './pipes';

@NgModule({
    declarations: [
        AddCredentialModalComponent,
        DeleteGroupsByCredentialModalComponent,
        CredentialsCountPipe,
        ViewCredentialModalComponent,
        CredentialImageComponent,
        CredentialGroupNamePipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        PassbotCommonModule,
        StoreModule.forFeature(CREDENTIALS_FEATURE_KEY, CredentialsReducers),
        EffectsModule.forFeature([CredentialsEffects, CredentialGroupsEffects]),
        StoreModule.forFeature(CREDENTIAL_GROUPS_FEATURE_KEY, CredentialGroupsReducers),
    ],
    providers: [CredentialsFacade, CredentialGroupsFacade],
    exports: [AddCredentialModalComponent, CredentialsCountPipe, CredentialGroupNamePipe, ViewCredentialModalComponent, CredentialImageComponent],
})
export class CredentialsModule {}
