import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '@passbot/angular/api';
import { TenantService } from '@passbot/angular/tenant';
import { ITenant } from '@passbot/shared';
import { Html5Qrcode } from 'html5-qrcode';
import { CameraDevice } from 'html5-qrcode/src/camera/core';

@Component({
    selector: 'passbot-scan-2fa',
    templateUrl: './scan.component.html',
})
export class ScanComponent implements OnInit {
    public error: string;
    public uuid: string;
    public tenant: ITenant;
    public cameras: CameraDevice[];
    public found = false;
    private scanner: Html5Qrcode;
    private facingEnvironment = true;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly api: APIService,
        private readonly tenantService: TenantService,
        private readonly zone: NgZone,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    public async ngOnInit() {
        this.tenant = this.tenantService.getTenant() as ITenant;
        const { token } = this.route.snapshot.queryParams;
        if (!token) {
            this.error = 'Invalid token provided';
        }

        const response = await this.api.getAsync<{ meta: { uuid: string } }>(`/auth/token/${token}`);
        this.uuid = response.meta.uuid;
        this.initScanner();
    }

    public switchCamera() {
        this.facingEnvironment = !this.facingEnvironment;
        void this.startScanner();
    }

    public closeWindow() {
        window.close();
    }

    private initScanner() {
        void this.zone.runOutsideAngular(async () => {
            this.scanner = new Html5Qrcode('scanFrame');
            this.cameras = await Html5Qrcode.getCameras();
            await this.startScanner();
        });
    }

    private async startScanner() {
        if (this.scanner.isScanning) {
            await this.scanner.stop();
            this.scanner.clear();
        }
        void this.scanner.start(
            { facingMode: this.facingEnvironment ? 'environment' : 'user' },
            { fps: 10, qrbox: 250 },
            this.scanSuccess.bind(this),
            undefined,
        );
    }

    private scanSuccess(decodedText: string) {
        if (!this.found) {
            this.found = true;
            this.cdr.detectChanges();
            setTimeout(async () => {
                await this.api.postAsync('/credential/scanned-totp', { totp: decodedText });
                await this.scanner.stop();
                this.scanner.clear();
            });
        }
    }
}
