import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ICredentialGroup, IUserPermission, PartialWithId } from '@passbot/shared';
import { filter, firstValueFrom, map, merge, Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import {
    createCredentialGroup,
    createCredentialGroupError,
    createCredentialGroupSuccess,
    deleteCredentialGroup,
    deleteCredentialGroupError,
    deleteCredentialGroupSuccess,
    fetchCredentialGroup,
    fetchCredentialGroupError,
    fetchCredentialGroupSuccess,
    loadCredentials,
    loadCredentialsError,
    loadCredentialsSuccess,
    updateCredentialGroup,
    updateCredentialGroupError,
    updateCredentialGroupPermissions,
    updateCredentialGroupPermissionsError,
    updateCredentialGroupPermissionsSuccess,
    updateCredentialGroupSuccess,
} from './credential-groups.actions';
import { credentialGroupSelectors, getGroupsByCredentialId } from './credential-groups.selectors';

@Injectable()
export class CredentialGroupsFacade {
    constructor(private readonly store: Store, private readonly actions: Actions) {}

    public getAll$ = this.store.pipe(select(credentialGroupSelectors.getAllAlphabetically));
    public getById$ = (id: string) => this.store.pipe(select(credentialGroupSelectors.getById(id)));
    public isProcessing$ = this.store.pipe(select(credentialGroupSelectors.getIsProcessing));
    public getError$ = this.store.pipe(select(credentialGroupSelectors.getError));
    public awaiting2FASubject = new Subject<boolean | string>();
    public awaiting2FA$ = this.awaiting2FASubject.asObservable();
    public cancel2FA = new Subject<void>();
    public groupsByCredentialId$ = (credentialId: string) => this.store.pipe(select(getGroupsByCredentialId(credentialId)));
    public group$ = (groupId: string) => this.store.pipe(select(credentialGroupSelectors.getById(groupId)));
    public getFirst$ = this.store.pipe(select(credentialGroupSelectors.getFirst));
    public search$ = (searchStr: string) => this.store.pipe(select(credentialGroupSelectors.searchCredentialGroups(searchStr)));
    public haveVaults$ = this.store.pipe(
        select(credentialGroupSelectors.selectAll),
        map((vaults) => vaults.length > 0),
    );
    public hydratedGroup$ = (groupId: number) =>
        this.store.pipe(select(credentialGroupSelectors.getById(groupId))).pipe(filter((group) => !!group?.userPermissions));

    public loadCredentials() {
        this.store.dispatch(loadCredentials());
    }

    public loadCredentialsAsync() {
        this.store.dispatch(loadCredentials());

        const loadSuccess = this.actions.pipe(ofType(loadCredentialsSuccess));
        const loadError = this.actions.pipe(ofType(loadCredentialsError));

        return firstValueFrom(merge(loadSuccess, loadError));
    }

    public fetchCredentialGroup(id: string) {
        this.store.dispatch(fetchCredentialGroup({ id }));
    }

    public fetchCredentialGroupAsync(id: string) {
        this.fetchCredentialGroup(id);

        const fetchSuccess = this.actions.pipe(ofType(fetchCredentialGroupSuccess));
        const fetchError = this.actions.pipe(ofType(fetchCredentialGroupError));

        return firstValueFrom(merge(fetchSuccess, fetchError));
    }

    public groupsByCredentialIdAsync(credentialId: string) {
        return firstValueFrom(this.groupsByCredentialId$(credentialId));
    }

    public getByIdAsync(groupId: string) {
        return firstValueFrom(this.store.pipe(select(credentialGroupSelectors.getById(groupId))));
    }

    public async getGroupsToDelete(credentialId: string) {
        const groups = await firstValueFrom(this.store.pipe(select(getGroupsByCredentialId(credentialId))));
        return groups.filter((group) => group.credentials!.length === 1);
    }

    public deleteGroup(credentialGroupId: string, cascadeDelete = false) {
        this.store.dispatch(deleteCredentialGroup({ credentialGroupId, cascadeDelete }));
    }

    public deleteGroupAsync(credentialGroupId: string, cascadeDelete = false) {
        this.deleteGroup(credentialGroupId, cascadeDelete);

        const updateSuccess = this.actions.pipe(ofType(deleteCredentialGroupSuccess));
        const updateError = this.actions.pipe(ofType(deleteCredentialGroupError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }

    public updateCredentialGroupPermissionsAsync(credentialGroupId: string, permissions: IUserPermission[]) {
        this.store.dispatch(updateCredentialGroupPermissions({ id: credentialGroupId, permissions }));

        const updateSuccess = this.actions.pipe(ofType(updateCredentialGroupPermissionsSuccess));
        const updateError = this.actions.pipe(ofType(updateCredentialGroupPermissionsError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }

    public createAsync(name: string) {
        this.store.dispatch(createCredentialGroup({ name }));

        const createSuccess = this.actions.pipe(ofType(createCredentialGroupSuccess));
        const createError = this.actions.pipe(ofType(createCredentialGroupError));

        return firstValueFrom(merge(createSuccess, createError));
    }

    public updateCredentialGroup(credentialGroup: PartialWithId<ICredentialGroup>) {
        this.store.dispatch(updateCredentialGroup({ credentialGroup }));
    }

    public updateCredentialGroupAsync(credentialGroup: PartialWithId<ICredentialGroup>) {
        this.updateCredentialGroup(credentialGroup);

        const updateSuccess = this.actions.pipe(ofType(updateCredentialGroupSuccess));
        const updateError = this.actions.pipe(ofType(updateCredentialGroupError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }

    public onCreate() {
        return this.actions.pipe(ofType(createCredentialGroupSuccess));
    }
}
