<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="(isProcessing$ | async) === false">
    <h3 class="text-center font-semibold text-lg text-brand">Confirm Changes</h3>
    <div class="space-y-4">
        <p>Are you sure you want to update the access to this credential group?</p>
        <p>Summary of changes:</p>
        <div class="pl-8 space-y-2">
            <div *ngIf="data.changed.new > 0"><span class="text-green-600">{{ data.changed.new }}</span> users added.</div>
            <div *ngIf="data.changed.changed > 0"><span class="text-orange-600">{{ data.changed.changed }}</span> users access changed.</div>
            <div *ngIf="data.changed.deleted > 0"><span class="text-red-600">{{ data.changed.deleted }}</span> users deleted.</div>
        </div>
    </div>
    <div class="flex justify-between pt-4">
        <div (click)="cancel()" class="cursor-pointer inline-flex justify-center bg-slate-200 cursor-pointer hover:bg-slate-100 text-slate-800 py-2 px-4 border-b-4 border-white rounded-lg">
            Cancel
        </div>
        <button
                (click)="confirm()"
                class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
            Confirm
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>

