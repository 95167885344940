import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICredential, ICredentialGroup } from '@passbot/shared';

@Component({
    selector: 'passbot-credential-image',
    templateUrl: './credential-image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialImageComponent {
    @Input() public item: Partial<ICredential | ICredentialGroup>;
    public imageUrl: string;
    public firstLetter: string;

    public ngOnInit() {
        if ((this.item as ICredential).url) {
            this.imageUrl = `https://www.google.com/s2/favicons?sz=32&domain=${(this.item as ICredential).url}`;
        } else {
            this.firstLetter = (this.item.name || ' ')[0].toUpperCase();
        }
    }
}
