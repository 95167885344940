import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PassbotCommonModule } from '@passbot/angular/common';
import { ModalModule } from '@passbot/angular/modal';
import { PermissionsModule } from '@passbot/angular/permissions';
import { SidebarModule } from '@passbot/angular/sidebar';
import { credentialsRoutes } from './lib.routes';
import { CredentialsModule } from './credentials.module';
import { IndexComponent } from './components/index/index.component';
import { DeleteCredentialModalComponent } from './components/delete-credential-modal/delete-credential-modal.component';
import { DeleteGroupModalComponent } from './components/delete-group-modal/delete-group-modal.component';
import { CredentialGroupComponent } from './components/credential-group/credential-group.component';
import { AddUserModalComponent } from './components/add-user-modal/add-user-modal.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { ManageUsersConfirmModalComponent } from './components/manage-users-confirm-modal/manage-users-confirm-modal.component';

@NgModule({
    imports: [
        CommonModule,
        CredentialsModule,
        RouterModule.forChild(credentialsRoutes),
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        PassbotCommonModule,
        ModalModule,
        PermissionsModule,
        SidebarModule,
    ],
    declarations: [
        IndexComponent,
        DeleteCredentialModalComponent,
        DeleteGroupModalComponent,
        CredentialGroupComponent,
        AddUserModalComponent,
        ManageUsersComponent,
        ManageUsersConfirmModalComponent,
    ],
})
export class CredentialsModuleRouted {}
