import { Injectable } from '@angular/core';

import { ConfigService } from '@passbot/angular/config';

@Injectable()
export class ChargebeeService {
    public cbSiteId: string;

    constructor(private readonly configService: ConfigService) {}

    public init() {
        this.cbSiteId = this.configService.get('CHARGEBEE_SITE') as string;

        this.loadExternalScript(this.cbSiteId);

    }

    protected loadExternalScript(cbSiteValue: string) {
        const script = document.createElement('script');

        script.onload = () => {
            (window as any).Chargebee.init({
                site: cbSiteValue,
            });
        };

        script.src = 'https://js.chargebee.com/v2/chargebee.js';

        script.setAttribute('data-cb-site', cbSiteValue);

        document.head.appendChild(script);
    }
}
