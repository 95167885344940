import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { HostService } from '@passbot/angular/config';

import { TenantService } from '../services/tenant.service';

@Injectable({
    providedIn: 'root',
})
export class TenantGuard {
    constructor(private readonly hostService: HostService, private readonly tenantService: TenantService) {}

    public async canActivate(): Promise<boolean> {
        const tenant = await firstValueFrom(this.tenantService.getTenant$());

        if (!tenant) {
            window.location = this.hostService.getApplicationUrl().toString() as any;
        }

        return true;
    }
}
