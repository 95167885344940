import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { KeepaliveService } from '../services/keepalive.service';

@Injectable()
export class KeepaliveInterceptor implements HttpInterceptor {
    constructor(protected readonly keepalive: KeepaliveService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            tap({
                // Succeeds when there is a response; ignore other events
                next: (event) => {
                    if (!req.url.includes('keepalive') && !req.url.includes('logout')) {
                        this.keepalive.trackActivity();
                    }
                },
            }),
        );
    }
}
