import { Component, Inject, OnDestroy } from '@angular/core';
import { Subject, take, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';
import { addDeviceSuccess, DevicesFacade } from '../../../+state';

@Component({
    selector: 'passbot-add-email-device',
    templateUrl: './add-email-device.component.html',
})
export class AddEmailDeviceComponent implements OnDestroy {
    public isProcessing$ = this.devicesFacade.isProcessing$;
    public destroyed$ = new Subject<void>();

    public emailForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        private readonly devicesFacade: DevicesFacade,
        private readonly actions$: Actions,
        private readonly toastr: ToastrService,
    ) {}

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public close() {
        if (this.modal) {
            this.modal.close();
        }
    }

    public addViaEmail() {
        if (this.emailForm.invalid) {
            return;
        }
        // do through store and listen for progress observable
        this.devicesFacade.addDevice('email', '', this.emailForm.controls.name.value!);
        this.actions$.pipe(ofType(addDeviceSuccess), take(1), takeUntil(this.destroyed$)).subscribe(() => {
            this.toastr.success('Mobile authentication device added');
            this.modal.close();
        });
    }
}
