import { Component, Inject } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { IUserPermission } from '@passbot/shared';
import { ToastrService } from 'ngx-toastr';
import { CredentialGroupsFacade } from '../../+state';

export interface IManageUsersConfirmData {
    deleted: number;
    new: number;
    changed: number;
}

@Component({
    selector: 'passbot-manage-users-confirm-modal',
    templateUrl: './manage-users-confirm-modal.component.html',
})
export class ManageUsersConfirmModalComponent {
    public isProcessing$ = this.credentialGroupsFacade.isProcessing$;
    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA)
        public readonly data: {
            changed: IManageUsersConfirmData;
            credentialGroupId: string;
            permissions: IUserPermission[];
        },
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly toastr: ToastrService,
    ) {}

    public cancel() {
        this.modal.close();
    }

    public async confirm() {
        await this.credentialGroupsFacade.updateCredentialGroupPermissionsAsync(this.data.credentialGroupId, this.data.permissions);
        this.modal.close();
        this.toastr.success('Group permissions updated');
    }
}
