import { IUser, IUserPermission, StoreActions } from '@passbot/shared';
import { createAction, props } from '@ngrx/store';

export const loadPermissions = createAction(StoreActions.Permissions.loadPermissions);
export const loadPermissionsSuccess = createAction(StoreActions.Permissions.loadPermissionsSuccess, props<{ permissions: IUserPermission[] }>());
export const loadPermissionsError = createAction(StoreActions.Permissions.loadPermissionsError, props<{ msg: string }>());
export const removeUserPermission = createAction(
    StoreActions.Permissions.removePermission,
    props<{ userId: IUser['id']; permissionId: IUserPermission['id'] }>(),
);
export const removeUserPermissionSuccess = createAction(
    StoreActions.Permissions.removePermissionSuccess,
    props<{ userId: IUser['id']; permissionId: string }>(),
);
export const removeUserPermissionError = createAction(StoreActions.Permissions.removePermissionError, props<{ msg: string }>());
export const updateUserPermission = createAction(
    StoreActions.Permissions.updatePermission,
    props<{ permission: IUserPermission; userId: IUser['id'] }>(),
);
export const updateUserPermissionSuccess = createAction(
    StoreActions.Permissions.updatePermissionSuccess,
    props<{ permission: IUserPermission; userId: IUser['id'] }>(),
);
export const updateUserPermissionError = createAction(StoreActions.Permissions.updatePermissionError, props<{ msg: string }>());
