import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { RouterFacade } from '../facades';

export interface ILayoutProperties {
    sidebarVisible: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private layoutProps: ILayoutProperties = {
        sidebarVisible: false,
    };

    private readonly layoutProps$ = new ReplaySubject<ILayoutProperties>(1);

    constructor(private readonly routerFacade: RouterFacade) {
        this.layoutProps$.next(this.layoutProps);
        this.routerFacade.storeData$.subscribe((data) => {
            Object.keys(this.layoutProps).forEach((key) => {
                if (data[key] !== undefined) {
                    this.layoutProps[key as keyof ILayoutProperties] = data[key];
                }

                this.layoutProps$.next(this.layoutProps);
            });
        });
    }

    public updateProps(props: Partial<ILayoutProperties>) {
        this.layoutProps = {
            ...this.layoutProps,
            ...props,
        };

        this.layoutProps$.next(this.layoutProps);
    }

    public getProperties() {
        return this.layoutProps$.asObservable();
    }
}
