import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import isString from 'lodash-es/isString';
import { ToastrService } from 'ngx-toastr';
import { filter, firstValueFrom, map, take } from 'rxjs';

import { APIService } from '@passbot/angular/api';
import { getMergedRouteParams } from '@passbot/angular/common';
import { MODAL_DATA, MODAL_REF, ModalComponent, ModalService } from '@passbot/angular/modal';
import { generateSecurePassword, ICredential, ICredentialGroup } from '@passbot/shared';

import { CredentialsFacade, CredentialGroupsFacade } from '../../+state';
import { DeleteGroupsByCredentialModalComponent } from '../delete-groups-by-credential-modal/delete-groups-by-credential-modal.component';

@Component({
    selector: 'passbot-add-credential-modal',
    templateUrl: './add-credential-modal.component.html',
})
export class AddCredentialModalComponent implements OnInit {
    @ViewChild('passwordInput') public passwordInput: ElementRef;
    public credentialForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        url: new FormControl('', [Validators.pattern(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)]),
        credentialGroups: new FormControl([] as { id: string; name: string }[], [Validators.required]),
        totpKey: new FormControl('', [Validators.pattern(/^[A-Z2-7]+=*$|\*\*\*\*\*/)]),
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        confirm2FA: new FormControl('', [this.validateConfirm2FA.bind(this)]),
    });

    public isProcessing$ = this.credentialsFacade.isProcessing$;
    public awaiting2FA$ = this.credentialsFacade.awaiting2FA$;
    public groups$ = this.credentialGroupsFacade.getAll$.pipe(map((groups) => groups.map((g) => ({ id: g.id, name: g.name }))));
    public totp: string | undefined;
    public editing = false;
    public had2FA = false;
    public originalCredential: ICredential;

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        private readonly toastr: ToastrService,
        private readonly credentialsFacade: CredentialsFacade,
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly apiService: APIService,
        private readonly modalService: ModalService,
        private readonly route: ActivatedRoute,
        @Inject(MODAL_DATA) private readonly data: { credential?: ICredential; selectedGroup?: ICredentialGroup } = {},
    ) {}

    public async ngOnInit() {
        if (this.data?.credential) {
            this.originalCredential = await this.apiService.getAsync<ICredential>(`/credential/${this.data.credential.id}`);

            this.editing = true;
            this.had2FA = !!this.data.credential.totpKey;
            const credentialGroups = await firstValueFrom(this.credentialGroupsFacade.groupsByCredentialId$(this.data.credential.id));
            this.credentialForm.patchValue({
                ...this.originalCredential,
                credentialGroups,
            });
            this.credentialForm.updateValueAndValidity();
        } else {
            const routeParams = getMergedRouteParams(this.route);
            if (routeParams.credentialGroupId) {
                const group = await firstValueFrom(this.credentialGroupsFacade.getById$(routeParams.credentialGroupId));
                if (group) {
                    this.credentialForm.controls.credentialGroups.patchValue([{ id: group.id, name: group.name }]);
                }
            }
        }

        if (this.data.selectedGroup) {
            this.credentialForm.patchValue({
                credentialGroups: [{ id: this.data.selectedGroup.id, name: this.data.selectedGroup.name }],
            });
            this.credentialForm.updateValueAndValidity();
        }

        this.credentialForm.controls.totpKey.valueChanges.subscribe(() => (this.totp = undefined));
    }

    public close() {
        this.modal.close();
    }

    public async saveCredential() {
        if (this.credentialForm.invalid) {
            return;
        }

        const credential = this.credentialForm.value as Partial<ICredential>;
        if (credential.url === '') {
            credential.url = undefined;
        }

        let response;
        if (!this.editing) {
            response = await this.credentialsFacade.createCredentialAsync(credential);
        } else {
            // check if any groups could be deleted
            const groupsToDelete = (await this.credentialGroupsFacade.getGroupsToDelete(this.originalCredential.id)).filter(
                (g) => !this.credentialForm.controls.credentialGroups.value?.map((cg) => cg.id).includes(g.id),
            );

            if (groupsToDelete.length > 0) {
                await firstValueFrom(this.modalService.show(DeleteGroupsByCredentialModalComponent, { data: groupsToDelete }));
            }

            response = await this.credentialsFacade.updateCredentialAsync({ id: this.originalCredential.id, ...credential } as ICredential);
        }

        const err = (response as { msg: string }).msg;

        if (err) {
            this.toastr.error(err);
            return;
        }

        this.toastr.success(`Credential group ${this.editing ? 'updated' : 'created'} successfully`);
        this.modal.close();
        return;
    }

    public cancel2FAScan() {
        this.credentialsFacade.cancel2FAListener();
    }

    public scanWithPhone() {
        void this.credentialsFacade.scan2FA();
        this.awaiting2FA$
            .pipe(
                filter((response) => isString(response)),
                take(1),
            )
            .subscribe((totpToken) => {
                this.credentialForm.controls.totpKey.setValue(totpToken as string);
            });
    }

    public debug() {
        console.log(this.credentialForm);
    }

    public trackByFn(group: ICredentialGroup) {
        return group.id;
    }

    public async generateTotp() {
        if (this.credentialForm.controls.totpKey.value && this.credentialForm.controls.totpKey.value !== '') {
            const secret = this.credentialForm.controls.totpKey.value;
            this.totp = await this.credentialsFacade.getTotp(secret);
        }
    }

    public generateStrongPassword() {
        this.credentialForm.controls.password.setValue(generateSecurePassword(window.crypto || (window as any).msCrypto, 16));
        this.credentialForm.controls.password.markAsDirty();
        this.passwordInput.nativeElement.setAttribute('type', 'text');
    }

    private validateConfirm2FA(control: AbstractControl) {
        if (!(this.credentialForm?.controls.totpKey.value === '' && this.had2FA)) {
            return null;
        }

        const value = control.value.toLowerCase();
        if (value !== (this.credentialForm?.controls.name.value || '').toLowerCase()) {
            return { confirm2FAError: true };
        }

        return null;
    }
}
