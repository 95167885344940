<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="(isProcessing$ | async) === false" >
    <h3 class="text-center font-semibold text-lg">Remove Auth Device</h3>
    <div>
        Are you sure you wish to remove your <span class="font-semibold text-brand">{{ device.displayName}}</span> device? You will no longer be able to use this device to authenticate with our platform.
    </div>
    <div class="flex justify-between mt-8">
        <div (click)="cancel()" class="cursor-pointer inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
            Cancel
        </div>
        <button
                (click)="removeDevice()"
                class="inline-flex justify-center rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed">
            Remove Device
        </button>
    </div>
</div>
