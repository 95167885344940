<div class="min-w-[300px]">
    <div class="text-2xl font-semibold text-white text-center mb-4">
        Authorise access
    </div>

    <div class="flex space-x-4 justify-between" *ngIf="(inProgress | async) === false">
        <button *ngIf="mobileAvailable"
                (click)="doAuth('mobile')"
                class="w-[150px] bg-slate-900 shadow-lg border border-white/20 p-6 rounded-lg text-center text-white flex items-center justify-center cursor-pointer"
        >
            <i class="fa-regular fa-mobile text-3xl mr-2"></i><span>Mobile Device</span>
        </button>

        <button *ngIf="localAvailable"
                (click)="doAuth('local')"
                class="w-[150px] bg-slate-900 shadow-lg border border-white/20 p-6 rounded-lg text-center text-white flex items-center justify-center cursor-pointer"
        >
            <i class="fa-regular fa-up-right-from-square text-3xl mr-2"></i><span>Local Device</span>
        </button>

        <button
                (click)="doAuth('email')"
                class="w-[150px] bg-slate-900 shadow-lg border border-white/20 p-6 rounded-lg text-center text-white flex items-center justify-center cursor-pointer"
        >
            <i class="fa-regular fa-envelope text-3xl mr-2"></i><span>via Email</span>
        </button>
    </div>

    <div class="p-8 text-white text-center space-y-4" *ngIf="inProgress | async">
        <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
        <div *ngIf="method === 'mobile'">Awaiting authentication on device, check your device for a SMS message with a URL to authenticate the device</div>
        <div *ngIf="method === 'email'">Awaiting authentication on device, check your emails to authenticate the device</div>
        <div *ngIf="method === 'local'">Awaiting authentication...</div>
    </div>
</div>
