import { Injectable } from '@angular/core';
import { ITenant } from '@passbot/shared';
import { HttpClient } from '@angular/common/http';
import { filter, firstValueFrom, ReplaySubject } from 'rxjs';

@Injectable()
export class TenantService {
    private tenant: ITenant | null;
    private readonly tenantSubject$ = new ReplaySubject<ITenant | null>(1);
    constructor(private readonly http: HttpClient) {}

    public async init() {
        try {
            this.tenant = await firstValueFrom(this.http.get<ITenant>('/api/tenant/current'));
        } catch (e) {
            this.tenant = null;
        }
        this.tenantSubject$.next(this.tenant);
    }

    public getTenant() {
        return this.tenant;
    }

    public getTenant$() {
        return this.tenantSubject$.pipe(filter((tenant) => tenant !== undefined));
    }
}
