<div
    class="space-y-8 text-white w-[500px] max-w-full text-left"
    *ngIf="(isProcessing$ | async) === false && (platformUsers$ | async)"
    [formGroup]="userForm"
>
    <h3 class="text-center font-semibold text-lg text-brand">{{ editing ? 'Edit' : 'Add'}} User</h3>
    <div class="space-y-8">
        <div>
            <label for="user" class="text-xs mb-1" [ngClass]="{'!text-red-500': userForm.controls.workspaceUser.invalid && userForm.controls.workspaceUser.touched }">User *</label>
            <ng-select *ngIf="!editing" [items]="platformUsers$ | async | passbotAlphabeticalSort: 'name'"
                       bindLabel="name"
                       labelForId="group"
                       id="user"
                       [addTag]="false"
                       [multiple]="false"
                       [hideSelected]="false"
                       [trackByFn]="trackByFn"
                       [minTermLength]="2"
                       [loading]="false"
                       typeToSearchText="Please enter 2 or more characters"
                       formControlName="workspaceUser"
                       [disabled]="editing">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div class="flex space-x-2 items-center" [style.color]="item.colorHex === '#000000' ? '#fff' : item.colorHex"><img class="rounded-full w-10 h-10" [src]="item.avatarUrl"> <span class="flex-1">{{ item.name }}</span></div>
                </ng-template>
            </ng-select>
            <input *ngIf="editing" class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6 cursor-not-allowed" type="text" readonly [value]="userForm.controls.workspaceUser.value?.name" disabled>
        </div>
        <div class="relative text-center">
            <div class="z-1 inline-block m-auto text-xm bg-slate-900 px-4 text-white/40 relative">PERMISSIONS</div>
            <div class="z-0 absolute border-b border-white/20 top-[50%] w-full"></div>
        </div>

        <div class="">
            <label class="relative inline-flex items-center cursor-pointer">
                <input formControlName="active" type="checkbox" value="" class="sr-only">
                <div
                    [ngClass]="{
                        'after:translate-x-full': userForm.controls.active.value,
                        'bg-green-500': userForm.controls.status.value === UserStatus.active,
                        'bg-red-500': userForm.controls.status.value === UserStatus.disabled,
                        'bg-yellow-700': userForm.controls.status.value === UserStatus.temp
                    }"
                    class="w-11 h-6 peer-focus:outline-none rounded-full bg-gray-700 peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-brand"></div>
                <span class="ml-3 text-sm font-medium text-white">Account {{ userForm.controls.status.value === UserStatus.active ? 'active' : userForm.controls.status.value === UserStatus.temp ? 'expiring' : 'disabled' }}</span>
            </label>
            <div *ngIf="!canGoActive" class="text-xs text-red-500 mt-2">This user cannot be made active as you are using all of the seats available on your plan. Either increase the plan subscription or disable another user first.</div>
            <div *ngIf="userForm.controls.status.value === UserStatus.temp" class="text-xs text-yellow-600 mt-2">This user account will be disabled on {{ seatsInfo.pendingDate | date }} in line with your pending subscription changes.</div>
        </div>

        <div [ngClass]="{'opacity-50': userForm.controls.status.value === UserStatus.disabled}">
            <label class="relative inline-flex items-center cursor-pointer">
                <input formControlName="tenantAdmin" type="checkbox" value="" class="sr-only peer">
                <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-brand"></div>
                <span class="ml-3 text-sm font-medium text-white">Tenant Admin</span>
            </label>
        </div>
        <div [ngClass]="{'opacity-50': userForm.controls.status.value === UserStatus.disabled}">
            <label class="relative inline-flex items-center cursor-pointer">
                <input formControlName="billingAdmin" type="checkbox" value="" class="sr-only peer">
                <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-brand"></div>
                <span class="ml-3 text-sm font-medium text-white">Billing Admin</span>
            </label>
        </div>

        <div [ngClass]="{'opacity-50': userForm.controls.status.value === UserStatus.disabled}" class="space-y-2" *ngIf="!userForm.controls.tenantAdmin.value">
            <div class="flex">
                <div class="flex-1 text-xs">
                    Group(s) *
                </div>
                <div class="text-center text-xs w-[130px]">
                    Access
                </div>
            </div>
            <div *ngFor="let group of userForm.controls.groupPerms.controls; let i = index" class="flex space-x-6 items-center" [formGroup]="group">
                <div class="flex-1">
                    <ng-select [items]="items[i] | passbotAlphabeticalSort: 'name'"
                               bindLabel="name"
                               labelForId="group"
                               [id]="i"
                               [addTag]="false"
                               [bindValue]="'id'"
                               [multiple]="false"
                               [hideSelected]="false"
                               [trackByFn]="trackByIdFn"
                               [loading]="false"
                               (change)="checkGroups(i)"
                               typeToSearchText="Please enter 2 or more characters"
                               formControlName="credentialGroupId">
                    </ng-select>
                </div>
                <div class="w-[130px] text-center h-full flex items-center justify-center">
                    <passbot-group-access-selector [userPermissionFormGroup]="group"></passbot-group-access-selector>
                </div>
            </div>
        </div>

    </div>
    <div class="border border-red-500 p-4 text-center text-sm text-red-500" *ngIf="userDisableDate">
        <span class="font-bold">PLEASE NOTE:</span> Whilst you can add this user now, due to your upcoming subscription changes this user will lose access on your next billing date (<span class="font-semibold">{{ userDisableDate | date}}</span>), unless your subscription is increased beforehand.
    </div>
    <div class="flex justify-between mt-8">
        <div (click)="close()" class="cursor-pointer inline-flex justify-center bg-slate-200 cursor-pointer hover:bg-slate-100 text-slate-800 py-2 px-4 border-b-4 border-white rounded-lg">
            Cancel
        </div>
        <div class="text-center text-xs text-white flex items-center justify-center">
            * required field
        </div>
        <button
                (click)="saveUser()"
                [disabled]="!(userForm.valid && userForm.dirty)"
                class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
            {{ editing ? 'Save' : 'Create' }}
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="isProcessing$ | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>
