import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SidebarService } from './sidebar.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarContentDirective } from './sidebar/sidebar-content.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [SidebarComponent, SidebarContentDirective],
    exports: [SidebarComponent],
    providers: [SidebarService, SidebarContentDirective],
})
export class SidebarModule {}
