<div class="max-w-7xl m-auto flex-1 flex flex-col h-full">
    <div class="flex relative items-center justify-center space-x-4 mb-8 mt-4">
        <div><img class="inline-block h-14 w-auto" src="/assets/images/passbot-logo.svg"></div>
        <div>
            <div class="text-brand font-semibold">PASSBOT.IO</div>
            <div class="text-white font-semibold">{{ tenant.displayName }}</div>
        </div>
    </div>
    <div class="flex-1 flex-col flex items-center justify-center" *ngIf="!showLoginLinkInvalid">

        <ng-container *ngIf="isOnboarding">
            <h1 class="text-white font-bold text-5xl text-center font-inter mb-4">Welcome!</h1>
            <h3 class="text-white text-lg text-center">Let's get you setup on the platform, firstly connect your chosen workspace using the button below</h3>
        </ng-container>

        <h1 *ngIf="!isOnboarding && !this.devices" class="text-white font-bold text-3xl text-center font-inter" >Sign in to your account</h1>

        <div class="mt-4 text-center" *ngIf="!this.devices">
            <a *ngFor="let workspace of workspaces" (click)="login(workspace)" class="shadow-lg inline-block p-6 cursor-pointer bg-indigo-600 text-white text-center rounded-lg text-lg align-middle hover:bg-indigo-500 hover:shadow-sm transition-all">
                <passbot-platform [platform]="workspace.platform!"></passbot-platform>
            </a>

            <div class="bg-error/20 text-error-focus text-center rounded-lg font-bold p-5 mt-4" *ngIf="loginError">{{ loginError }}</div>
        </div>

        <div class="mt-4" *ngIf="devices">
            <passbot-device-auth (authed)="handleAuth()"></passbot-device-auth>
        </div>

    </div>

    <div *ngIf="showLoginLinkInvalid" class="border border-red-400 text-red-400 p-6">
        ERROR: It looks like you haven't yet setup your account, please follow the link in your email to do so.
    </div>


</div>
