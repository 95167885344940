import { Component, ViewChild } from '@angular/core';
import { UserFacade } from '@passbot/angular/auth';
import { ManageDevicesComponent } from '../manage-devices/manage-devices.component';

@Component({
    selector: 'passbot-device-index',
    templateUrl: './index.component.html',
})
export class IndexComponent {
    @ViewChild('manageDevicesComponent') private readonly manageDevices: ManageDevicesComponent;
    public optionsVisible = false;
    public hasMobile = false;

    constructor(private readonly userFacade: UserFacade) {}

    public addDevice(type: string) {
        this.optionsVisible = false;
        this.manageDevices.showModal(type);
    }

    public async ngOnInit() {
        const user = await this.userFacade.getUser();
        this.hasMobile = !!user.mobile;
    }

    public createOptions() {
        this.optionsVisible = !this.optionsVisible;
    }
}
