import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import omit from 'lodash-es/omit';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable, Subject, switchMap, tap, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen, take } from 'rxjs/operators';

import { ConfigService } from '@passbot/angular/config';
import { Store } from '@ngrx/store';
import { StoreActions } from '@passbot/shared';
import { Actions, ofType } from '@ngrx/effects';

export type APICallMethod = 'POST' | 'GET' | 'PUT' | 'DELETE';

export const HTTP_CLIENT_MAX_RETRIES = 3;
const RETRY_STATUS_CODES = [404, 500, 301];
const RETRY_SCALING_DURATION = 400;

@Injectable({
    providedIn: 'root',
})
export class APIService {
    public authInProgressObservable = new Subject<void>();
    public authInProgress = false;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigService,
        private readonly toastr: ToastrService,
        private readonly store: Store,
        private readonly actions$: Actions,
    ) {}

    // eslint-disable-next-line complexity
    public call<T>(
        url: string,
        data?: unknown,
        method: APICallMethod = 'GET',
        showError?: boolean,
        headers: Record<string, unknown> = {},
    ): Observable<T> {
        // eslint-disable-next-line
        const source = typeof window !== 'undefined' ? window.location.href : 'unknown';

        return this.httpClient
            .request<T>(method, this.buildUrl(url), {
                headers: {
                    ...(!url.includes('http')
                        ? {
                              'X-Source-url': source,
                          }
                        : {}),
                    ...omit(headers, ['withCredentials', 'responseType']),
                },
                ...(method === 'POST' || method === 'PUT' ? { body: data } : { params: data as Record<string, string> }),
                withCredentials: headers.withCredentials !== undefined ? (headers.withCredentials as boolean) : true,
                responseType: headers.responseType ? (headers.responseType as 'json') : undefined,
            })
            .pipe(
                retryWhen((attempts) =>
                    attempts.pipe(
                        mergeMap((error, i) => {
                            const retryAttempt = i + 1;
                            // if maximum number of retries have been met
                            // or response is a status code we don't wish to retry, throw error
                            if (retryAttempt > HTTP_CLIENT_MAX_RETRIES || !RETRY_STATUS_CODES.find((e) => e === error.status)) {
                                return throwError(error);
                            }
                            // retry after 1s, 2s, etc...
                            return timer(retryAttempt * RETRY_SCALING_DURATION);
                        }),
                    ),
                ),
                catchError((e, caught) => {
                    if (showError) {
                        this.toastr.error(e.error?.message || 'There has been an error');
                    }

                    // handle needs auth
                    if (e.status === 412) {
                        if (this.authInProgress) {
                            return this.authInProgressObservable.asObservable().pipe(
                                take(1),
                                switchMap(() => this.call(url, data, method, showError, headers)),
                            );
                        } else {
                            this.authInProgress = true;
                            this.store.dispatch({ type: StoreActions.User.needsDeviceAuth });
                            return this.actions$.pipe(
                                ofType(StoreActions.User.authWithDeviceSuccess),
                                take(1),
                                tap(() => {
                                    this.authInProgress = false;
                                    this.authInProgressObservable.next();
                                }),
                                switchMap(() => this.call(url, data, method, showError, headers)),
                            );
                        }
                    }

                    return throwError(e);
                }),
            ) as Observable<T>;
    }

    public buildUrl(url: string) {
        if (url.includes('http')) {
            return url;
        }

        const { hostname, protocol } = window.location;

        // Should build this up by the tenant.
        const tenantApiHost = `${protocol}//${hostname}/api`;
        const apiHost = tenantApiHost ? tenantApiHost : this.configService.get('API_ENDPOINT');

        return `${apiHost}${url}`;
    }

    public async callAsync<T>(url: string, data?: unknown, method: APICallMethod = 'GET', showError?: boolean) {
        return firstValueFrom<T>(this.call<T>(url, data, method, showError));
    }

    public get<T>(url: string, data?: unknown, showError?: boolean, force?: boolean) {
        return this.call<T>(url, data, 'GET', showError, { ...(force ? { 'x-noCache': true } : {}) });
    }

    public async getAsync<T>(url: string, data?: unknown, showError?: boolean, headers?: Record<string, unknown>) {
        return firstValueFrom<T>(this.call<T>(url, data, 'GET', showError, headers));
    }

    public post<T>(url: string, data: unknown, showError?: boolean) {
        return this.call<T>(url, data, 'POST', showError);
    }

    public async postAsync<T>(url: string, data: unknown, showError?: boolean) {
        return firstValueFrom<T>(this.call<T>(url, data, 'POST', showError));
    }

    public put<T>(url: string, data: unknown, showError?: boolean) {
        return this.call<T>(url, data, 'PUT', showError);
    }

    public async putAsync<T>(url: string, data: unknown, showError?: boolean) {
        return firstValueFrom<T>(this.call<T>(url, data, 'PUT', showError));
    }

    public delete<T>(url: string, data?: unknown, showError?: boolean) {
        return this.call<T>(url, data, 'DELETE', showError);
    }

    public async deleteAsync<T>(url: string, data?: unknown, showError?: boolean) {
        return firstValueFrom<T>(this.call<T>(url, data, 'DELETE', showError));
    }

    public upload<T>(url: string, file: File, data: Record<string, unknown>) {
        const form = this.makeForm(file, data);
        return this.call<T>(url, form, 'POST');
    }

    public async uploadAsync<T>(url: string, file: File, data: Record<string, unknown>) {
        return this.upload<T>(url, file, data).pipe(take(1)).toPromise();
    }

    private makeForm(file: File, data: Record<string, unknown>) {
        const formData = new FormData();
        formData.append('file', file);
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key] as string);
        });
        return formData;
    }
}
