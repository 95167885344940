import { Component, Inject } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { ICredentialGroup } from '@passbot/shared';
import { CredentialGroupsFacade } from '../../+state';

@Component({
    templateUrl: './delete-groups-by-credential-modal.component.html',
})
export class DeleteGroupsByCredentialModalComponent {
    public groups: ICredentialGroup[];
    public deleteGroups: Record<string, boolean> = {};
    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) private readonly data: ICredentialGroup[],
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
    ) {
        this.groups = this.data;
    }

    public cancel() {
        this.modal.close(false);
    }

    public processGroups() {
        Object.keys(this.deleteGroups).forEach((groupId) => {
            if (this.deleteGroups[groupId]) {
                this.credentialGroupsFacade.deleteGroup(groupId);
            }
        });
        this.modal.close(true);
    }
}
