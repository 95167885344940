import { Component, Inject, OnInit } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent, ModalService } from '@passbot/angular/modal';
import { ICredential, ICredentialGroup } from '@passbot/shared';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { CredentialsFacade, CredentialGroupsFacade } from '../../+state';
import { DeleteGroupsByCredentialModalComponent } from '../delete-groups-by-credential-modal/delete-groups-by-credential-modal.component';

export interface IDeleteCredentialModalData {
    credential: ICredential;
    groupId: string;
}

@Component({
    selector: 'passbot-delete-credential-modal',
    templateUrl: './delete-credential-modal.component.html',
})
export class DeleteCredentialModalComponent implements OnInit {
    public credential: ICredential;
    public isProcessing$ = this.credentialsFacade.isProcessing$;
    public otherCredentialGroups: ICredentialGroup[] = [];
    public group: ICredentialGroup;

    public deleteCredentialForm = new FormGroup({
        confirm2FA: new FormControl('', [this.validateConfirm.bind(this)]),
        deleteAll: new FormControl(false),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) private readonly data: IDeleteCredentialModalData,
        private readonly credentialsFacade: CredentialsFacade,
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly modalService: ModalService,
    ) {
        this.credential = this.data.credential;
    }

    public async ngOnInit() {
        this.group = (await this.credentialGroupsFacade.getByIdAsync(this.data.groupId)) as ICredentialGroup;
        this.otherCredentialGroups = (await this.credentialGroupsFacade.groupsByCredentialIdAsync(this.credential.id)).filter(
            (groups) => groups.id !== this.group.id,
        );
    }

    public close() {
        this.modal.close();
    }

    public async deleteFromGroup() {
        // need to check if it's going to leave any groups empty
        const groupsToDelete = (await this.credentialGroupsFacade.getGroupsToDelete(this.credential.id)).filter(
            (g) => g.id === this.group.id || !!this.deleteCredentialForm.controls.deleteAll.value,
        );

        if (groupsToDelete.length > 0) {
            await firstValueFrom(this.modalService.show(DeleteGroupsByCredentialModalComponent, { data: groupsToDelete }));
        }

        await this.credentialsFacade.deleteCredentialAsync(this.credential, this.group.id, !!this.deleteCredentialForm.controls.deleteAll.value);

        this.modal.close();
    }

    private validateConfirm(control: AbstractControl) {
        if (
            this.credential &&
            this.credential.totpKey &&
            this.otherCredentialGroups.length > 0 &&
            !this.deleteCredentialForm.controls.deleteAll.value &&
            control.value.toLowerCase() !== this.credential.name.toLowerCase()
        ) {
            return { confirmed: false };
        }
        return null;
    }
}
