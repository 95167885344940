import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService } from '@passbot/angular/api';
import { ConfigService, IConfig } from '@passbot/angular/config';
import { DevicesFacade } from '@passbot/angular/devices';
import { TenantService } from '@passbot/angular/tenant';
import { ISignupToken, ITenant, ITenantWorkspace, IUserAuthDevice } from '@passbot/shared';

import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'passbot-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    public workspaces: Partial<ITenantWorkspace>[];
    public config: IConfig;
    public loginError: string | undefined;
    public showLoginLinkInvalid = false;
    public isOnboarding = false;
    public tenant: ITenant;
    public devices: IUserAuthDevice[];

    constructor(
        private readonly authService: AuthService,
        private readonly configService: ConfigService,
        private readonly api: APIService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly devicesFacade: DevicesFacade,
        private readonly tenantService: TenantService,
    ) {
        this.config = this.configService.getConfig();
    }

    public async ngOnInit() {
        this.tenant = this.tenantService.getTenant() as ITenant;
        this.workspaces = await this.api.getAsync<ITenantWorkspace[]>('/tenant-workspace');

        if (this.workspaces.length < 1 && !this.route.snapshot.queryParams.token) {
            this.showLoginLinkInvalid = true;
        }

        // check for token
        if (this.route.snapshot.queryParams.token) {
            this.isOnboarding = true;
            // get signup
            try {
                const signup = await this.api.getAsync<ISignupToken>('/signup', { token: this.route.snapshot.queryParams.token });
                this.workspaces = [
                    {
                        platform: signup.platform,
                    },
                ];
            } catch (e) {
                this.showLoginLinkInvalid = true;
            }
            return;
        }
    }

    public async login(workspace: Partial<ITenantWorkspace>) {
        this.loginError = undefined;
        try {
            await this.authService.login(workspace, this.isOnboarding);
            await this.processLogin();
        } catch (e: any) {
            this.loginError = e.message;
        }
    }

    public async handleAuth() {
        return this.router.navigateByUrl('/');
    }

    private async processLogin() {
        if (this.isOnboarding) {
            return this.router.navigateByUrl('/onboarding');
        }

        // check if user has devices
        const devices = await this.devicesFacade.loadDevicesAsync();
        if (devices.items?.length === 0 || devices.msg) {
            // if not we need to force them to onboard them
            return '/onboarding';
        }

        this.devices = devices.items!;

        return '/';
    }
}
