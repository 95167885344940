import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getDefaultSelectors } from '@passbot/angular/common';
import { ICredentialGroup } from '@passbot/shared';
import { credentialsSelectors } from '../credentials';
import { CREDENTIAL_GROUPS_FEATURE_KEY, CredentialGroupsState } from './credential-groups.reducer';

export const getCredentialGroupsState = createFeatureSelector<CredentialGroupsState>(CREDENTIAL_GROUPS_FEATURE_KEY);

const { selectAll, getIsProcessing, getError, getSelectedId, getSelected, getById } = getDefaultSelectors<ICredentialGroup>(getCredentialGroupsState);

export const getGroupsByCredentialId = (credentialId: string) =>
    createSelector(
        credentialsSelectors.getById(credentialId),
        getCredentialGroupsState,
        (credential, credentialGroupsState) => credential!.credentialGroups!.map((g) => credentialGroupsState.entities[g.id]) as ICredentialGroup[],
    );

export const getAllAlphabetically = createSelector(selectAll, (credentials) => {
    return credentials.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
});

export const getFirst = createSelector(getAllAlphabetically, (credentials) => {
    return credentials[0];
});

export const searchCredentialGroups = (searchStr: string) =>
    createSelector(getAllAlphabetically, (groups) => {
        return groups.filter((g) => g.name.toLowerCase().includes(searchStr.toLowerCase())).slice(0, 5);
    });

export const credentialGroupSelectors = {
    selectAll,
    getIsProcessing,
    getError,
    getSelected,
    getSelectedId,
    getById,
    getGroupsByCredentialId,
    getAllAlphabetically,
    getFirst,
    searchCredentialGroups,
};

//export const getHydratedGroup = (credentialGroupId: string) => createSelector(getById(credentialGroupId), (credentialGroup) => credentialGroup);
