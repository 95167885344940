import { Component, Inject, OnInit } from '@angular/core';
import { ICredentialGroup, IPlatformUser, ITenantWorkspace, IUser, IUserPermission, Permissions } from '@passbot/shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatestWith, firstValueFrom, map, Observable } from 'rxjs';
import { APIService } from '@passbot/angular/api';
import { PermissionsFacade, UsersFacade } from '@passbot/angular/permissions';
import { SIDEBAR_DATA, SIDEBAR_REF, SidebarComponent } from '@passbot/angular/sidebar';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import { ModalService } from '@passbot/angular/modal';
import keyBy from 'lodash-es/keyBy';
import { ManageUsersConfirmModalComponent } from '../manage-users-confirm-modal/manage-users-confirm-modal.component';

@Component({
    selector: 'passbot-manage-users',
    templateUrl: './manage-users.component.html',
    host: { class: 'flex-1 flex flex-col h-full' },
})
export class ManageUsersComponent implements OnInit {
    public platformUsers$: Observable<IUser[]>;
    public selectedWorkspace: ITenantWorkspace;
    public groupPermissions: Partial<IUserPermission>[] = [];
    public originalPerms: Partial<IUserPermission>[] = [];

    public userForm = new FormGroup({
        user: new FormControl<IUser | undefined>(undefined, [Validators.required]),
        permission: new FormControl(Permissions.Read),
    });

    protected readonly Permissions = Permissions;

    constructor(
        @Inject(SIDEBAR_REF) private readonly sidebar: SidebarComponent,
        @Inject(SIDEBAR_DATA) private readonly data: { credentialGroup: ICredentialGroup },
        private readonly api: APIService,
        private readonly usersFacade: UsersFacade,
        private readonly permissionsFacade: PermissionsFacade,
        private readonly modalService: ModalService,
    ) {}

    public async ngOnInit() {
        const workspaces = await this.api.getAsync<ITenantWorkspace[]>('/tenant-workspace');
        this.selectedWorkspace = workspaces[0]; // @todo needs some attention for multiple workspace approach

        this.groupPermissions = (await firstValueFrom(
            this.permissionsFacade.getByGroupId$(this.data.credentialGroup.id).pipe(
                combineLatestWith(this.usersFacade.getByGroupId$(this.data.credentialGroup.id, true)),
                map(([permissions, users]) => {
                    const usersMap = keyBy(users, 'id');
                    return [
                        ...permissions.map((p) => ({ ...p, user: usersMap[p.user.id] })),
                        ...users.filter((u) => u.tenantAdmin).map((u) => ({ user: u, permission: 'tenant_admin' })),
                    ];
                }),
            ),
        )) as IUserPermission[];

        this.originalPerms = cloneDeep(this.groupPermissions || []);

        this.platformUsers$ = this.usersFacade.users$.pipe(
            combineLatestWith(this.permissionsFacade.getByGroupId$(this.data.credentialGroup.id)),
            map(([platformUsers, currentUsers]: [IUser[], Partial<IUserPermission>[]]) => {
                const addedUsers = currentUsers.filter((u) => !!u.user).map((u) => u.user!.id);
                return platformUsers.filter((u) => !addedUsers.includes(u.id) && !u.tenantAdmin);
            }),
        );
    }

    public trackByFn(user: IPlatformUser) {
        return user.platformUserId;
    }

    public addUser() {
        const platformUser = this.userForm.controls.user.value as IUser;
        this.groupPermissions.push({
            permission: Permissions.Read,
            user: platformUser,
            credentialGroup: { id: this.data.credentialGroup.id },
        });
        this.userForm.reset();
    }

    public deleteUser(user: Partial<IUserPermission>) {
        const userIndex = this.groupPermissions.findIndex((gUser) => gUser.user!.id === user.user!.id);

        this.groupPermissions.splice(userIndex, 1);
    }

    public onSave() {
        const originalUsers = this.originalPerms.map((up) => (up as IUserPermission).user!.id);
        const usersSeen = [];

        const changed = this.groupPermissions.reduce(
            (changes, perm) => {
                if (!originalUsers.includes(perm.user!.id!)) {
                    changes.new++;
                } else {
                    const origPermission = this.originalPerms.find((ou) => (ou as IUserPermission).user!.id === perm.user!.id);
                    if (!isEqual(origPermission, perm)) {
                        changes.changed++;
                    }
                    usersSeen.push(perm.user!.id);
                }

                return changes;
            },
            { deleted: 0, new: 0, changed: 0 },
        );

        changed.deleted = originalUsers.length - usersSeen.length;

        this.modalService
            .show(ManageUsersConfirmModalComponent, {
                data: {
                    changed,
                    credentialGroupId: this.data.credentialGroup.id,
                    permissions: this.groupPermissions
                        .filter((perm) => (perm.permission as string) !== 'tenant_admin')
                        .map((perm) => ({ ...perm, user: { id: perm.user!.id } })),
                },
            })
            .subscribe(() => {
                this.sidebar.close();
            });
    }
}
