import { Component, Inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { MODAL_REF, ModalComponent } from '@passbot/angular/modal';

@Component({
    selector: 'passbot-timeout-modal',
    templateUrl: './timeout-modal.component.html',
    standalone: true,
    imports: [NgIf],
})
export class TimeoutModalComponent {
    public timer = 30;

    constructor(@Inject(MODAL_REF) private readonly modal: ModalComponent) {}

    public ngOnInit() {
        this.setTimer();
    }

    public logout() {
        this.modal.close(false);
    }

    public stayLoggedIn() {
        this.modal.close(true);
    }

    private checkTimer() {
        this.timer--;
        if (this.timer !== 0) {
            this.setTimer();
            return;
        }

        this.modal.close(false);
    }

    private setTimer() {
        setTimeout(this.checkTimer.bind(this), 1000);
    }
}
