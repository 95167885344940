import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash-es/get';

@Pipe({
    name: 'passbotAlphabeticalSort',
    pure: false,
})
export class AlphabeticalSortPipe implements PipeTransform {
    public transform(array: any, field: string): any[] {
        if (!array) {
            return [];
        }

        const newArray = [...array];

        newArray.sort((a: any, b: any) => {
            const aField = get(a, field).toLowerCase();
            const bField = get(b, field).toLowerCase();
            if (aField < bField) {
                return -1;
            } else if (aField > bField) {
                return 1;
            } else {
                return 0;
            }
        });
        return newArray;
    }
}
