import { Component, EventEmitter, Inject, OnDestroy, Optional, Output } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { firstValueFrom, Subject, take, takeUntil } from 'rxjs';
import { MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { ToastrService } from 'ngx-toastr';
import { DevicesFacade } from '@passbot/angular/devices';
import { authWithDeviceError, authWithDeviceSuccess } from '../../+state/user.actions';
import { UserFacade } from '../../+state/user.facade';

@Component({
    selector: 'passbot-device-auth',
    templateUrl: './device-auth.component.html',
})
export class DeviceAuthComponent implements OnDestroy {
    @Output() public authed = new EventEmitter<boolean>();
    public inProgress;
    public method: string;
    public destroyed$ = new Subject<void>();
    public localAvailable = true;
    public mobileAvailable = true;
    public emailAvailable = true;
    public noAuthAvailable = false;

    constructor(
        private readonly userFacade: UserFacade,
        private readonly devicesFacade: DevicesFacade,
        private readonly actions$: Actions,
        private readonly toastr: ToastrService,
        @Optional() @Inject(MODAL_REF) private readonly modal?: ModalComponent,
    ) {
        this.inProgress = this.userFacade.inProgress$;
    }

    public async ngOnInit() {
        const devices = await this.devicesFacade.loadDevicesAsync();
        const user = await firstValueFrom(this.userFacade.getUser$);
        if (devices.items?.length === 1 && devices.items[0].type === 'mobile') {
            // they only have mobile, just do it
            this.doAuth('mobile');
            return;
        }

        const supportsLocal = await this.userFacade.hasLocalWebauthnDevice();

        // if they have local enabled and device supports local, just do it
        if (devices.items?.length === 1 && devices.items[0].type === 'local' && supportsLocal) {
            // just do local
            this.doAuth('local');
            return;
        }

        // if current device doesn't support local, remove it.
        this.localAvailable = supportsLocal;

        this.mobileAvailable = (devices.items || []).findIndex((d) => d.type === 'mobile') >= 0 && !!user.mobile;
        this.emailAvailable = !!user.email;

        // make sure we can actually auth
        const filteredDevices = (devices.items || []).filter(
            (d) =>
                (d.type === 'mobile' && this.mobileAvailable) ||
                (d.type === 'local' && this.localAvailable) ||
                (d.type === 'email' && this.emailAvailable),
        );

        if (filteredDevices.length === 0) {
            this.noAuthAvailable = true;
            console.error('No valid auth devices available');
        }
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public doAuth(method: string) {
        this.method = method;
        this.userFacade.authDevice(method);
        this.actions$.pipe(ofType(authWithDeviceSuccess), take(1), takeUntil(this.destroyed$)).subscribe(() => {
            this.authed.emit(true);
            if (this.modal) {
                this.modal.close();
            }
        });
        this.actions$.pipe(ofType(authWithDeviceError), take(1), takeUntil(this.destroyed$)).subscribe(() => {
            this.toastr.error('Error authenticating, please try again');
        });
    }
}
