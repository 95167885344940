import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { catchError, map, tap } from 'rxjs/operators';
import { APIService } from '@passbot/angular/api';
import { IUser, IUserAuthDevice } from '@passbot/shared';
import { SocketIoService } from '@passbot/angular/socket-io';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { WebauthService } from '@passbot/angular/webauthn';
import { ModalService } from '@passbot/angular/modal';
import { DeviceAuthComponent } from '../components/device-auth/device-auth.component';
import * as userActions from './user.actions';
import { authWithDeviceInProgress, authWithDeviceSuccess, authWithTokenSuccess } from './user.actions';

@Injectable()
export class UserEffects {
    public loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.loadUser),
            fetch({
                // provides an action
                run: () => {
                    return this.apiService.call<IUser>('/auth/session').pipe(
                        tap((user) => {
                            if (user.id) {
                                this.socketService.init();
                            }
                        }),
                        map(userActions.loadUserSuccess),
                    );
                },

                onError: (err) => {
                    return of(userActions.loadUserError({ msg: 'Failed to load user' }));
                },
            }),
        ),
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.logout),
            fetch({
                run: () => {
                    return this.apiService.get<{ ok: true }>('/user/logout').pipe(
                        tap(() => {
                            this.socketService.disconnect();
                            void this.router.navigateByUrl('/login', { onSameUrlNavigation: 'reload' });
                        }),
                        map(userActions.logoutSuccess),
                    );
                },
            }),
            catchError(() => of(userActions.logoutSuccess)),
        ),
    );

    public authWithDevice = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.authWithDevice),
            fetch({
                run: ({ method }) => {
                    return this.apiService
                        .post<IUserAuthDevice[]>('/auth/webauthn/send-to-device', { deviceType: method })
                        .pipe(map(() => authWithDeviceInProgress()));
                },
                onError: (err) => {
                    return of(userActions.authWithDeviceError({ message: 'Failed to auth user' }));
                },
            }),
        ),
    );

    public authWithLocalDevice = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.authWithLocalDevice),
                fetch({
                    run: () => from(this.webauthn.authenticateDevice()).pipe(map((expiration) => authWithDeviceSuccess(expiration))),
                    onError: (err) => {
                        return of(userActions.authWithDeviceError({ message: 'Failed to auth user' }));
                    },
                }),
            ),
        { dispatch: true },
    );

    public needsDeviceAuth = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.needsDeviceAuth),
                map(() => {
                    return this.modal.show(DeviceAuthComponent);
                }),
            ),
        { dispatch: false },
    );

    public authWithToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.authWithToken),
            fetch({
                run: ({ token }) => this.apiService.get(`/auth/token/${token}`).pipe(map(() => authWithTokenSuccess())),
                onError: () => of(userActions.authWithTokenError({ message: 'Failed to auth with token' })),
            }),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private readonly apiService: APIService,
        private readonly socketService: SocketIoService,
        private readonly router: Router,
        private readonly webauthn: WebauthService,
        private readonly modal: ModalService,
    ) {}
}
