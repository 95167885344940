<div class="fixed pointer-events-none inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5 top-0 left-0 w-screen h-screen" role="modal">
    <div *ngIf="visible && !closing" class="absolute inset-0 bg-black/60 backdrop-blur top-0 left-0 w-full h-full pointer-events-auto" [@fadeInOnEnter] [@fadeOutOnLeave]></div>
    <div *ngIf="visible && !closing" class="border border-black/20 pointer-events-auto relative max-w-[90%] rounded-lg bg-slate-900 px-4 py-10 text-center dark:bg-navy-700 sm:px-5 shadow-[0_35px_60px_-15px_rgba(0,0,0,1)]" [@fadeInOnEnter] [@slideInDownOnEnter] [@slideOutUpOnLeave] [@fadeOutOnLeave]>
        <ng-template *ngIf="childComponent" modalContent></ng-template>

        <ng-container *ngIf="template">
            <ng-container *ngTemplateOutlet="template"></ng-container>
        </ng-container>

        <div class="text-white" *ngIf="trustedHtml" [innerHTML]="trustedHtml"></div>
    </div>
</div>




<!--<div class="modal__backdrop {{ class }}" [class.modal__backdrop&#45;&#45;closing]="closing">-->
<!--    <div class="modal__container" [class.modal__container&#45;&#45;closing]="closing">-->
<!--        <div class="modal__header" *ngIf='title'>-->
<!--            <h2 class="modal__title" *ngIf="title">{{ title }}</h2>-->
<!--            <a class="modal__close" (click)="close()"><i class='fal fa-times-square'></i></a>-->
<!--        </div>-->

<!--        &lt;!&ndash; Body &ndash;&gt;-->
<!--        <div class="modal__content">-->
<!--            <ng-template *ngIf="childComponent" modalContent></ng-template>-->

<!--            <ng-container *ngIf="template">-->
<!--                <ng-container *ngTemplateOutlet="template"></ng-container>-->
<!--            </ng-container>-->

<!--            <div *ngIf="trustedHtml" [innerHTML]="trustedHtml"></div>-->
<!--        </div>-->

<!--        &lt;!&ndash; Footer &ndash;&gt;-->
<!--        <div class="modal__footer" *ngIf="closeText && closeText !== ''">-->
<!--            <button type="button" class="btn" (click)="close()">{{ closeText }}</button>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="modal__outside-capture" *ngIf="hideOnClickOutside" (click)="close()"></div>-->
<!--</div>-->
