import { Component, Input } from '@angular/core';
import { CredentialGroupsFacade } from '@passbot/angular/credentials';
import { firstValueFrom } from 'rxjs';
import { ICredentialGroup, IUser, IUserPermission } from '@passbot/shared';
import { ModalService } from '@passbot/angular/modal';
import { DeleteItemModalComponent, IDeleteModalData } from '@passbot/angular/common';
import { ToastrService } from 'ngx-toastr';
import { EditPermissionModal } from '../../edit-permission/edit-permission.modal';
import { PermissionsFacade } from '../../../+state';

@Component({
    selector: 'passbot-group-permission-row',
    templateUrl: './group-permission-row.component.html',
})
export class GroupPermissionRowComponent {
    @Input() public permission: IUserPermission;
    @Input() public user: IUser;
    public group: ICredentialGroup | undefined;

    constructor(
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly modal: ModalService,
        private readonly permissionsFacade: PermissionsFacade,
        private readonly toastr: ToastrService,
    ) {}

    public async ngOnInit() {
        this.group = await firstValueFrom(this.credentialGroupsFacade.getById$(this.permission.credentialGroup.id));
    }

    public editPermission(permission: IUserPermission, user: IUser) {
        this.modal.show(EditPermissionModal, {
            data: {
                permission,
                user,
            },
        });
    }

    public deletePermission(permission: IUserPermission, user: IUser) {
        this.modal.show<IDeleteModalData>(DeleteItemModalComponent, {
            data: {
                itemName: this.group!.name,
                itemType: 'Permission',
                processing$: this.permissionsFacade.isProcessing$,
                callback: async () => {
                    await this.permissionsFacade.deleteUserPermissionAsync(user.id, permission.id);
                    this.toastr.success(`${this.group!.name} access removed`);
                },
            },
        });
    }
}
