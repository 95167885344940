import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getDefaultSelectors } from '@passbot/angular/common';
import { IUser, UserStatus } from '@passbot/shared';
import keyBy from 'lodash-es/keyBy';
import { getUser } from '@passbot/angular/auth';
import { permissionsSelectors } from '../permissions';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';

export const getState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);
export const { selectAll, getIsProcessing, getError, getSelectedId, getSelected, getById } = getDefaultSelectors<IUser>(getState);

const getByGroupId = (groupId: string, includeTenantAdmins = false) =>
    createSelector(permissionsSelectors.getByGroupId(groupId), selectAll, (permissions, users) => {
        const usersById = keyBy(users, 'id');
        return [...permissions.map((perm) => usersById[perm.user.id]), ...(includeTenantAdmins ? users.filter((u) => u.tenantAdmin) : [])];
    });

const getOtherUsers = createSelector(getUser, selectAll, (user: IUser | undefined, users: IUser[]) => {
    return users.filter((u) => u.id !== user?.id);
});

const getUsersAlphabetically = createSelector(selectAll, (users) => users.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));

const searchUsers = (searchStr: string) =>
    createSelector(getUsersAlphabetically, getUser, (users, user) =>
        !user?.tenantAdmin ? [] : users.filter((u) => u.name.toLowerCase().includes(searchStr)),
    );

export const usersSelectors = {
    selectAll,
    getIsProcessing,
    getError,
    getSelectedId,
    getSelected,
    getById,
    getByStatus: (status: UserStatus) => createSelector(selectAll, (users) => users.filter((user) => user.status === status)),
    getByGroupId,
    getOtherUsers,
    searchUsers,
    getUsersAlphabetically,
};
