import { EntityAdapter } from '@ngrx/entity';
import { createSelector, Selector, Store } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import values from 'lodash-es/values';
import isNil from 'lodash-es/isNil';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { IEntityState } from '../interfaces';

// eslint-disable-next-line
export const getDefaultEntityState = <T>(adapter: EntityAdapter<T>): IEntityState<T> => {
    return adapter.getInitialState({
        isProcessing: false,
        error: undefined,
        selectedId: undefined,
        entities: {},
    });
};

export const entityStartProcessing = <T>(state: T): T => ({
    ...state,
    isProcessing: true,
});

export const entityStopProcessing = <T>(state: T): T => ({
    ...state,
    isProcessing: false,
});

export const entityErrorFn =
    <T>(state: T) =>
    (error: string) => ({
        ...state,
        isProcessing: false,
        error,
    });

export const getDefaultSelectors = <T>(initialState: MemoizedSelector<object, IEntityState<T>>) => ({
    // eslint-disable-next-line
    selectAll: createSelector(initialState, (state): T[] => (state ? (values(state.entities) as T[]) : [])),
    getIsProcessing: createSelector(initialState, (state) => state.isProcessing || false),
    getError: createSelector(initialState, (state) => state.error),
    getSelectedId: createSelector(initialState, (state) => state.selectedId),
    getSelected: createSelector(initialState, (state) => !isNil(state.selectedId) && state.entities[state.selectedId]),
    getById: (id: number | string) => createSelector(initialState, (state) => state.entities[id]),
    getIds: createSelector(initialState, (state) => state.ids),
});

export const untilLoaded =
    <T>(store: Store<unknown>, selector: Selector<unknown, unknown>) =>
    (source: Observable<T>) =>
        new Observable<T>((observer) =>
            store
                .select(selector)
                .pipe(
                    filter((isLoading) => !isLoading),
                    first(),
                )
                .subscribe(() => source.subscribe(observer)),
        );
