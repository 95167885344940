<div class="min-h-screen bg-gradient-to-r from-slate-800 via-indigo-900/30 to-slate-800">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div class="relative z-50 lg:hidden" role="dialog" aria-modal="true" *ngIf="showMobileMenu">
        <!--
          Off-canvas menu backdrop, show/hide based on off-canvas menu state.

          Entering: "transition-opacity ease-linear duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "transition-opacity ease-linear duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-slate-900/80"></div>

        <div class="fixed inset-0 flex">
            <!--
              Off-canvas menu, show/hide based on off-canvas menu state.

              Entering: "transition ease-in-out duration-300 transform"
                From: "-translate-x-full"
                To: "translate-x-0"
              Leaving: "transition ease-in-out duration-300 transform"
                From: "translate-x-0"
                To: "-translate-x-full"
            -->
            <div class="relative mr-16 flex w-full max-w-xs flex-1">
                <!--
                  Close button, show/hide based on off-canvas menu state.

                  Entering: "ease-in-out duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                  Leaving: "ease-in-out duration-300"
                    From: "opacity-100"
                    To: "opacity-0"
                -->
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" (click)="closeMobileMenu()">
                        <span class="sr-only">Close sidebar</span>
                        <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div class="flex h-16 shrink-0 items-center">
                        <img class="h-8 w-auto" src="/assets/images/passbot-icon.png" alt="Passbot.io">
                    </div>
                    <nav class="flex flex-1 flex-col">
                        <ul role="list" class="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" class="-mx-2 space-y-1">
                                    <li class="pb-2">
                                        <a href="#" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                                            <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                            </svg>
                                            Dashboard
                                        </a>
                                    </li>
                                    <li class="px-2 py-2">
                                        <h3 class="text-bold text-white/40 text-sm mb-2 flex justify-between items-center">
                                            <span>Vaults</span>
                                            <a (click)="createVault()" class="text-brand max-w-[18px] transition-all ease-in-out whitespace-nowrap overflow-hidden cursor-pointer hover:max-w-[150px] flex items-center">
                                                <i class="fa-light fa-plus-circle text-lg"></i>
                                                <span class="ml-2">Create new vault</span>
                                            </a>
                                        </h3>
                                        <ul role="list" class="-mx-2 space-y-1">
                                            <li *ngFor="let credentialGroup of credentialsGroups$ | async">
                                                <a [routerLink]="'/credentials/' + credentialGroup.id" routerLinkActive="bg-gray-800 !text-white" class="group relative flex items-center gap-2.5 rounded-md p-2 font-medium ease-in-out hover:bg-gray-800 hover:text-white text-gray-400">
                                                    <span class="icon-parent flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-sm group-hover:text-white">{{ credentialGroup.name[0].toUpperCase() }}</span>
                                                    <span class="flex-1 capitalize">{{ credentialGroup.name }}</span>
                                                    <div class="text-xs space-x-4">
                                                        <span><i class="fa-thin fa-key"></i> {{ credentialGroup | credentialsCount }}</span>
                                                        <span *needPermission="{ permission: writePermission, groupId: credentialGroup.id }"><i class="fa-thin fa-users"></i> {{ credentialGroup | permissionsCount }}</span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="px-2 py-2">
                                        <h3 class="text-bold text-white/40 text-sm mb-2">Navigation</h3>
                                        <ul role="list" class="-mx-2 space-y-1">
                                            <li>
                                                <a routerLink="/users" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 ease-in-out hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center">
                                                    <i class="w-[25px] fa-light fa-user-group text-lg"></i>
                                                    Users
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/devices" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">
                                                    <i class="fa-light fa-fingerprint text-lg w-[25px]"></i>
                                                    <span class="font-semibold">Auth Devices</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/audit-log" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">
                                                    <i class="fa-light fa-list-timeline text-lg w-[25px]"></i>
                                                    <span class="font-semibold">Audit Log</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/billing" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">
                                                    <i class="fa-light fa-file-invoice text-lg w-[25px] text-center"></i>
                                                    <span class="font-semibold">Billing</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/health-check" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                                                    <i class="fa-light fa-clipboard-medical text-lg w-[25px] text-center"></i>
                                                    Health Check
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li class="mt-auto flex items-center space-x-4" *ngIf="user">
                                <div class="">
                                    <img [src]="user?.avatarUrl" class="h-12 w-12 rounded-full bg-gray-50" alt="">
                                </div>
                                <div class="flex-1 flex flex-col">
                                    <span class="text-sm font-semibold leading-6 text-gray-300" aria-hidden="true">{{ user.name }}</span>
                                    <a href="#" (click)="logout()" class="text-xs text-brand" role="menuitem" tabindex="-1">Sign out</a>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col" *ngIf="layoutProps.sidebarVisible">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-slate-900 px-6 pb-4">
            <div class="flex relative items-center justify-center space-x-4 mb-8 mt-4">
                <div><img class="inline-block h-14 w-auto" src="/assets/images/passbot-logo.svg"></div>
                <div>
                    <div class="text-brand font-semibold text-lg">PassBot<span class="opacity-90 font-normal">.io</span></div>
                    <div class="text-white font-semibold">{{ tenant.displayName }}</div>
                </div>
            </div>
            <nav class="flex flex-1 flex-col space-y-8">
                <div>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li>
                            <!-- Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" -->
                            <a id="navigate-dashboard" routerLink="/" routerLinkActive="bg-gray-800 text-white" [routerLinkActiveOptions]="{ exact: true }" class="group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-gray-800 hover:text-white text-gray-400">
                                <i class="fa-light fa-home text-lg w-[25px]"></i>
                                Dashboard
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 class="text-bold text-white/40 text-sm mb-2 flex justify-between items-center">
                        <span>Vaults</span>
                        <a id="create-vault-action" (click)="createVault()" class="text-brand max-w-[18px] transition-all ease-in-out whitespace-nowrap overflow-hidden cursor-pointer hover:max-w-[150px] flex items-center">
                            <i class="fa-light fa-plus-circle text-lg"></i>
                            <span class="ml-2">Create new vault</span>
                        </a>
                    </h3>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li *ngFor="let credentialGroup of credentialsGroups$ | async">
                            <a [routerLink]="'/credentials/' + credentialGroup.id" routerLinkActive="bg-gray-800 !text-white" class="group relative flex items-center gap-2.5 rounded-md p-2 font-medium ease-in-out hover:bg-gray-800 hover:text-white text-gray-400">
                                <span class="icon-parent flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-sm group-hover:text-white">{{ credentialGroup.name[0].toUpperCase() }}</span>
                                <span class="flex-1 capitalize">{{ credentialGroup.name }}</span>
                                <div class="text-xs space-x-4">
                                    <span><i class="fa-thin fa-key"></i> {{ credentialGroup | credentialsCount }}</span>
                                    <span *needPermission="{ permission: writePermission, groupId: credentialGroup.id }"><i class="fa-thin fa-users"></i> {{ credentialGroup | permissionsCount }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="flex-1">
                    <h3 class="text-bold text-white/40 text-sm mb-2">Navigation</h3>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li>
                            <a routerLink="/users" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 ease-in-out hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center">
                                <i class="w-[25px] fa-light fa-user-group text-lg"></i>
                                Users
                            </a>
                        </li>
                        <li>
                            <a routerLink="/devices" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">
                                <i class="fa-light fa-fingerprint text-lg w-[25px]"></i>
                                <span class="font-semibold">Auth Devices</span>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/audit-log" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">
                                <i class="fa-light fa-list-timeline text-lg w-[25px]"></i>
                                <span class="font-semibold">Audit Log</span>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/billing" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">
                                <i class="fa-light fa-file-invoice text-lg w-[25px] text-center"></i>
                                <span class="font-semibold">Billing</span>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/health-check" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                                <i class="fa-light fa-clipboard-medical text-lg w-[25px] text-center"></i>
                                Health Check
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="mt-auto flex items-center space-x-4" *ngIf="user">
                    <div class="">
                        <img [src]="user?.avatarUrl" class="h-12 w-12 rounded-full bg-gray-50" alt="">
                    </div>
                    <div class="flex-1 flex flex-col">
                        <span class="text-sm font-semibold leading-6 text-gray-300" aria-hidden="true">{{ user.name }}</span>
                        <a href="#" (click)="logout()" class="text-xs text-brand" role="menuitem" tabindex="-1">Sign out</a>
                    </div>
                </div>
<!--                <ul role="list" class="flex flex-1 flex-col gap-y-7">-->
<!--                    <li>-->
<!--                        <ul role="list" class="-mx-2 space-y-1">-->
<!--                            <li>-->
<!--                                &lt;!&ndash; Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" &ndash;&gt;-->
<!--                                <a href="#" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">-->
<!--                                    <svg class="h-6 m-w-[25px] shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
<!--                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />-->
<!--                                    </svg>-->
<!--                                    Dashboard-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a routerLink="/credentials" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">-->
<!--                                    <i class="fa-light fa-key text-xl w-[25px]"></i>-->
<!--                                    <span class="font-semibold">Credentials</span>-->
<!--                                </a>-->
<!--                                <ul class="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">-->
<!--                                    <li *ngFor="let credential of credentials$ | async">-->
<!--                                        <a [routerLink]="'/credentials/' + credential.id" routerLinkActive="bg-gray-800 !text-brand" class="group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium duration-300 ease-in-out hover:text-white text-slate-300">-->
<!--                                            {{ credential.name }}-->
<!--                                        </a>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a routerLink="/users" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">-->
<!--                                    <i class="w-[25px] fa-light fa-user-group text-xl"></i>-->
<!--                                    Users-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a routerLink="/devices" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">-->
<!--                                    <i class="fa-light fa-fingerprint text-xl w-[25px]"></i>-->
<!--                                    <span class="font-semibold">Auth Devices</span>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a routerLink="/audit-log" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">-->
<!--                                    <i class="fa-light fa-list-timeline text-xl w-[25px]"></i>-->
<!--                                    <span class="font-semibold">Audit Log</span>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a routerLink="/billing" routerLinkActive="bg-gray-800 text-white" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 items-center">-->
<!--                                    <i class="fa-light fa-file-invoice text-xl w-[25px]"></i>-->
<!--                                    <span class="font-semibold">Billing</span>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="#" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">-->
<!--                                    <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
<!--                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />-->
<!--                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />-->
<!--                                    </svg>-->
<!--                                    Reports-->
<!--                                </a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </li>-->
<!--                    <li class="mt-auto flex items-center space-x-4">-->
<!--                        <div class="">-->
<!--                            <img [src]="user.avatarUrl" class="h-12 w-12 rounded-full bg-gray-50" alt="">-->
<!--                        </div>-->
<!--                        <div class="flex-1 flex flex-col">-->
<!--                            <span class="text-sm font-semibold leading-6 text-gray-300" aria-hidden="true">{{ user.name }}</span>-->
<!--                            <a href="#" (click)="logout()" class="text-xs text-brand" role="menuitem" tabindex="-1">Sign out</a>-->
<!--                        </div>-->


<!--&lt;!&ndash;                        <a href="#" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">&ndash;&gt;-->
<!--&lt;!&ndash;                            <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">&ndash;&gt;-->
<!--&lt;!&ndash;                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />&ndash;&gt;-->
<!--&lt;!&ndash;                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />&ndash;&gt;-->
<!--&lt;!&ndash;                            </svg>&ndash;&gt;-->
<!--&lt;!&ndash;                            Settings&ndash;&gt;-->
<!--&lt;!&ndash;                        </a>&ndash;&gt;-->
<!--                    </li>-->
<!--                </ul>-->
            </nav>
        </div>
    </div>

    <div class="h-screen justify-center flex flex-col overflow-hidden relative" [ngClass]="{ 'lg:pl-0': !layoutProps.sidebarVisible, 'lg:pl-72' : layoutProps.sidebarVisible}">
        <div *ngIf="layoutProps.sidebarVisible" class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-slate-800 bg-slate-900 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" (click)="showMobileMenu = true">
                <span class="sr-only">Open sidebar</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>

            <!-- Separator -->
            <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"></div>

            <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form class="relative flex-1 flex items-center" action="#" method="GET">
                    <div class="w-full md:w-[70%] lg:w-[55%] xl:w-[40%] relative flex items-center">
                        <label for="search-field" class="sr-only">Search</label>
                        <i class="pointer-events-none absolute top-3 left-3 h-full w-5 text-slate-300 fa-regular fa-search"></i>
                        <i *ngIf="searchResultsVisible" (click)="closeSearch()" class="absolute top-3 right-3 h-full w-5 text-slate-300 fa-regular fa-close cursor-pointer"></i>
                        <input #searchInput (keyup)="doSearch($event)" autocomplete="off" id="search-field" class="bg-slate-800 h-10 block rounded-lg w-full focus:outline-none border-0 py-0 pl-10 pr-0 text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." name="search">
                        <ng-container *ngIf="searchResults$ | async as searchResults">
                            <div class="fixed left-0 md:absolute md:w-full z-10 shadow-lg top-14 bg-slate-950 p-2 rounded-lg drop-shadow-md w-screen space-y-4" *ngIf="searchResultsVisible">

                                <div *ngIf="searchResults.groups.length > 0">
                                    <div class="text-xs text-white/60 mb-2">Vaults</div>
                                    <div class="space-y-2">
                                        <div (click)="closeSearch()" [routerLink]="'/credentials/' + vault.id" class="text-white font-semibold p-2 group hover:bg-slate-900 space-x-2 flex items-center cursor-pointer" *ngFor="let vault of searchResults.groups">
                                            <span class="icon-parent flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-sm group-hover:text-white">{{ vault.name[0].toUpperCase() }}</span>
                                            <span class="capitalize group-hover:text-brand flex-1">{{ vault.name }}</span>
                                            <span class=""><i class="fa-regular fa-external-link"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="searchResults.credentials.length > 0" class="space-y-2">
                                    <div class="text-xs text-white/60 mb-2">Credentials</div>
                                    <div class="flex space-x-3 items-center p-2 group hover:bg-slate-900" *ngFor="let credential of searchResults.credentials">
                                        <div class="w-9">
                                            <passbot-credential-image [item]="credential"></passbot-credential-image>
                                        </div>
                                        <div class="flex-1 text-white group-hover:text-brand cursor-pointer" (click)="viewCredential(credential)">
                                            <div class="font-semibold">{{ credential.name }}</div>
                                            <div class="text-xs">{{ credential.username }}</div>
                                        </div>
                                        <div class="text-white group-hover:text-brand space-x-3">
                                            <i *needPermission="{ permission: writePermission, groups: credential.credentialGroups }" (click)="editCredential(credential)" class="fa-light fa-edit text-brand cursor-pointer" [attr.data-tooltip]="'Edit ' + credential.name"  data-tooltip-position="left" ></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="searchResults.users.length > 0" class="space-y-2">
                                    <div class="text-xs text-white/60 mb-2">Users</div>
                                    <div (click)="closeSearch()" routerLink="/users" [queryParams]="{ uid: user.id }" class="flex space-x-3 items-center p-2 group hover:bg-slate-900 cursor-pointer" *ngFor="let user of searchResults.users">
                                        <div class="w-9">
                                            <img class="inline-block w-9 h-9 rounded-full" [src]="user.avatarUrl"/>
                                        </div>
                                        <div class="flex-1 text-white group-hover:text-brand">
                                            {{ user.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center font-semibold text-white/50" *ngIf="searchResults.users.length < 1 && searchResults.credentials.length < 1 && searchResults.groups.length < 1">
                                    NO RESULTS
                                </div>

                            </div>
                        </ng-container>
                    </div>
                </form>
                <div class="flex items-center gap-x-4 lg:gap-x-6">
<!--                    <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">-->
<!--                        <span class="sr-only">View notifications</span>-->
<!--                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
<!--                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />-->
<!--                        </svg>-->
<!--                    </button>-->

                    <!-- Separator -->
                    <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"></div>

                    <!-- Profile dropdown -->

                    <button id="add-credential-action" [disabled]="(haveVaults$ | async) === false" (click)="addCredential()" class="relative inline-flex text-sm items-center justify-start py-2 px-4 transition-all duration-200 ease-in-out rounded-lg text-brand bg-slate-900 hover:bg-brand hover:text-white border-2 border-brand cursor-pointer disabled:border-slate-600 disabled:text-slate-600 disabled:!bg-slate-900 disabled:cursor-not-allowed">
                        <i class="fa-thin fa-shield-plus text-lg"></i>
                        <span class="ml-2 relative w-full text-left">Add Credential</span>
                    </button>
                </div>
            </div>
        </div>

        <main class="flex-1 flex overflow-hidden">
            <div class="flex-1 flex flex-col [&>*]:flex-1" *ngIf="ready">
                <!-- Your content -->
                <router-outlet class="hidden"></router-outlet>
            </div>
        </main>
    </div>
</div>
