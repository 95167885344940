<div class="space-y-8">
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-4" *ngIf="showAddButtons">
        <button
            (click)="showModal('mobile')"
            class="bg-slate-900 shadow-lg border border-white/20 p-6 rounded-lg text-center text-white flex items-center justify-center cursor-pointer"
            [ngClass]="{'opacity-50 cursor-not-allowed': hasMobile}"
            [disabled]="hasMobile"
            [attr.data-tooltip]="hasMobile ? 'You can only add 1 mobile device at a time' : null"
        >
            <i *ngIf="!hasMobile" class="fa-regular fa-mobile text-3xl mr-2"></i><i *ngIf="hasMobile" class="fa-regular fa-check text-green-500 text-3xl mr-2"></i> <span>Mobile Device</span>
        </button>

        <button
            (click)="showModal('local')"
            class="bg-slate-900 shadow-lg border border-white/20 p-6 rounded-lg text-center text-white flex items-center justify-center cursor-pointer"
        >
            <i class="fa-regular fa-up-right-from-square text-3xl mr-2"></i> <span>Local Device</span>
        </button>

        <button
            (click)="showModal('email')"
            class="bg-slate-900 shadow-lg border border-white/20 p-6 rounded-lg text-center text-white flex items-center justify-center cursor-pointer"
        >
            <i *ngIf="!hasEmail" class="fa-regular fa-envelope text-3xl mr-2"></i><i *ngIf="hasEmail" class="fa-regular fa-check text-green-500 text-3xl mr-2"></i> <span>Other device via Email</span>
        </button>
    </div>

    <div *ngIf="(devices$ | async)?.length" class="text-white">
        <div class="text-white text-lg font-semibold mb-4 " *ngIf="showAddButtons">Devices Added:</div>
        <div class="space-y-2">
            <div *ngFor="let device of devices$ | async as devices" class="flex rounded-lg bg-slate-900/60 border border-gray-700 text-white p-4">
                <div class="flex-1">
                    {{ device.displayName }} <span class="text-xs" *ngIf="device.target">({{ mask(device.target) }})</span>
                </div>
                <div class="">
                    <i (click)="deleteDevice(device)" [ngClass]="{'text-slate-700 cursor-not-allowed': devices.length < 2, 'text-red-600 hover-solid cursor-pointer': devices.length > 1}" class="fa-regular fa-trash" [attr.data-tooltip-position]="'left'" [attr.data-tooltip]=" devices.length < 2 ? 'Must have at least 1 device added' : 'Delete ' + device.type + ' device'"></i>
                </div>
            </div>
        </div>
    </div>
</div>
