import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ICredentialGroup } from '@passbot/shared';
import { Subscription } from 'rxjs';
import { CredentialsFacade } from '../+state';

@Pipe({ name: 'credentialsCount', pure: false })
export class CredentialsCountPipe implements PipeTransform, OnDestroy {
    public value?: number;
    private subscription: Subscription;

    constructor(private readonly credentialsFacade: CredentialsFacade, private readonly cdr: ChangeDetectorRef) {}

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public transform(credentialGroup: ICredentialGroup): number {
        if (!this.value) {
            this.value = 0;
            this.subscription = this.credentialsFacade
                .getByGroupId$(credentialGroup.id)
                .subscribe((credentials) => {
                    this.value = credentials.length;
                    this.cdr.markForCheck();
                });

            return this.value;
        }

        return this.value;
    }
}
