<div class="flex-1 flex flex-col space-y-2 text-slate-400 h-full">
    <div class="p-2 " [formGroup]="userForm">
        <div class="space-x-2 text-left flex">
            <div class="flex-1">
                <ng-select [items]="platformUsers$ | async | passbotAlphabeticalSort: 'name'"
                           bindLabel="name"
                           labelForId="group"
                           id="user"
                           [addTag]="false"
                           [multiple]="false"
                           [hideSelected]="false"
                           [trackByFn]="trackByFn"
                           [minTermLength]="2"
                           [loading]="false"
                           placeholder="Add New User"
                           typeToSearchText="Please enter 2 or more characters"
                           formControlName="user"
                           notFoundText="No further users available"
                >
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div class="flex space-x-2 items-center" [style.color]="item.colorHex === '#000000' ? '#fff' : item.colorHex"><img class="rounded-full w-10 h-10" [src]="item.avatarUrl"> <span class="flex-1">{{ item.name }}</span></div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="">
                <button
                        (click)="addUser()"
                        [disabled]="!(userForm.valid && userForm.dirty)"
                        class="inline-flex justify-center bg-brand cursor-pointer hover:bg-orange-700 text-white py-2 px-4 border-b-4 border-orange-700 hover:border-orange-800 rounded-lg disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed disabled:border-slate-800">
                    <i class="fa-light fa-user-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="flex-1 flex flex-col divide-y divide-slate-900 overscroll-y-auto scrollbar-thin scrollbar-thumb-slate-700 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-track-slate-900 overflow-y-auto" *ngIf="groupPermissions">
        <div *ngFor="let permission of groupPermissions | passbotAlphabeticalSort:'user.name'" class="flex space-x-2 items-center p-3">
            <div><img [src]="permission.user?.avatarUrl" class="inline-block rounded-full object-fit w-10 h-10"/></div>
            <div class="flex-1 flex flex-col justify-start text-left">
                <div class="text-sm">{{ permission.user?.name }}</div>
                <div>
                    <passbot-group-access-selector [(userPermission)]="permission.permission!" *ngIf="permission.permission !== 'tenant_admin'"></passbot-group-access-selector>
                    <span *ngIf="permission.user.tenantAdmin" class="text-xs opacity-60">Inherited from Admin status</span>
                </div>
            </div>
            <div class="flex items-center justify-center" *ngIf="permission.permission !== 'tenant_admin'"><i (click)="deleteUser(permission)" [attr.data-tooltip]="'Delete ' + permission.user?.name" [attr.data-tooltip-position]="'left'" class="fa-light cursor-pointer fa-trash text-red-600 hover:fa-regularhover-solid"></i></div>
        </div>
    </div>

</div>
