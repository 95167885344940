import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, TemplateRef, Type } from '@angular/core';

import { Observable } from 'rxjs';
import { ISidebarOptions } from './sidebar.interfaces';
import { SidebarComponent } from './sidebar/sidebar.component';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    private readonly container: HTMLElement;
    private modalInstance: ComponentRef<SidebarComponent>;

    constructor(
        private readonly applicationRef: ApplicationRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly injector: Injector,
    ) {}

    public show<O = unknown, R = unknown>(
        componentOrTemplateRef: Type<unknown> | TemplateRef<unknown> | string,
        options: ISidebarOptions<O> = {},
    ): Observable<R> {
        this.modalInstance = this.appendComponent<SidebarComponent>(SidebarComponent, {
            childComponent: componentOrTemplateRef instanceof Type ? componentOrTemplateRef : undefined,
            template: componentOrTemplateRef instanceof TemplateRef ? componentOrTemplateRef : undefined,
            html: typeof componentOrTemplateRef === 'string' ? componentOrTemplateRef : undefined,
            ...options,
        });

        this.modalInstance.instance.onClose().subscribe(this.detachModal.bind(this));

        return this.modalInstance.instance.onClose<R>();
    }

    private getRootViewContainer(): HTMLElement {
        if (this.container) {
            return this.container;
        }

        return (this.applicationRef.components[0].hostView as EmbeddedViewRef<unknown>).rootNodes[0];
    }

    private getComponentRootNode(componentRef: ComponentRef<unknown>): HTMLElement {
        // eslint-disable-next-line
        return (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0];
    }

    private getRootViewContainerNode(): HTMLElement {
        return this.getRootViewContainer();
    }

    private projectComponentInputs(component: ComponentRef<any>, options: Record<string, unknown>): ComponentRef<unknown> {
        if (options) {
            const props = Object.getOwnPropertyNames(options);
            for (const prop of props) {
                // eslint-disable-next-line no-param-reassign
                component.instance[prop] = options[prop];
            }
        }

        return component;
    }

    private appendComponent<T>(
        componentClass: Type<T>,
        options: Record<string, unknown> = {},
        location: Element = this.getRootViewContainerNode(),
    ): ComponentRef<T> {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        const componentRef = componentFactory.create(this.injector);
        const componentRootNode = this.getComponentRootNode(componentRef);

        // project the options passed to the component instance
        this.projectComponentInputs(componentRef, options);

        this.applicationRef.attachView(componentRef.hostView);

        componentRef.onDestroy(() => {
            this.applicationRef.detachView(componentRef.hostView);
        });

        location.appendChild(componentRootNode);

        return componentRef;
    }

    private detachModal() {
        // in timeout to allow for close animation to complete
        setTimeout(() => {
            this.applicationRef.detachView(this.modalInstance.hostView);
            this.modalInstance.destroy();
        }, 500);
    }
}
