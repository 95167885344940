import { Component, Inject } from '@angular/core';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { IUserAuthDevice } from '@passbot/shared';
import { DevicesFacade } from '@passbot/angular/devices';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'passbot-remove-device',
    templateUrl: './remove-device.component.html',
})
export class RemoveDeviceComponent {
    public device: IUserAuthDevice;
    public isProcessing$ = this.devicesFacade.isProcessing$;

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) private readonly data: IUserAuthDevice,
        private readonly devicesFacade: DevicesFacade,
        private readonly toastr: ToastrService,
    ) {
        this.device = this.data;
    }

    public cancel() {
        this.modal.close();
    }

    public async removeDevice() {
        const result = await this.devicesFacade.removeDeviceAsync(this.device);
        if ('msg' in result) {
            this.toastr.error(result.msg);
            return;
        }

        this.toastr.success('Device successfully removed');
        this.modal.close();
    }
}
