import { NgModule } from '@angular/core';

import { ChargebeeService } from './services';

@NgModule({
    providers: [
        ChargebeeService,
    ],
})
export class ChargebeeModule {}
