import {
    AfterViewInit,
    Component,
    ComponentRef,
    ContentChild,
    InjectionToken,
    Injector,
    Input,
    OnInit,
    StaticProvider,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';

import { filter, Observable, Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { slideInRightOnEnterAnimation, slideOutRightOnLeaveAnimation } from 'angular-animations';
import { BaseSidebarComponent } from '../base/base.sidebar.component';
import { SidebarContentDirective } from './sidebar-content.directive';
import { Router, RouterEvent } from '@angular/router';

export const SIDEBAR_DATA = new InjectionToken<unknown>('SidebarData');
export const SIDEBAR_REF = new InjectionToken<SidebarComponent>('SidebarRef');

@Component({
    selector: 'passbot-modal-wrapper',
    templateUrl: './sidebar.component.html',
    animations: [slideInRightOnEnterAnimation({ duration: 600 }), slideOutRightOnLeaveAnimation({ duration: 600 })],
})
export class SidebarComponent implements AfterViewInit, OnInit {
    @ViewChild(SidebarContentDirective, { static: false })
    public contentDirective: SidebarContentDirective;

    @Input() public title: string;
    @Input() public subtitle: string;
    @Input() public closeText = 'Close';
    @Input() public body: TemplateRef<unknown>;
    @Input() public context: unknown;
    @Input() public hideOnClickOutside = true;
    @Input() public hideOnEsc = true;
    @Input() public data: unknown;
    @ContentChild('footerTemplate', { static: false }) public footerTemplateRef: TemplateRef<unknown>;
    @Input() public childComponent: Type<BaseSidebarComponent>;
    @Input() public template: TemplateRef<unknown>;
    @Input() public html: string;
    @Input() public class: string;
    @Input() public saveText: string = 'Save';
    @Input() public formValid: Observable<boolean>;

    public childInstance: BaseSidebarComponent;
    public modalBoxOpenState = false;
    public closing = false;
    public trustedHtml: SafeHtml;

    private childComponentRef: ComponentRef<BaseSidebarComponent>;
    private readonly closeSubject = new Subject<unknown>();
    public visible = true;

    constructor(private readonly injector: Injector, private readonly sanitizer: DomSanitizer, private readonly router: Router) {}

    public ngOnInit() {
        if (this.html) {
            this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.html);
        }

        this.router.events.pipe(filter((e: unknown) => e instanceof RouterEvent)).subscribe((e: unknown) => {
            this.close();
        });
    }

    public ngAfterViewInit(): void {
        if (this.childComponent) {
            const { viewContainerRef } = this.contentDirective;
            viewContainerRef.clear();

            const providers: StaticProvider[] = [
                { provide: SIDEBAR_DATA, useValue: this.data },
                { provide: SIDEBAR_REF, useValue: this },
            ];

            const injector = Injector.create({ parent: this.injector, providers });

            this.childComponentRef = viewContainerRef.createComponent(this.childComponent, { injector });
            this.childInstance = this.childComponentRef.instance;
            if (this.data) {
                Object.keys(this.data).forEach((key) => {
                    Object.assign(this.childInstance, this.data);
                });
            }
            this.childComponentRef.changeDetectorRef.detectChanges();
            setTimeout(() => {
                this.visible = true;
            }, 1000);
        }
    }

    public cancel(): void {
        this.close();
    }

    public close(response?: unknown): void {
        this.closing = true;
        const closeModal = () => {
            setTimeout(() => this.closeSubject.next(response), 600);
        };

        if (!response && this.childInstance && this.childInstance.onClose) {
            const outcome = this.childInstance.onClose();
            if (outcome === true) {
                closeModal();
            }
            return;
        }

        closeModal();
        this.visible = false;
    }

    public onClose<T>(): Observable<T> {
        return this.closeSubject.asObservable() as Observable<T>;
    }

    public saveClick(): void {
        if (!this.childInstance.saveDisabled) {
            this.childInstance.onSave!();
        }
    }

    public modalBoxState(eventVal: { isModalBoxActive: boolean; modalBoxId: string }) {
        this.modalBoxOpenState = eventVal.isModalBoxActive;
    }
}
