// eslint-disable-next-line max-classes-per-file
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PERMISSIONS_FEATURE_KEY, PermissionsFacade, PermissionsReducers, USERS_FEATURE_KEY, UsersFacade, UsersReducers } from './+state';
import { UsersEffects } from './+state/users/users.effects';
import { PermissionsEffects } from './+state/permissions/permissions.effects';
import { NeedPermissionDirective } from './directives/need-permission.directive';
import { PermissionsCountPipe } from './pipes/permissions-count.pipe';

@NgModule({
    imports: [
        StoreModule.forFeature(PERMISSIONS_FEATURE_KEY, PermissionsReducers),
        StoreModule.forFeature(USERS_FEATURE_KEY, UsersReducers),
        EffectsModule.forFeature([UsersEffects, PermissionsEffects]),
    ],
    declarations: [NeedPermissionDirective, PermissionsCountPipe],
    providers: [UsersFacade, PermissionsFacade],
    exports: [NeedPermissionDirective, PermissionsCountPipe],
})
export class PermissionsModule {}
