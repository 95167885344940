import { AfterViewInit, Component } from '@angular/core';

import { Router } from '@angular/router';
import { take } from 'rxjs';
import { CredentialGroupsFacade } from '../../+state';

@Component({
    selector: 'passbot-credentials-index',
    template: '',
})
export class IndexComponent implements AfterViewInit {
    constructor(private readonly credentialGroupsFacade: CredentialGroupsFacade, private readonly router: Router) {}

    public ngAfterViewInit() {
        return this.credentialGroupsFacade.getFirst$.pipe(take(1)).subscribe((credGroup) => {
            if (credGroup) {
                void this.router.navigateByUrl(`/credentials/${credGroup.id}`);
                return;
            }

            void this.router.navigateByUrl('/');
        });
    }
}
